import React, { useState, useRef, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useScrollPosition } from 'utils/hooks';
import request from 'utils/request';

import { Collapse, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Catalogo from 'components/catalogo';
import CategoriesMenu from 'components/categoriesMenu';
import { deporte as categories } from 'utils/data/categories';

import DroneImage from './images/dron.png';
import {
  useStyles,
  SearchTitle,
  SearchInput,
  CategoriesContainer,
  CarouselItem,
  CarouselCaptionLeft,
  CarouselImageRight,
} from './styles';

function DeportesSalud() {
  const [showCarousel, setShowCarousel] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();
  const [fixedCategories, setFixedCategories] = useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [shouldLoadMoreItems, setShouldLoadMoreItems] = useState(false);
  const categoriesRef = useRef(null);
  const searchInputRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    request(`https://api.bigbenespana.es/api/totem/products`, {
      queryParams: {
        filter: {
          'menus.Id': 4,
          'productos.estado': 1,
          ...(categoryId
            ? { 'productos_padre.id_categoria': categoryId }
            : null),
        },
        ...(searchValue.trim().length > 0
          ? { search: searchValue.trim() }
          : null),
        skip: 0,
        limit: 30,
      },
    })
      .then(data => {
        setProducts(data);
        setShouldLoadMoreItems(data.length > 0);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }, [categoryId, searchValue]);

  const loadMoreProducts = skip => {
    setLoading(true);
    request(`https://api.bigbenespana.es/api/totem/products`, {
      queryParams: {
        filter: {
          'menus.Id': 4,
          'productos.estado': 1,
          ...(categoryId
            ? { 'productos_padre.id_categoria': categoryId }
            : null),
        },
        ...(searchValue.trim().length > 0
          ? { search: searchValue.trim() }
          : null),
        skip,
        limit: 30,
      },
    })
      .then(data => {
        setProducts(prevState => [...prevState, ...data]);
        setShouldLoadMoreItems(data.length > 0);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  useScrollPosition(
    ({ _, currPos }) => {
      if (currPos.y <= 46) {
        setFixedCategories(true);
      }
      if (currPos.y > 46) {
        setFixedCategories(false);
      }
    },
    [],
    categoriesRef,
  );

  return (
    <div>
      <Collapse in={showCarousel} timeout={300}>
        <Carousel
          indicators={false}
          controls={false}
          style={{ borderBottom: '1px solid #ececec' }}
        >
          <Carousel.Item as={CarouselItem}>
            <div
              className="container position-relative"
              style={{ height: 440 }}
            >
              <CarouselImageRight
                src={DroneImage}
                draggable={false}
                style={{ height: 640 }}
                alt="Funda semirígida azul para iPhone"
              />
              <Carousel.Caption as={CarouselCaptionLeft}>
                <h2>Motivación a tope.</h2>
                <Link to="/deportes-salud">
                  Mantenerse en forma cuesta muy poco
                </Link>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </Collapse>
      <div className="container">
        <div className="text-center">
          <SearchTitle>Encuentra los accesorios que buscas.</SearchTitle>
          <SearchInput>
            <SearchIcon
              fontSize="large"
              style={{ marginRight: 8, color: '#bbb' }}
            />
            <InputBase
              inputRef={searchInputRef}
              type="search"
              placeholder="Buscar accesorios"
              className={classes.input}
              value={searchValue}
              onChange={({ target }) => {
                setShowCarousel(
                  target.value === '' &&
                    document.activeElement !== searchInputRef.current,
                );
                setSearchValue(target.value);
              }}
              onKeyUp={({ target }) => {
                setShowCarousel(
                  target.value === '' &&
                    document.activeElement !== searchInputRef.current,
                );
                setSearchValue(target.value);
              }}
              onFocus={() => {
                setShowCarousel(false);
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              }}
              onBlur={() => {
                if (searchValue === '') {
                  setShowCarousel(true);
                }
              }}
              fullWidth
            />
          </SearchInput>
        </div>
      </div>
      <div ref={categoriesRef} className="position-relative">
        <div style={{ paddingBottom: 135 }} />
        <CategoriesContainer
          fixed={fixedCategories}
          className={fixedCategories ? 'container-fluid' : 'container'}
        >
          <div className="text-center">
            <CategoriesMenu
              items={categories}
              fixed={fixedCategories}
              selectedCategory={categoryId}
              onChange={id => {
                if (categoryId !== id) {
                  setProducts([]);
                  setCategoryId(id);
                }
              }}
            />
          </div>
        </CategoriesContainer>
      </div>
      <div className="container mb-5">
        <Catalogo
          loading={loading}
          products={products}
          loadMoreItems={loadMoreProducts}
          shouldLoadMoreItems={shouldLoadMoreItems}
        />
      </div>
    </div>
  );
}

export default DeportesSalud;
