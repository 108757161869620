import styled from 'styled-components';

export const Submenu = styled.ul`
  margin: 0;
  margin-top: 0;
  padding: 0;
  list-style: none;
  padding: ${({ fixed }) => fixed ? '12px 0' : '24px 0' };
  border: 1px solid transparent;
  border-top-color: ${({ fixed }) => fixed ? '#fff' : '#d6d6d6' };
  border-bottom-color: ${({ fixed }) => fixed ? '#fff' : '#d6d6d6' };
  transition: all 200ms ease-out;
`;

export const SubmenuItem = styled.li`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ fixed }) => fixed ? '0 16px' : '0 32px' };
  color: ${({selected}) => selected ? '#007bff' : 'inherit'};
  transition: all 200ms ease-out;
  animation: nav-slideinLeft 350ms backwards;
  cursor: pointer;
`;

export const SubmenuIcon = styled.img`
  display: block;
  width: ${({ fixed }) => fixed ? '44px' : '54px' };
  height: auto;
  max-width: 100%;
  transition: all 200ms ease-out;
  user-select: none;
`;

export const SubmenuText = styled.div`
  margin-top: ${({ fixed }) => fixed ? '12px' : '18px' };
  font-size: 12px;
  line-height: 1.09091;
  transition: all 200ms ease-out;
`;
