import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import request from 'utils/request';

import { Select, MenuItem, InputBase } from '@material-ui/core';
import Catalogo from 'components/catalogo';
import {
  useStyles,
  SearchTitle,
  SearchInput,
  CategoriesWrapper,
} from './styles';

function Terminales({ terminal, setTerminal }) {
  const [terminales, setTerminales] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shouldLoadMoreItems, setShouldLoadMoreItems] = useState(false);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    request('https://api.bigbenespana.es/api/totem/terminales')
      .then(data => setTerminales(data))
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    setLoading(true);
    request(`https://api.bigbenespana.es/api/totem/terminales/${terminal}`, {
      queryParams: {
        filter: {
          'productos.estado': 1,
          ...(category
            ? {
                'productos_padre.id_categoria':
                  category === 5 ? [5, 25] : category,
              }
            : null),
        },
        skip: 0,
        limit: 30,
      },
    })
      .then(data => {
        setProducts(data);
        setShouldLoadMoreItems(data.length === 30);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
      });
  }, [category]);

  useEffect(() => {
    if (terminal) {
      setLoading(true);
      request(`https://api.bigbenespana.es/api/totem/terminales/${terminal}`, {
        queryParams: {
          filter: {
            'productos.estado': 1,
            ...(category ? { 'productos_padre.id_categoria': category } : null),
          },
          skip: 0,
          limit: 30,
        },
      })
        .then(data => {
          setProducts(data);
          setCategories(
            data
              .map(product => product.category.id === 1 ? { id: product.category.id, name: 'Otros' } : product.category)
              .reduce(
                (unique, item) =>
                  unique.find(element => {
                    return (item.id === 25 && element.id === 5) || element.id === item.id;
                  })
                    ? unique
                    : [...unique, item],
                [],
              ).sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
              }),
          );
          setShouldLoadMoreItems(data.length === 30);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [terminal]);

  const loadMoreProducts = skip => {
    if (!loading && shouldLoadMoreItems) {
      setLoading(true);
      request(`https://api.bigbenespana.es/api/totem/terminales/${terminal}`, {
        queryParams: {
          filter: {
            'productos.estado': 1,
            ...(category ? { 'productos_padre.id_categoria': category } : null),
          },
          skip,
          limit: 30,
        },
      })
        .then(data => {
          setProducts(prevState => [...prevState, ...data]);
          setShouldLoadMoreItems(data.length === 30);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="container">
        <div className="text-center">
          <SearchTitle>Encuentra los accesorios para tu terminal.</SearchTitle>
          <SearchInput>
            <Select
              value={terminal ? terminal : undefined}
              onChange={({ target }) => {
                if (terminal !== target.value) {
                  setCategory(null);
                  setTerminal(target.value);
                  setProducts([]);
                }
              }}
              classes={{ root: classes.select }}
              input={<InputBase type="hidden" value={terminal} fullWidth />}
              fullWidth
              displayEmpty
            >
              <MenuItem disabled>Selecciona un terminal</MenuItem>
              {terminales.map(item => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </SearchInput>
        </div>
      </div>
      {terminal && (
        <div className="container">
          <CategoriesWrapper>
            <li
              className={!category ? 'active' : ''}
              onClick={() => {
                if (category) {
                  setProducts([]);
                  setCategory(null);
                }
              }}
            >
              Todos
            </li>
            {categories.map(item => (
              <li
                key={item.id}
                className={item.id === category ? 'active' : ''}
                onClick={() => {
                  if (category !== item.id) {
                    setProducts([]);
                    setCategory(item.id);
                  }
                }}
              >
                {item.name}
              </li>
            ))}
          </CategoriesWrapper>
        </div>
      )}
      <div className="container mb-5">
        <Catalogo
          loading={loading}
          products={products}
          loadMoreItems={loadMoreProducts}
          shouldLoadMoreItems={shouldLoadMoreItems}
          hideProducts={!terminal}
        />
      </div>
    </div>
  );
}

Terminales.propTypes = {
  terminal: PropTypes.number,
  setTerminal: PropTypes.func.isRequired,
};

export default Terminales;
