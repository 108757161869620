export const query = {
  database: 'totem',
  products: [
    {
      id: 16,
      amount: 19.99,
    },
    {
      id: 17,
      amount: 19.99,
    },
    {
      id: 18,
      amount: 19.99,
    },
    {
      id: 19,
      amount: 19.99,
    },
    {
      id: 20,
      amount: 19.99,
    },
    {
      id: 21,
      amount: 19.99,
    },
    {
      id: 22,
      amount: 19.99,
    },
    {
      id: 23,
      amount: 19.99,
    },
    {
      id: 24,
      amount: 19.99,
    },
    {
      id: 25,
      amount: 19.99,
    },
    {
      id: 26,
      amount: 19.99,
    },
    {
      id: 27,
      amount: 19.99,
    },
    {
      id: 28,
      amount: 19.99,
    },
    {
      id: 29,
      amount: 19.99,
    },
    {
      id: 34,
      amount: 29.99,
    },
    {
      id: 35,
      amount: 29.99,
    },
    {
      id: 42,
      amount: 29.99,
    },
    {
      id: 44,
      amount: 29.99,
    },
    {
      id: 578,
      amount: 59.99,
    },
    {
      id: 579,
      amount: 24.99,
    },
    {
      id: 580,
      amount: 24.99,
    },
    {
      id: 9,
      amount: 499.99,
    },
    {
      id: 180,
      amount: 89.99,
    },
    {
      id: 181,
      amount: 69.99,
    },
    {
      id: 576,
      amount: 599.0,
    },
    {
      id: 577,
      amount: 399.0,
    },
    {
      id: 442,
      amount: 99.99,
    },
    {
      id: 482,
      amount: 49.99,
    },
    {
      id: 484,
      amount: 19.99,
    },
    {
      id: 598,
      amount: 159.99,
    },
    {
      id: 599,
      amount: 159.99,
    },
    {
      id: 600,
      amount: 159.99,
    },
    {
      id: 610,
      amount: 119.99,
    },
    {
      id: 496,
      amount: 399.91,
    },
    {
      id: 497,
      amount: 179.9,
    },
    {
      id: 575,
      amount: 799.0,
    },
    {
      id: 110,
      amount: 9.99,
    },
    {
      id: 451,
      amount: 69.99,
    },
    {
      id: 487,
      amount: 359.0,
    },
    {
      id: 498,
      amount: 149.99,
    },
    {
      id: 501,
      amount: 429.99,
    },
    {
      id: 502,
      amount: 329.99,
    },
    {
      id: 585,
      amount: 64.99,
    },
    {
      id: 457,
      amount: 34.99,
    },
    {
      id: 458,
      amount: 34.99,
    },
    {
      id: 459,
      amount: 49.99,
    },
    {
      id: 460,
      amount: 49.99,
    },
    {
      id: 4,
      amount: 49.9,
    },
    {
      id: 6,
      amount: 69.9,
    },
    {
      id: 10,
      amount: 69.99,
    },
    {
      id: 11,
      amount: 69.99,
    },
    {
      id: 436,
      amount: 49.9,
    },
    {
      id: 445,
      amount: 49.9,
    },
    {
      id: 446,
      amount: 59.9,
    },
    {
      id: 471,
      amount: 49.99,
    },
    {
      id: 472,
      amount: 49.99,
    },
    {
      id: 473,
      amount: 49.99,
    },
    {
      id: 474,
      amount: 12.99,
    },
    {
      id: 475,
      amount: 19.99,
    },
    {
      id: 476,
      amount: 29.99,
    },
    {
      id: 477,
      amount: 29.99,
    },
    {
      id: 478,
      amount: 29.99,
    },
    {
      id: 479,
      amount: 79.99,
    },
    {
      id: 480,
      amount: 24.99,
    },
    {
      id: 7,
      amount: 69.9,
    },
    {
      id: 8,
      amount: 99.9,
    },
    {
      id: 175,
      amount: 39.99,
    },
    {
      id: 440,
      amount: 79.99,
    },
    {
      id: 441,
      amount: 139.99,
    },
    {
      id: 443,
      amount: 39.99,
    },
    {
      id: 444,
      amount: 39.99,
    },
    {
      id: 79,
      amount: 299.99,
    },
    {
      id: 84,
      amount: 12.99,
    },
    {
      id: 85,
      amount: 9.99,
    },
    {
      id: 87,
      amount: 9.99,
    },
    {
      id: 89,
      amount: 34.99,
    },
    {
      id: 90,
      amount: 19.99,
    },
    {
      id: 99,
      amount: 7.99,
    },
    {
      id: 105,
      amount: 26.99,
    },
    {
      id: 106,
      amount: 19.99,
    },
    {
      id: 107,
      amount: 5.99,
    },
    {
      id: 109,
      amount: 19.99,
    },
    {
      id: 113,
      amount: 4.99,
    },
    {
      id: 115,
      amount: 12.99,
    },
    {
      id: 117,
      amount: 19.99,
    },
    {
      id: 121,
      amount: 8.99,
    },
    {
      id: 122,
      amount: 14.99,
    },
    {
      id: 123,
      amount: 6.99,
    },
    {
      id: 124,
      amount: 9.99,
    },
    {
      id: 126,
      amount: 4.5,
    },
    {
      id: 127,
      amount: 14.99,
    },
    {
      id: 129,
      amount: 10.99,
    },
    {
      id: 130,
      amount: 16.99,
    },
    {
      id: 131,
      amount: 12.99,
    },
    {
      id: 132,
      amount: 6.99,
    },
    {
      id: 133,
      amount: 9.99,
    },
    {
      id: 134,
      amount: 9.99,
    },
    {
      id: 135,
      amount: 9.99,
    },
    {
      id: 136,
      amount: 9.99,
    },
    {
      id: 137,
      amount: 9.99,
    },
    {
      id: 138,
      amount: 9.99,
    },
    {
      id: 139,
      amount: 22.99,
    },
    {
      id: 140,
      amount: 2.99,
    },
    {
      id: 141,
      amount: 12.99,
    },
    {
      id: 142,
      amount: 4.99,
    },
    {
      id: 143,
      amount: 19.99,
    },
    {
      id: 144,
      amount: 19.99,
    },
    {
      id: 145,
      amount: 19.99,
    },
    {
      id: 146,
      amount: 4.99,
    },
    {
      id: 148,
      amount: 17.99,
    },
    {
      id: 150,
      amount: 9.99,
    },
    {
      id: 151,
      amount: 5.99,
    },
    {
      id: 152,
      amount: 6.99,
    },
    {
      id: 154,
      amount: 5.99,
    },
    {
      id: 158,
      amount: 4.99,
    },
    {
      id: 62,
      amount: 29.99,
    },
    {
      id: 63,
      amount: 29.99,
    },
    {
      id: 64,
      amount: 29.99,
    },
    {
      id: 65,
      amount: 29.9,
    },
    {
      id: 66,
      amount: 39.99,
    },
    {
      id: 88,
      amount: 199.99,
    },
    {
      id: 91,
      amount: 39.99,
    },
    {
      id: 92,
      amount: 39.99,
    },
    {
      id: 93,
      amount: 39.99,
    },
    {
      id: 94,
      amount: 39.99,
    },
    {
      id: 95,
      amount: 39.99,
    },
    {
      id: 96,
      amount: 39.99,
    },
    {
      id: 97,
      amount: 39.99,
    },
    {
      id: 98,
      amount: 39.99,
    },
    {
      id: 101,
      amount: 129.99,
    },
    {
      id: 102,
      amount: 109.99,
    },
    {
      id: 103,
      amount: 109.99,
    },
    {
      id: 104,
      amount: 109.99,
    },
    {
      id: 119,
      amount: 49.0,
    },
    {
      id: 49,
      amount: 14.99,
    },
    {
      id: 50,
      amount: 79.99,
    },
    {
      id: 60,
      amount: 49.99,
    },
    {
      id: 67,
      amount: 39.99,
    },
    {
      id: 68,
      amount: 79.99,
    },
    {
      id: 80,
      amount: 79.99,
    },
    {
      id: 82,
      amount: 59.9,
    },
    {
      id: 86,
      amount: 14.99,
    },
    {
      id: 100,
      amount: 29.99,
    },
    {
      id: 157,
      amount: 19.99,
    },
    {
      id: 61,
      amount: 24.99,
    },
    {
      id: 69,
      amount: 9.99,
    },
    {
      id: 70,
      amount: 9.99,
    },
    {
      id: 71,
      amount: 9.99,
    },
    {
      id: 72,
      amount: 9.99,
    },
    {
      id: 73,
      amount: 19.99,
    },
    {
      id: 74,
      amount: 49.99,
    },
    {
      id: 75,
      amount: 39.99,
    },
    {
      id: 76,
      amount: 9.99,
    },
    {
      id: 77,
      amount: 14.99,
    },
    {
      id: 78,
      amount: 19.99,
    },
    {
      id: 56,
      amount: 29.99,
    },
    {
      id: 57,
      amount: 49.99,
    },
    {
      id: 58,
      amount: 59.99,
    },
    {
      id: 59,
      amount: 79.99,
    },
    {
      id: 52,
      amount: 99.99,
    },
    {
      id: 53,
      amount: 109.9,
    },
    {
      id: 54,
      amount: 139.99,
    },
    {
      id: 55,
      amount: 169.99,
    },
    {
      id: 881,
      amount: 9.99,
    },
    {
      id: 884,
      amount: 19.99,
    },
    {
      id: 1039,
      amount: 39.99,
    },
    {
      id: 1040,
      amount: 39.99,
    },
    {
      id: 1127,
      amount: 16.99,
    },
    {
      id: 760,
      amount: 29.99,
    },
    {
      id: 761,
      amount: 29.99,
    },
    {
      id: 784,
      amount: 12.99,
    },
    {
      id: 786,
      amount: 29.99,
    },
    {
      id: 787,
      amount: 19.99,
    },
    {
      id: 874,
      amount: 29.99,
    },
    {
      id: 875,
      amount: 29.99,
    },
    {
      id: 876,
      amount: 29.99,
    },
    {
      id: 877,
      amount: 29.99,
    },
    {
      id: 878,
      amount: 14.99,
    },
    {
      id: 879,
      amount: 14.99,
    },
    {
      id: 880,
      amount: 19.99,
    },
    {
      id: 882,
      amount: 12.99,
    },
    {
      id: 883,
      amount: 19.99,
    },
    {
      id: 891,
      amount: 12.99,
    },
    {
      id: 893,
      amount: 12.99,
    },
    {
      id: 894,
      amount: 12.99,
    },
    {
      id: 895,
      amount: 12.99,
    },
    {
      id: 896,
      amount: 12.99,
    },
    {
      id: 897,
      amount: 12.99,
    },
    {
      id: 898,
      amount: 12.99,
    },
    {
      id: 899,
      amount: 12.99,
    },
    {
      id: 900,
      amount: 12.99,
    },
    {
      id: 901,
      amount: 12.99,
    },
    {
      id: 902,
      amount: 12.99,
    },
    {
      id: 903,
      amount: 7.99,
    },
    {
      id: 904,
      amount: 7.99,
    },
    {
      id: 905,
      amount: 7.99,
    },
    {
      id: 906,
      amount: 7.99,
    },
    {
      id: 907,
      amount: 12.99,
    },
    {
      id: 908,
      amount: 12.99,
    },
    {
      id: 909,
      amount: 12.99,
    },
    {
      id: 910,
      amount: 12.99,
    },
    {
      id: 911,
      amount: 7.99,
    },
    {
      id: 912,
      amount: 7.99,
    },
    {
      id: 913,
      amount: 12.99,
    },
    {
      id: 914,
      amount: 12.99,
    },
    {
      id: 917,
      amount: 7.99,
    },
    {
      id: 918,
      amount: 7.99,
    },
    {
      id: 920,
      amount: 12.99,
    },
    {
      id: 921,
      amount: 12.99,
    },
    {
      id: 922,
      amount: 7.99,
    },
    {
      id: 923,
      amount: 12.99,
    },
    {
      id: 924,
      amount: 12.99,
    },
    {
      id: 925,
      amount: 7.99,
    },
    {
      id: 926,
      amount: 12.99,
    },
    {
      id: 928,
      amount: 7.99,
    },
    {
      id: 930,
      amount: 7.99,
    },
    {
      id: 932,
      amount: 12.99,
    },
    {
      id: 933,
      amount: 12.99,
    },
    {
      id: 935,
      amount: 12.99,
    },
    {
      id: 938,
      amount: 12.99,
    },
    {
      id: 939,
      amount: 12.99,
    },
    {
      id: 940,
      amount: 12.99,
    },
    {
      id: 942,
      amount: 12.99,
    },
    {
      id: 943,
      amount: 12.99,
    },
    {
      id: 944,
      amount: 12.99,
    },
    {
      id: 945,
      amount: 12.99,
    },
    {
      id: 946,
      amount: 12.99,
    },
    {
      id: 947,
      amount: 12.99,
    },
    {
      id: 948,
      amount: 12.99,
    },
    {
      id: 949,
      amount: 12.99,
    },
    {
      id: 950,
      amount: 12.99,
    },
    {
      id: 951,
      amount: 12.99,
    },
    {
      id: 952,
      amount: 12.99,
    },
    {
      id: 953,
      amount: 12.99,
    },
    {
      id: 955,
      amount: 12.99,
    },
    {
      id: 956,
      amount: 12.99,
    },
    {
      id: 957,
      amount: 12.99,
    },
    {
      id: 958,
      amount: 12.99,
    },
    {
      id: 959,
      amount: 12.99,
    },
    {
      id: 960,
      amount: 12.99,
    },
    {
      id: 961,
      amount: 12.99,
    },
    {
      id: 962,
      amount: 12.99,
    },
    {
      id: 963,
      amount: 12.99,
    },
    {
      id: 964,
      amount: 12.99,
    },
    {
      id: 965,
      amount: 12.99,
    },
    {
      id: 966,
      amount: 12.99,
    },
    {
      id: 967,
      amount: 12.99,
    },
    {
      id: 968,
      amount: 12.99,
    },
    {
      id: 969,
      amount: 12.99,
    },
    {
      id: 970,
      amount: 12.99,
    },
    {
      id: 971,
      amount: 12.99,
    },
    {
      id: 972,
      amount: 12.99,
    },
    {
      id: 974,
      amount: 7.99,
    },
    {
      id: 976,
      amount: 7.99,
    },
    {
      id: 977,
      amount: 12.99,
    },
    {
      id: 978,
      amount: 7.99,
    },
    {
      id: 981,
      amount: 7.99,
    },
    {
      id: 983,
      amount: 12.99,
    },
    {
      id: 984,
      amount: 12.99,
    },
    {
      id: 985,
      amount: 12.99,
    },
    {
      id: 986,
      amount: 7.99,
    },
    {
      id: 987,
      amount: 7.99,
    },
    {
      id: 993,
      amount: 24.99,
    },
    {
      id: 996,
      amount: 12.99,
    },
    {
      id: 1008,
      amount: 19.99,
    },
    {
      id: 1011,
      amount: 19.99,
    },
    {
      id: 1012,
      amount: 39.99,
    },
    {
      id: 1013,
      amount: 39.99,
    },
    {
      id: 1014,
      amount: 69.99,
    },
    {
      id: 1015,
      amount: 79.99,
    },
    {
      id: 1016,
      amount: 79.99,
    },
    {
      id: 1017,
      amount: 39.99,
    },
    {
      id: 1018,
      amount: 39.99,
    },
    {
      id: 1019,
      amount: 39.99,
    },
    {
      id: 1020,
      amount: 39.99,
    },
    {
      id: 1021,
      amount: 29.99,
    },
    {
      id: 1022,
      amount: 29.99,
    },
    {
      id: 1023,
      amount: 49.99,
    },
    {
      id: 1024,
      amount: 54.99,
    },
    {
      id: 1025,
      amount: 49.99,
    },
    {
      id: 1026,
      amount: 49.99,
    },
    {
      id: 1027,
      amount: 29.99,
    },
    {
      id: 1028,
      amount: 29.99,
    },
    {
      id: 1029,
      amount: 29.99,
    },
    {
      id: 1030,
      amount: 29.99,
    },
    {
      id: 1031,
      amount: 59.99,
    },
    {
      id: 1032,
      amount: 59.99,
    },
    {
      id: 1033,
      amount: 59.99,
    },
    {
      id: 1034,
      amount: 69.99,
    },
    {
      id: 1035,
      amount: 74.99,
    },
    {
      id: 1036,
      amount: 69.99,
    },
    {
      id: 1037,
      amount: 69.99,
    },
    {
      id: 1038,
      amount: 69.99,
    },
    {
      id: 1055,
      amount: 12.99,
    },
    {
      id: 1056,
      amount: 12.99,
    },
    {
      id: 1063,
      amount: 12.99,
    },
    {
      id: 1064,
      amount: 12.99,
    },
    {
      id: 1065,
      amount: 12.99,
    },
    {
      id: 1066,
      amount: 12.99,
    },
    {
      id: 1067,
      amount: 12.99,
    },
    {
      id: 1073,
      amount: 7.99,
    },
    {
      id: 1074,
      amount: 29.99,
    },
    {
      id: 1075,
      amount: 29.99,
    },
    {
      id: 1076,
      amount: 29.99,
    },
    {
      id: 1077,
      amount: 29.99,
    },
    {
      id: 1078,
      amount: 29.99,
    },
    {
      id: 1079,
      amount: 29.99,
    },
    {
      id: 1080,
      amount: 29.99,
    },
    {
      id: 1081,
      amount: 29.99,
    },
    {
      id: 1082,
      amount: 29.99,
    },
    {
      id: 1083,
      amount: 29.99,
    },
    {
      id: 1084,
      amount: 29.99,
    },
    {
      id: 1085,
      amount: 29.99,
    },
    {
      id: 1086,
      amount: 29.99,
    },
    {
      id: 1087,
      amount: 29.99,
    },
    {
      id: 1088,
      amount: 24.99,
    },
    {
      id: 1089,
      amount: 24.99,
    },
    {
      id: 1090,
      amount: 24.99,
    },
    {
      id: 1091,
      amount: 19.99,
    },
    {
      id: 1092,
      amount: 19.99,
    },
    {
      id: 1093,
      amount: 19.99,
    },
    {
      id: 1094,
      amount: 19.99,
    },
    {
      id: 1095,
      amount: 19.99,
    },
    {
      id: 1096,
      amount: 19.99,
    },
    {
      id: 1097,
      amount: 19.99,
    },
    {
      id: 1098,
      amount: 19.99,
    },
    {
      id: 1099,
      amount: 19.99,
    },
    {
      id: 1100,
      amount: 19.99,
    },
    {
      id: 1101,
      amount: 19.99,
    },
    {
      id: 1102,
      amount: 19.99,
    },
    {
      id: 1103,
      amount: 19.99,
    },
    {
      id: 1104,
      amount: 19.99,
    },
    {
      id: 1105,
      amount: 19.99,
    },
    {
      id: 1106,
      amount: 19.99,
    },
    {
      id: 1107,
      amount: 19.99,
    },
    {
      id: 1108,
      amount: 19.99,
    },
    {
      id: 1109,
      amount: 19.99,
    },
    {
      id: 1110,
      amount: 19.99,
    },
    {
      id: 1111,
      amount: 19.99,
    },
    {
      id: 1112,
      amount: 19.99,
    },
    {
      id: 1113,
      amount: 19.99,
    },
    {
      id: 1114,
      amount: 19.99,
    },
    {
      id: 1115,
      amount: 19.99,
    },
    {
      id: 1116,
      amount: 19.99,
    },
    {
      id: 1117,
      amount: 19.99,
    },
    {
      id: 1118,
      amount: 19.99,
    },
    {
      id: 1119,
      amount: 19.99,
    },
    {
      id: 1120,
      amount: 19.99,
    },
    {
      id: 1121,
      amount: 19.99,
    },
    {
      id: 1122,
      amount: 19.99,
    },
    {
      id: 1123,
      amount: 19.99,
    },
    {
      id: 1124,
      amount: 19.99,
    },
    {
      id: 1125,
      amount: 19.99,
    },
    {
      id: 1128,
      amount: 19.99,
    },
    {
      id: 1129,
      amount: 19.99,
    },
    {
      id: 1130,
      amount: 19.99,
    },
    {
      id: 1131,
      amount: 19.99,
    },
    {
      id: 1132,
      amount: 19.99,
    },
    {
      id: 1133,
      amount: 19.99,
    },
    {
      id: 1134,
      amount: 19.99,
    },
    {
      id: 1135,
      amount: 19.99,
    },
    {
      id: 1136,
      amount: 19.99,
    },
    {
      id: 1137,
      amount: 19.99,
    },
    {
      id: 1138,
      amount: 19.99,
    },
    {
      id: 1139,
      amount: 19.99,
    },
    {
      id: 1140,
      amount: 19.99,
    },
    {
      id: 1142,
      amount: 19.99,
    },
    {
      id: 1143,
      amount: 19.99,
    },
    {
      id: 1144,
      amount: 19.99,
    },
    {
      id: 1145,
      amount: 19.99,
    },
    {
      id: 1146,
      amount: 19.99,
    },
    {
      id: 1147,
      amount: 19.99,
    },
    {
      id: 1148,
      amount: 19.99,
    },
    {
      id: 1149,
      amount: 19.99,
    },
    {
      id: 1150,
      amount: 19.99,
    },
    {
      id: 1151,
      amount: 19.99,
    },
    {
      id: 1152,
      amount: 19.99,
    },
    {
      id: 1153,
      amount: 19.99,
    },
    {
      id: 1154,
      amount: 19.99,
    },
    {
      id: 1155,
      amount: 19.99,
    },
    {
      id: 1156,
      amount: 19.99,
    },
    {
      id: 1157,
      amount: 19.99,
    },
    {
      id: 1158,
      amount: 19.99,
    },
    {
      id: 1159,
      amount: 19.99,
    },
    {
      id: 1160,
      amount: 19.99,
    },
    {
      id: 1161,
      amount: 19.99,
    },
    {
      id: 1162,
      amount: 19.99,
    },
    {
      id: 1163,
      amount: 19.99,
    },
    {
      id: 1164,
      amount: 19.99,
    },
    {
      id: 1165,
      amount: 19.99,
    },
    {
      id: 1166,
      amount: 19.99,
    },
    {
      id: 1167,
      amount: 19.99,
    },
    {
      id: 1168,
      amount: 19.99,
    },
    {
      id: 1169,
      amount: 19.99,
    },
    {
      id: 1170,
      amount: 19.99,
    },
    {
      id: 1171,
      amount: 24.99,
    },
    {
      id: 1172,
      amount: 24.99,
    },
    {
      id: 1173,
      amount: 19.99,
    },
    {
      id: 1174,
      amount: 29.99,
    },
    {
      id: 1175,
      amount: 29.99,
    },
    {
      id: 1176,
      amount: 29.99,
    },
    {
      id: 1177,
      amount: 29.99,
    },
    {
      id: 1178,
      amount: 24.99,
    },
    {
      id: 1179,
      amount: 24.99,
    },
    {
      id: 1180,
      amount: 24.99,
    },
    {
      id: 1181,
      amount: 24.99,
    },
    {
      id: 743,
      amount: 29.99,
    },
    {
      id: 744,
      amount: 39.99,
    },
    {
      id: 745,
      amount: 69.99,
    },
    {
      id: 746,
      amount: 99.99,
    },
    {
      id: 762,
      amount: 19.99,
    },
    {
      id: 763,
      amount: 29.99,
    },
    {
      id: 764,
      amount: 34.99,
    },
    {
      id: 765,
      amount: 44.99,
    },
    {
      id: 766,
      amount: 44.99,
    },
    {
      id: 767,
      amount: 44.99,
    },
    {
      id: 768,
      amount: 29.99,
    },
    {
      id: 769,
      amount: 34.99,
    },
    {
      id: 770,
      amount: 29.99,
    },
    {
      id: 771,
      amount: 34.99,
    },
    {
      id: 772,
      amount: 29.99,
    },
    {
      id: 773,
      amount: 34.99,
    },
    {
      id: 774,
      amount: 24.99,
    },
    {
      id: 775,
      amount: 34.99,
    },
    {
      id: 776,
      amount: 39.99,
    },
    {
      id: 777,
      amount: 44.99,
    },
    {
      id: 778,
      amount: 44.99,
    },
    {
      id: 779,
      amount: 44.99,
    },
    {
      id: 780,
      amount: 69.99,
    },
    {
      id: 781,
      amount: 69.99,
    },
    {
      id: 890,
      amount: 24.99,
    },
    {
      id: 989,
      amount: 14.99,
    },
    {
      id: 990,
      amount: 24.99,
    },
    {
      id: 1002,
      amount: 49.99,
    },
    {
      id: 1003,
      amount: 39.99,
    },
    {
      id: 1004,
      amount: 59.99,
    },
    {
      id: 1005,
      amount: 59.99,
    },
    {
      id: 1051,
      amount: 29.99,
    },
    {
      id: 1052,
      amount: 34.99,
    },
    {
      id: 1053,
      amount: 29.99,
    },
    {
      id: 1054,
      amount: 29.99,
    },
    {
      id: 995,
      amount: 7.99,
    },
    {
      id: 742,
      amount: 199.99,
    },
    {
      id: 755,
      amount: 599.0,
    },
    {
      id: 756,
      amount: 99.0,
    },
    {
      id: 757,
      amount: 99.0,
    },
    {
      id: 683,
      amount: 22.49,
    },
    {
      id: 684,
      amount: 1099.0,
    },
    {
      id: 685,
      amount: 1199.0,
    },
    {
      id: 686,
      amount: 14.99,
    },
    {
      id: 687,
      amount: 49.99,
    },
    {
      id: 688,
      amount: 49.99,
    },
    {
      id: 690,
      amount: 96.0,
    },
    {
      id: 691,
      amount: 49.99,
    },
    {
      id: 693,
      amount: 49.99,
    },
    {
      id: 694,
      amount: 29.99,
    },
    {
      id: 695,
      amount: 25.0,
    },
    {
      id: 611,
      amount: 119.99,
    },
    {
      id: 612,
      amount: 119.99,
    },
    {
      id: 636,
      amount: 299.99,
    },
    {
      id: 649,
      amount: 349.99,
    },
    {
      id: 650,
      amount: 349.99,
    },
    {
      id: 651,
      amount: 69.99,
    },
    {
      id: 652,
      amount: 69.99,
    },
    {
      id: 653,
      amount: 69.99,
    },
    {
      id: 758,
      amount: 499.0,
    },
    {
      id: 759,
      amount: 109.0,
    },
    {
      id: 627,
      amount: 699.99,
    },
    {
      id: 702,
      amount: 34.99,
    },
    {
      id: 705,
      amount: 299.99,
    },
    {
      id: 706,
      amount: 299.99,
    },
    {
      id: 707,
      amount: 299.99,
    },
    {
      id: 721,
      amount: 34.99,
    },
    {
      id: 722,
      amount: 34.99,
    },
    {
      id: 723,
      amount: 34.99,
    },
    {
      id: 724,
      amount: 34.99,
    },
    {
      id: 727,
      amount: 229.99,
    },
    {
      id: 729,
      amount: 139.99,
    },
    {
      id: 730,
      amount: 139.99,
    },
    {
      id: 731,
      amount: 139.99,
    },
    {
      id: 732,
      amount: 139.99,
    },
    {
      id: 734,
      amount: 139.99,
    },
    {
      id: 751,
      amount: 44.9,
    },
    {
      id: 753,
      amount: 79.99,
    },
    {
      id: 754,
      amount: 279.99,
    },
    {
      id: 886,
      amount: 9.99,
    },
    {
      id: 887,
      amount: 9.99,
    },
    {
      id: 888,
      amount: 9.99,
    },
    {
      id: 728,
      amount: 249.99,
    },
    {
      id: 703,
      amount: 79.99,
    },
    {
      id: 704,
      amount: 49.99,
    },
    {
      id: 708,
      amount: 49.99,
    },
    {
      id: 709,
      amount: 49.99,
    },
    {
      id: 710,
      amount: 49.99,
    },
    {
      id: 711,
      amount: 49.99,
    },
    {
      id: 713,
      amount: 49.99,
    },
    {
      id: 714,
      amount: 49.99,
    },
    {
      id: 720,
      amount: 99.99,
    },
    {
      id: 726,
      amount: 39.99,
    },
    {
      id: 735,
      amount: 69.99,
    },
    {
      id: 736,
      amount: 69.99,
    },
    {
      id: 737,
      amount: 69.99,
    },
    {
      id: 738,
      amount: 69.99,
    },
    {
      id: 739,
      amount: 129.99,
    },
    {
      id: 740,
      amount: 99.99,
    },
    {
      id: 741,
      amount: 99.99,
    },
    {
      id: 747,
      amount: 89.99,
    },
    {
      id: 749,
      amount: 99.99,
    },
    {
      id: 750,
      amount: 45.99,
    },
    {
      id: 752,
      amount: 44.9,
    },
    {
      id: 991,
      amount: 19.99,
    },
    {
      id: 992,
      amount: 19.99,
    },
    {
      id: 998,
      amount: 39.99,
    },
    {
      id: 999,
      amount: 39.99,
    },
    {
      id: 1000,
      amount: 39.99,
    },
    {
      id: 1001,
      amount: 39.99,
    },
    {
      id: 1041,
      amount: 24.99,
    },
    {
      id: 1042,
      amount: 24.99,
    },
    {
      id: 1043,
      amount: 24.99,
    },
    {
      id: 1044,
      amount: 24.99,
    },
    {
      id: 1045,
      amount: 29.99,
    },
    {
      id: 1046,
      amount: 24.99,
    },
    {
      id: 1047,
      amount: 24.99,
    },
    {
      id: 1048,
      amount: 19.99,
    },
    {
      id: 1049,
      amount: 19.99,
    },
    {
      id: 1050,
      amount: 19.99,
    },
    {
      id: 725,
      amount: 179.99,
    },
    {
      id: 677,
      amount: 39.0,
    },
    {
      id: 868,
      amount: 109.99,
    },
    {
      id: 869,
      amount: 39.99,
    },
    {
      id: 1251,
      amount: 14.99,
    },
    {
      id: 1252,
      amount: 34.99,
    },
    {
      id: 1253,
      amount: 99.99,
    },
    {
      id: 1256,
      amount: 14.99,
    },
    {
      id: 1335,
      amount: 12.99,
    },
    {
      id: 1336,
      amount: 14.99,
    },
    {
      id: 1182,
      amount: 24.99,
    },
    {
      id: 1183,
      amount: 24.99,
    },
    {
      id: 1184,
      amount: 24.99,
    },
    {
      id: 1185,
      amount: 24.99,
    },
    {
      id: 1186,
      amount: 24.99,
    },
    {
      id: 1187,
      amount: 24.99,
    },
    {
      id: 1188,
      amount: 22.99,
    },
    {
      id: 1189,
      amount: 29.99,
    },
    {
      id: 1190,
      amount: 29.99,
    },
    {
      id: 1191,
      amount: 29.99,
    },
    {
      id: 1193,
      amount: 19.99,
    },
    {
      id: 1194,
      amount: 29.99,
    },
    {
      id: 1195,
      amount: 12.99,
    },
    {
      id: 1196,
      amount: 34.99,
    },
    {
      id: 1197,
      amount: 24.99,
    },
    {
      id: 1198,
      amount: 24.99,
    },
    {
      id: 1199,
      amount: 24.99,
    },
    {
      id: 1200,
      amount: 19.99,
    },
    {
      id: 1201,
      amount: 34.99,
    },
    {
      id: 1202,
      amount: 34.99,
    },
    {
      id: 1203,
      amount: 14.99,
    },
    {
      id: 1204,
      amount: 29.99,
    },
    {
      id: 1205,
      amount: 12.99,
    },
    {
      id: 1206,
      amount: 29.99,
    },
    {
      id: 1207,
      amount: 29.99,
    },
    {
      id: 1208,
      amount: 29.99,
    },
    {
      id: 1209,
      amount: 29.99,
    },
    {
      id: 1210,
      amount: 29.99,
    },
    {
      id: 1211,
      amount: 39.99,
    },
    {
      id: 1212,
      amount: 39.99,
    },
    {
      id: 1213,
      amount: 29.99,
    },
    {
      id: 1214,
      amount: 29.99,
    },
    {
      id: 1215,
      amount: 29.99,
    },
    {
      id: 1216,
      amount: 29.99,
    },
    {
      id: 1217,
      amount: 12.99,
    },
    {
      id: 1218,
      amount: 12.99,
    },
    {
      id: 1219,
      amount: 19.99,
    },
    {
      id: 1220,
      amount: 29.99,
    },
    {
      id: 1221,
      amount: 29.99,
    },
    {
      id: 1222,
      amount: 29.99,
    },
    {
      id: 1223,
      amount: 29.99,
    },
    {
      id: 1224,
      amount: 29.99,
    },
    {
      id: 1225,
      amount: 29.99,
    },
    {
      id: 1226,
      amount: 29.99,
    },
    {
      id: 1227,
      amount: 29.99,
    },
    {
      id: 1228,
      amount: 29.99,
    },
    {
      id: 1229,
      amount: 29.99,
    },
    {
      id: 1230,
      amount: 29.99,
    },
    {
      id: 1231,
      amount: 29.99,
    },
    {
      id: 1232,
      amount: 29.99,
    },
    {
      id: 1233,
      amount: 29.99,
    },
    {
      id: 1234,
      amount: 29.99,
    },
    {
      id: 1235,
      amount: 29.99,
    },
    {
      id: 1236,
      amount: 29.99,
    },
    {
      id: 1237,
      amount: 39.99,
    },
    {
      id: 1238,
      amount: 39.99,
    },
    {
      id: 1239,
      amount: 39.99,
    },
    {
      id: 1240,
      amount: 39.99,
    },
    {
      id: 1241,
      amount: 39.99,
    },
    {
      id: 1242,
      amount: 39.99,
    },
    {
      id: 1243,
      amount: 39.99,
    },
    {
      id: 1247,
      amount: 19.99,
    },
    {
      id: 1250,
      amount: 19.99,
    },
    {
      id: 1278,
      amount: 12.99,
    },
    {
      id: 1282,
      amount: 9.99,
    },
    {
      id: 1297,
      amount: 12.99,
    },
    {
      id: 1299,
      amount: 12.99,
    },
    {
      id: 1300,
      amount: 12.99,
    },
    {
      id: 1302,
      amount: 7.99,
    },
    {
      id: 1305,
      amount: 12.99,
    },
    {
      id: 1312,
      amount: 7.99,
    },
    {
      id: 1316,
      amount: 7.99,
    },
    {
      id: 1317,
      amount: 7.99,
    },
    {
      id: 1318,
      amount: 7.99,
    },
    {
      id: 1320,
      amount: 7.99,
    },
    {
      id: 1323,
      amount: 7.99,
    },
    {
      id: 1324,
      amount: 7.99,
    },
    {
      id: 1325,
      amount: 7.99,
    },
    {
      id: 1326,
      amount: 7.99,
    },
    {
      id: 1327,
      amount: 7.99,
    },
    {
      id: 1330,
      amount: 12.99,
    },
    {
      id: 1334,
      amount: 7.99,
    },
    {
      id: 1337,
      amount: 19.99,
    },
    {
      id: 1338,
      amount: 19.99,
    },
    {
      id: 1379,
      amount: 12.99,
    },
    {
      id: 1380,
      amount: 7.99,
    },
    {
      id: 1381,
      amount: 12.99,
    },
    {
      id: 1382,
      amount: 12.99,
    },
    {
      id: 1383,
      amount: 12.99,
    },
    {
      id: 1384,
      amount: 14.99,
    },
    {
      id: 1385,
      amount: 19.99,
    },
    {
      id: 1386,
      amount: 12.99,
    },
    {
      id: 1387,
      amount: 12.99,
    },
    {
      id: 1388,
      amount: 7.99,
    },
    {
      id: 1389,
      amount: 7.99,
    },
    {
      id: 1390,
      amount: 7.99,
    },
    {
      id: 1391,
      amount: 7.99,
    },
    {
      id: 1392,
      amount: 7.99,
    },
    {
      id: 1393,
      amount: 7.99,
    },
    {
      id: 1394,
      amount: 12.99,
    },
    {
      id: 1395,
      amount: 7.99,
    },
    {
      id: 1396,
      amount: 12.99,
    },
    {
      id: 1397,
      amount: 12.99,
    },
    {
      id: 1398,
      amount: 12.99,
    },
    {
      id: 1399,
      amount: 12.99,
    },
    {
      id: 1400,
      amount: 7.99,
    },
    {
      id: 1401,
      amount: 7.99,
    },
    {
      id: 1402,
      amount: 7.99,
    },
    {
      id: 1403,
      amount: 12.99,
    },
    {
      id: 1404,
      amount: 12.99,
    },
    {
      id: 1405,
      amount: 7.99,
    },
    {
      id: 1406,
      amount: 19.99,
    },
    {
      id: 1407,
      amount: 12.99,
    },
    {
      id: 1408,
      amount: 12.99,
    },
    {
      id: 1244,
      amount: 29.99,
    },
    {
      id: 1245,
      amount: 29.99,
    },
    {
      id: 1246,
      amount: 29.99,
    },
    {
      id: 1257,
      amount: 19.99,
    },
    {
      id: 1258,
      amount: 19.99,
    },
    {
      id: 1259,
      amount: 19.99,
    },
    {
      id: 1260,
      amount: 19.99,
    },
    {
      id: 1261,
      amount: 19.99,
    },
    {
      id: 1262,
      amount: 9.99,
    },
    {
      id: 1263,
      amount: 19.99,
    },
    {
      id: 1264,
      amount: 19.99,
    },
    {
      id: 1265,
      amount: 19.99,
    },
    {
      id: 1267,
      amount: 9.99,
    },
    {
      id: 1268,
      amount: 9.99,
    },
    {
      id: 1269,
      amount: 29.99,
    },
    {
      id: 1270,
      amount: 16.99,
    },
    {
      id: 1271,
      amount: 34.99,
    },
    {
      id: 1272,
      amount: 19.99,
    },
    {
      id: 1273,
      amount: 39.99,
    },
    {
      id: 1275,
      amount: 9.99,
    },
    {
      id: 1276,
      amount: 9.99,
    },
    {
      id: 1277,
      amount: 9.99,
    },
    {
      id: 1284,
      amount: 7.99,
    },
    {
      id: 1286,
      amount: 12.99,
    },
    {
      id: 1291,
      amount: 7.99,
    },
    {
      id: 1292,
      amount: 7.99,
    },
    {
      id: 1293,
      amount: 7.99,
    },
    {
      id: 1294,
      amount: 7.99,
    },
    {
      id: 1295,
      amount: 7.99,
    },
    {
      id: 1296,
      amount: 7.99,
    },
    {
      id: 1461,
      amount: 7.99,
    },
    {
      id: 1462,
      amount: 19.99,
    },
    {
      id: 1463,
      amount: 19.99,
    },
    {
      id: 1464,
      amount: 19.99,
    },
    {
      id: 1465,
      amount: 12.99,
    },
    {
      id: 1466,
      amount: 19.99,
    },
    {
      id: 1467,
      amount: 19.99,
    },
    {
      id: 1468,
      amount: 7.99,
    },
    {
      id: 1469,
      amount: 7.99,
    },
    {
      id: 1470,
      amount: 12.99,
    },
    {
      id: 1471,
      amount: 7.99,
    },
    {
      id: 1472,
      amount: 19.99,
    },
    {
      id: 1473,
      amount: 12.99,
    },
    {
      id: 1474,
      amount: 7.99,
    },
    {
      id: 1475,
      amount: 7.99,
    },
    {
      id: 1476,
      amount: 7.99,
    },
    {
      id: 1311,
      amount: 7.99,
    },
    {
      id: 1248,
      amount: 9.99,
    },
    {
      id: 1249,
      amount: 9.99,
    },
    {
      id: 1375,
      amount: 49.99,
    },
    {
      id: 1376,
      amount: 12.99,
    },
    {
      id: 1362,
      amount: 209.99,
    },
    {
      id: 1355,
      amount: 12.99,
    },
    {
      id: 1339,
      amount: 19.99,
    },
    {
      id: 1345,
      amount: 34.99,
    },
    {
      id: 1346,
      amount: 34.99,
    },
    {
      id: 1347,
      amount: 34.99,
    },
    {
      id: 1348,
      amount: 34.99,
    },
    {
      id: 1349,
      amount: 24.99,
    },
    {
      id: 1350,
      amount: 24.99,
    },
    {
      id: 1351,
      amount: 24.99,
    },
    {
      id: 1352,
      amount: 24.99,
    },
    {
      id: 1353,
      amount: 24.99,
    },
    {
      id: 1354,
      amount: 24.99,
    },
  ],
};
