import styled from 'styled-components';
import { entranceDown } from 'utils/animations';

export const Wrapper = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  grid-auto-rows: minmax(min-content, max-content);
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ProductWrapper = styled.div.attrs(({ index }) => ({
  style: {
    animationDelay: `${index * 50}ms`,
  },
}))`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  animation: ${entranceDown};
  animation-duration: 250ms;
  animation-timing-function: ease-out;
  animation-fill-mode: backwards;
  animation-delay: ms;
  overflow: hidden;
  cursor: pointer;

  & h3 {
    margin-top: 0.5rem;
  }

  & h5 {
    padding: 0 6px;
    font-size: 13px;
    text-align: center;
  }
`;

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  max-height: 350px;
  padding: 16px;

  & img {
    max-width: 100%;
    max-height: 318px;
  }
`;

export const Divider = styled.hr`
  margin: 0;
  width: 100%;
  border-top-color: #efefef;
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 10px;
`;

export const estiloPrecioEspecial = {
  marginLeft: '0.5rem', 
  fontWeight: 'semi-bold',
  color: 'red',
}

export const estiloPrecio = {
  textDecoration: 'line-through',
}