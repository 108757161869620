import React, { useState, useRef, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import request from 'utils/request';

import { Collapse, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Catalogo from 'components/catalogo';
import EufyImage from './images/eufy.jpeg';
import {
  useStyles,
  SearchTitle,
  SearchInput,
  CarouselItem,
  CarouselCaptionLeft,
  CarouselImageRight,
} from './styles';

function HogarConectado() {
  const [showCarousel, setShowCarousel] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [shouldLoadMoreItems, setShouldLoadMoreItems] = useState(false);
  const searchInputRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    request(`https://api.bigbenespana.es/api/totem/products`, {
      queryParams: {
        filter: {
          'menus.Id': 2,
          'productos.estado': 1,
        },
        ...(searchValue.trim().length > 0
          ? { search: searchValue.trim() }
          : null),
        skip: 0,
        limit: 30,
      },
    })
      .then(data => {
        setProducts(data);
        setShouldLoadMoreItems(data.length > 0);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  }, [searchValue]);

  const loadMoreProducts = skip => {
    setLoading(true);
    request(`https://api.bigbenespana.es/api/totem/products`, {
      queryParams: {
        filter: {
          'menus.Id': 2,
          'productos.estado': 1,
        },
        ...(searchValue.trim().length > 0
          ? { search: searchValue.trim() }
          : null),
        skip,
        limit: 30,
      },
    })
      .then(data => {
        setProducts(prevState => [...prevState, ...data]);
        setShouldLoadMoreItems(data.length > 0);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div>
      <Collapse in={showCarousel} timeout={300}>
        <Carousel
          indicators={false}
          controls={false}
          style={{ borderBottom: '1px solid #ececec' }}
        >
          <Carousel.Item as={CarouselItem}>
            <div
              className="container position-relative"
              style={{ height: 440 }}
            >
              <CarouselImageRight
                src={EufyImage}
                draggable={false}
                style={{ height: 640 }}
                alt="Funda semirígida azul para iPhone"
              />
              <Carousel.Caption as={CarouselCaptionLeft}>
                <h2>Equipa tu vivienda.</h2>
                <Link to="/hogar-conectado">
                  Disfruta de un mayor control de tu hogar
                </Link>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </Collapse>
      <div className="container">
        <div className="text-center">
          <SearchTitle>Encuentra los dispositivos que buscas.</SearchTitle>
          <SearchInput>
            <SearchIcon
              fontSize="large"
              style={{ marginRight: 8, color: '#bbb' }}
            />
            <InputBase
              inputRef={searchInputRef}
              type="search"
              placeholder="Buscar dispositivos"
              className={classes.input}
              value={searchValue}
              onChange={({ target }) => {
                setShowCarousel(
                  target.value === '' &&
                    document.activeElement !== searchInputRef.current,
                );
                setSearchValue(target.value);
              }}
              onKeyUp={({ target }) => {
                setShowCarousel(
                  target.value === '' &&
                    document.activeElement !== searchInputRef.current,
                );
                setSearchValue(target.value);
              }}
              onFocus={() => {
                setShowCarousel(false);
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              }}
              onBlur={() => {
                if (searchValue === '') {
                  setShowCarousel(true);
                }
              }}
              fullWidth
            />
          </SearchInput>
        </div>
      </div>
      <div className="container mb-5">
        <Catalogo
          loading={loading}
          products={products}
          loadMoreItems={loadMoreProducts}
          shouldLoadMoreItems={shouldLoadMoreItems}
        />
      </div>
    </div>
  );
}

export default HogarConectado;
