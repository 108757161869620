import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
  Stepper,
  Step,
  StepLabel,
  Collapse,
  Paper,
  Button,
  Select,
  MenuItem,
  Divider,
  Table,
} from '@material-ui/core';
import Input from 'components/input';
import { shops } from 'utils/data';
import PageContainer from 'components/PageContainer';
import ShopIcon from '@material-ui/icons/Store';
import ShipmentIcon from '@material-ui/icons/LocalShipping';
import ProductTable from 'components/cesta/ProductTable';

import { useStyles } from './styles';

function Buy({ products, globalPromotionalCode }) {
  const [activeStep, setActiveStep] = useState(0);
  const [shipmentType, setShipmentType] = useState('');
  const [shopId, setShopId] = useState('002');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [provincia, setProvincia] = useState('');
  const [localidad, setLocalidad] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [promotionalCode, setPromotionalCode] = useState(globalPromotionalCode);
  const [isValidCode, setIsValidCode] = useState(false);
  const [validatingCode, setValidatingCode] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const codeInputRef = useRef(null);
  const [showProducts, setShowProducts] = useState(false);

  const classes = useStyles();
  const steps = ['Datos del pedido', 'Método de pago', 'Resumen del pedido'];

  let validatingTimeout;
  useEffect(() => {
    clearTimeout(validatingTimeout);
    setIsValidCode(false);
    setValidatingCode(false);
    if (promotionalCode.length > 0) {
      setValidatingCode(true);
      validatingTimeout = setTimeout(() => {
        setValidatingCode(false);
        setIsValidCode(promotionalCode === 'PROMOTOTEM');
      }, 800);
    }
  }, [promotionalCode]);

  useEffect(() => {
    if (showCodeInput && codeInputRef) {
      codeInputRef.current.focus();
    }
  }, [showCodeInput]);

  const handlePromotionalCodeChange = event => {
    const value = event.target.value.trim().toUpperCase();
    setPromotionalCode(value);
  };

  const checkInvalidFields = event => {
    event.preventDefault();
    const requiredFields =
      shipmentType === 'tienda'
        ? [firstname, lastname, email]
        : [
            firstname,
            lastname,
            email,
            phone,
            address,
            provincia,
            localidad,
            postalCode,
          ];
    const hasInvalidFields = requiredFields.some(
      field => field.trim().length === 0,
    );
    if (!hasInvalidFields) {
      setActiveStep(1);
    }
  };

  return products.length === 0 ? (
    <Redirect to="/" />
  ) : (
    <PageContainer className="container my-4">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="mt-3">
        <Paper elevation={0} square>
          <Collapse in={showProducts} timeout={150}>
            <div>
              <div className={classes.customerForm}>
                <Table>
                  <ProductTable products={products} />
                </Table>
              </div>
              <Divider />
            </div>
          </Collapse>
          <div className={classes.customerForm}>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div
                className={classes.link}
                onClick={() => setShowProducts(prevState => !prevState)}
              >{`Mostrar ${showProducts ? 'menos' : 'más'} detalles`}</div>
            </div>
          </div>
        </Paper>
      </div>
      <div className="mt-3">
        <Paper elevation={0} square>
          <div className={classes.customerForm}>
            <div className="d-flex flex-column align-items-end justify-content-center">
              <div style={{ lineHeight: '31px', fontSize: 28 }}>
                {new Intl.NumberFormat('es-Es', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(
                  products.reduce(
                    (a, b) =>
                      a + (isNaN(b.amount) ? 0 : b.amount * b.price.amount),
                    0,
                  ) + (shipmentType === 'domicilio' ? 6.99 : 0),
                )}
              </div>
              {shipmentType === 'domicilio' && (
                <div style={{ color: '#7d7d7d', fontSize: 12 }}>
                  Recargos incluidos:{' '}
                  <span style={{ fontSize: 14 }}>6.99 €</span>
                </div>
              )}
              {isValidCode ? (
                <React.Fragment>
                  <div style={{ color: '#7d7d7d', fontSize: 12 }}>
                    Descuentos incluidos:{' '}
                    <span style={{ fontSize: 14 }}>3.99 €</span>
                  </div>
                  <div
                    className={classes.link}
                    style={{ color: '#e02828' }}
                    onClick={() => {
                      setShowCodeInput(false);
                      setPromotionalCode('');
                    }}
                  >
                    Quitar descuento
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    className={classes.link}
                    onClick={() => setShowCodeInput(prevState => !prevState)}
                  >
                    ¿Tienes un código promocional?
                  </div>
                  <Collapse
                    in={showCodeInput}
                    timeout={150}
                    style={{ width: '100%', maxWidth: 270 }}
                  >
                    <div className="d-flex align-items-center mt-2">
                      <Input
                        inputRef={codeInputRef}
                        type="text"
                        className={
                          validatingCode ? 'canLoad loading' : 'canLoad'
                        }
                        placeholder="Introduzca su código promocional"
                        value={promotionalCode}
                        onChange={handlePromotionalCodeChange}
                        onKeyUp={handlePromotionalCodeChange}
                        inputProps={{
                          spellCheck: false,
                        }}
                        error={
                          promotionalCode.length > 0 &&
                          !isValidCode &&
                          !validatingCode
                        }
                        fullWidth
                      />
                    </div>
                  </Collapse>
                </React.Fragment>
              )}
            </div>
          </div>
          <Divider />
        </Paper>
        <Collapse in={activeStep === 0} timeout={300}>
          <Paper elevation={0} square>
            <form onSubmit={checkInvalidFields}>
              <div className={classes.customerForm}>
                <div style={{ marginLeft: 11, marginRight: 11 }}>
                  <p>
                    Selecciona uno de los métodos de entrega disponibles y
                    rellena tus datos personales
                  </p>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-control-label">
                          Método de entrega:
                        </label>
                        <div className="d-flex">
                          <div
                            className={`${classes.shipmentMethod}${
                              shipmentType === 'tienda' ? ' active' : ''
                            }`}
                            onClick={() => setShipmentType('tienda')}
                          >
                            <div className="d-flex align-items-end justify-content-center flex-grow-1 align-self-stretch">
                              <ShopIcon
                                style={{
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontSize: 52,
                                }}
                              />
                            </div>
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              style={{ padding: 8, minHeight: 55 }}
                            >
                              <div>Recogida en tienda</div>
                            </div>
                          </div>
                          <div
                            className={`${classes.shipmentMethod}${
                              shipmentType === 'domicilio' ? ' active' : ''
                            }`}
                            onClick={() => setShipmentType('domicilio')}
                          >
                            <div>
                              <ShipmentIcon
                                style={{
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontSize: 52,
                                }}
                              />
                            </div>
                            <div
                              className="d-flex flex-column align-items-center justify-content-center"
                              style={{ padding: 8, minHeight: 55 }}
                            >
                              <div>Envío a domicilio</div>
                              <div
                                style={{
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontSize: 12,
                                }}
                              >
                                * Suplemento de 6,99 € por gastos de envío
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Collapse in={shipmentType.length !== 0} timeout={300}>
                    <div className="row">
                      <div className="col-md-6">
                        {shipmentType === 'tienda' && (
                          <div className="form-group">
                            <label className="form-control-label">
                              Tienda:
                            </label>
                            <Select
                              input={<Input />}
                              value={shopId}
                              onChange={({ target }) => setShopId(target.value)}
                              fullWidth
                            >
                              {shops.map(shop => (
                                <MenuItem
                                  key={shop.id}
                                  value={shop.id}
                                  className={classes.shopMenu}
                                >
                                  <div>{shop.name}</div>
                                  <div
                                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                  >{`${shop.address}, ${shop.cp} ${shop.province}, ${shop.city}`}</div>
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        )}
                        {shipmentType === 'domicilio' && (
                          <React.Fragment>
                            <div className="form-group">
                              <label className="form-control-label">
                                Dirección completa:
                              </label>
                              <Input
                                type="text"
                                placeholder="Intoduzca su dirección (Calle, piso, puerta)"
                                value={address}
                                onChange={({ target }) =>
                                  setAddress(target.value)
                                }
                                onKeyUp={({ target }) =>
                                  setAddress(target.value)
                                }
                                fullWidth
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">
                                Código Postal:
                              </label>
                              <Input
                                type="text"
                                placeholder="Intoduzca su código postal"
                                value={postalCode}
                                onChange={({ target }) =>
                                  setPostalCode(target.value)
                                }
                                onKeyUp={({ target }) =>
                                  setPostalCode(target.value)
                                }
                                fullWidth
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">
                                Localidad:
                              </label>
                              <Input
                                type="text"
                                placeholder="Intoduzca su localidad"
                                value={localidad}
                                onChange={({ target }) =>
                                  setLocalidad(target.value)
                                }
                                onKeyUp={({ target }) =>
                                  setLocalidad(target.value)
                                }
                                fullWidth
                                required
                              />
                            </div>
                            <div className="form-group">
                              <label className="form-control-label">
                                Provincia:
                              </label>
                              <Input
                                type="text"
                                placeholder="Intoduzca su provincia"
                                value={provincia}
                                onChange={({ target }) =>
                                  setProvincia(target.value)
                                }
                                onKeyUp={({ target }) =>
                                  setProvincia(target.value)
                                }
                                fullWidth
                                required
                              />
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-control-label">Nombre:</label>
                          <Input
                            type="text"
                            placeholder="Intoduzca su nombre"
                            value={firstname}
                            onChange={({ target }) => setFirstname(target.value)}
                            onKeyUp={({ target }) => setFirstname(target.value)}
                            fullWidth
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">
                            Apellidos:
                          </label>
                          <Input
                            type="text"
                            placeholder="Intoduzca tus apellidos"
                            value={lastname}
                            onChange={({ target }) => setLastname(target.value)}
                            onKeyUp={({ target }) => setLastname(target.value)}
                            fullWidth
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">
                            Dirección de correo:
                          </label>
                          <Input
                            type="text"
                            placeholder="Intoduzca su correo electrónico"
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                            onKeyUp={({ target }) => setEmail(target.value)}
                            fullWidth
                            required
                          />
                        </div>
                        {shipmentType === 'domicilio' && (
                          <div className="form-group">
                            <label className="form-control-label">
                              Número de teléfono:
                            </label>
                            <Input
                              type="text"
                              placeholder="Intoduzca su número de contacto"
                              value={phone}
                              onChange={({ target }) => setPhone(target.value)}
                              onKeyUp={({ target }) => setPhone(target.value)}
                              fullWidth
                              required
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
              <Divider />
              <div className={classes.customerForm}>
                <div style={{ marginLeft: 11, marginRight: 11 }}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      Continuar
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Paper>
        </Collapse>
        <Collapse in={activeStep === 1} timeout={300}>
          <Paper className="d-flex flex-column" elevation={0} square>
            <div
              className={classes.customerForm}
              style={{ display: 'inline-block', margin: '0 auto' }}
            >
              <p style={{ marginLeft: 11, marginRight: 11 }}>
                Selecciona uno de los métodos de pago disponibles
              </p>
              <div className="d-flex">
                <div className={classes.paymentMethod}></div>
                <div className={classes.paymentMethod}></div>
                <div className={classes.paymentMethod}></div>
                <div className={classes.paymentMethod}></div>
                <div className={classes.paymentMethod}></div>
              </div>
            </div>
            <Divider />
            <div className={classes.customerForm}>
              <Button type="button" onClick={() => setActiveStep(0)} fullWidth>
                Volver atrás
              </Button>
            </div>
          </Paper>
        </Collapse>
      </div>
    </PageContainer>
  );
}

Buy.propTypes = {
  products: PropTypes.array.isRequired,
  globalPromotionalCode: PropTypes.string.isRequired,
};

const mapStateToProps = ({ cesta }) => ({
  products: cesta.products,
  globalPromotionalCode: cesta.promotionalCode,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Buy);
