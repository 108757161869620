import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(theme => ({
  select: {
    fontSize: 24,
    fontWeight: 300,
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
}));

export const SearchTitle = styled.h3`
  margin: 32px 0 0 0;
  padding-bottom: 26px;
  font-weight: normal;
`;

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 44px;
  padding: 7px 16px;
  width: 65%;
  background-color: #fafafa;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  color: #333;
  font-size: 24px;
  line-height: 1.20849;
`;

export const CategoriesWrapper = styled.ul`
  margin: 0;
  margin-bottom: 16px;
  border-bottom: 1px solid #d6d6d6;
  list-style: none;
  text-align: center;

  & li {
    display: inline-block;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
  }

  & li.active {
    color: #007bff;
  }
`
