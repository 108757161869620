import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  customerForm: {
    padding: 16,
  },
  shopMenu: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 8,
    paddingBottom: 8,
  },
  shipmentMethod: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    flexBasis: '50%',
    padding: '20px 8px',
    width: 'auto',
    minHeight: '166px',
    backgroundColor: '#fff',
    border: '1px solid #d4d4d4',
    borderRadius: 3,
    fontSize: 14,
    textAlign: 'center',
    transition: 'opacity 200ms linear',
    cursor: 'pointer',
    '&+&': {
      marginLeft: 11,
    },
    '&:hover:not(.active)': {
      boxShadow: '0 5px 10px 0 rgba(0,0,0,.14)',
    },
    '&.active': {
      paddingTop: '41px',
      borderColor: '#2885e0',
    },
    '&.active::before': {
      display: 'block',
      content: '"Seleccionado"',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: 3,
      backgroundColor: '#2885e0',
      color: '#fff',
      fontSize: 10,
      textAlign: 'center',
      textTransform: 'uppercase',
    },
  },
  paymentMethod: {
    margin: '0 11px 20px',
    padding: '20px 8px',
    width: '145px',
    height: '145px',
    backgroundColor: '#fff',
    border: '1px solid #d4d4d4',
    borderRadius: 3,
    textAlign: 'center',
    transition: 'opacity 200ms linear',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0 5px 10px 0 rgba(0,0,0,.14)',
    },
  },
  link: {
    color: '#2885e0',
    fontSize: 14,
    cursor: 'pointer',
  },
}));
