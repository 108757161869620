import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from '@material-ui/lab';
import { ProductDialog } from 'components/product';
import ProductItem from './productItem';
import {
  Wrapper,
  ProductWrapper,
  ProductImage,
  Divider,
  ProductDetails,
} from './styles';

function Catalogo({
  products,
  loading,
  loadMoreItems,
  shouldLoadMoreItems,
  hideProducts,
  isPacoMartinez = false,
}) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const listRef = useRef(null);

  const handleScroll = () => {
    const windowScrollPosition = window.scrollY + window.innerHeight;
    const listScrollPosition =
      listRef.current.clientHeight + listRef.current.offsetTop - 300;
    if (
      windowScrollPosition > listScrollPosition &&
      !loading &&
      shouldLoadMoreItems
    ) {
      loadMoreItems(products.length);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [products, loading, shouldLoadMoreItems]);

  const openProductDialog = (product, index = undefined) => {
    if (index !== undefined) {
      setSelectedIndex(index);
    }
    setSelectedProduct(product);
    setOpen(true);
  };

  const openNextProduct = () => {
    setSelectedIndex(prevState =>
      prevState < products.length - 1 ? prevState + 1 : prevState,
    );
    setSelectedProduct(
      products[
        selectedIndex < products.length - 1 ? selectedIndex + 1 : selectedIndex
      ],
    );
  };

  const openPrevProduct = () => {
    setSelectedIndex(prevState => (prevState > 0 ? prevState - 1 : prevState));
    setSelectedProduct(
      products[selectedIndex > 0 ? selectedIndex - 1 : selectedIndex],
    );
  };

  const closeProductDialog = () => {
    setOpen(false);
  };

  return products.length === 0 && !loading ? (
    <div ref={listRef}>
      {!hideProducts && (
        <p className="text-center my-5">
          No hemos encontrado productos que coincidan con el criterio de
          búsqueda.
        </p>
      )}
    </div>
  ) : (
    <Wrapper ref={listRef}>
      {!hideProducts && (
        <React.Fragment>
          {products.map((product, index, array) => {
            const animationDelay =
              index - (Math.ceil(products.length / 30) - 1) * 30;
            return (
              <ProductItem
                key={product.id}
                product={product}
                index={animationDelay}
                array={array}
                openProductDialog={openProductDialog}
                isPacoMartinez={isPacoMartinez}
              />
            );
          })}
          {loading &&
            Array.from(new Array(3)).map((_, index) => (
              <ProductWrapper key={index}>
                <ProductImage>
                  <Skeleton variant="rect" width="100%" height="100%" />
                </ProductImage>
                <Divider />
                <ProductDetails>
                  <Skeleton width="100%" style={{ margin: '8px 0' }} />
                  <Skeleton width="60%" style={{ margin: '0 0 8px' }} />
                </ProductDetails>
              </ProductWrapper>
            ))}
          <ProductDialog
            open={open}
            onClose={closeProductDialog}
            product={selectedProduct}
            openNextProduct={openNextProduct}
            openPrevProduct={openPrevProduct}
            openProductDialog={openProductDialog}
            hasNextProduct={selectedIndex < products.length - 1}
            hasPrevProduct={selectedIndex > 0}
            isPacoMartinez={isPacoMartinez}
          />
        </React.Fragment>
      )}
    </Wrapper>
  );
}

Catalogo.propTypes = {
  products: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  loadMoreItems: PropTypes.func,
  shouldLoadMoreItems: PropTypes.bool,
  hideProducts: PropTypes.bool,
};

export default Catalogo;
