import React from 'react';
import { Route } from 'react-router-dom';
import Videojuegos from 'containers/videojuegos';
import AccesoriosTelefonia from 'containers/accesorios-telefonia';
import HogarConectado from 'containers/hogar-conectado';
import DeportesSalud from 'containers/deportes-salud';
import About from 'containers/about';
import Header from 'components/header';
import Buy from 'containers/buy';
import AdminPage from 'containers/admin';
import PacoMartinez from 'containers/paco-martinez';

function App({ location }) {
  return (
    <React.Fragment>
      <Header location={location} />
      <main>
        <Route exact path="/" component={AccesoriosTelefonia} />
        <Route path="/accesorios-telefonia" component={AccesoriosTelefonia} />
        <Route path="/hogar-conectado" component={HogarConectado} />
        <Route path="/deportes-salud" component={DeportesSalud} />
        <Route path="/gaming" component={Videojuegos} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/buy-process" component={Buy} />
        <Route exact path="/admin" component={AdminPage} />
        <Route exact path="/paco-martinez" component={PacoMartinez} />
      </main>
    </React.Fragment>
  );
}

export default App;
