import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: '16px 12px',
    backgroundColor: '#fff',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      paddingRight: 24,
    },
  },
  tableBody: {
    '& tr:last-child td': {
      border: 0,
    },
  },
}));
