export const pacoProducts = [
  {
    id: 1,
    image: '12432332_02_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '15.20',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 2,
    image: '12432330_16_main.jpg',
    name: 'BOLSO SHOPPER BÁSICO PERFORADO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 3,
    image: '12432302_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 4,
    image: '11932308_16_main.jpg',
    name: 'BOLSO TOTE CANVAS LLAVERO CUERDA',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '20.99',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 5,
    image: '11932302_58_main.jpg',
    name: 'BOLSO BANDOLERA CUERDA SOFT',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 6,
    image: '11832313_16_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA BICOLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '19,99',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 7,
    image: '11832312_90_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA MULTICOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 8,
    image: '11832303_58_main.jpg',
    name: 'BOLSO BANDOLERA TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 9,
    image: '11832301_16_main.jpg',
    name: 'BOLSO TOTE COMBINACIÓN RAFIA',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 10,
    image: '11832310_58_main_1.jpg',
    name: 'BOLSO SHOPPER RAFIA',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 11,
    image: '11832308_90_main.jpg',
    name: 'BOLSO TOTE RAFIA MULTICOLOR',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 12,
    image: '13132322_03_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA ASA TRENZADA',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/0.html',
    indice: '0',
  },
  {
    id: 13,
    image: '12432332_02_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    id: 14,
    image: '12432330_16_main.jpg',
    name: 'BOLSO SHOPPER BÁSICO PERFORADO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    id: 15,
    image: '12432302_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    id: 16,
    image: '11932308_16_main.jpg',
    name: 'BOLSO TOTE CANVAS LLAVERO CUERDA',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    id: 17,
    image: '11932302_58_main.jpg',
    name: 'BOLSO BANDOLERA CUERDA SOFT',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '11832313_16_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA BICOLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '11832312_90_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA MULTICOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '11832303_58_main.jpg',
    name: 'BOLSO BANDOLERA TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '11832301_16_main.jpg',
    name: 'BOLSO TOTE COMBINACIÓN RAFIA',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '11832310_58_main_1.jpg',
    name: 'BOLSO SHOPPER RAFIA',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '11832308_90_main.jpg',
    name: 'BOLSO TOTE RAFIA MULTICOLOR',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '13132322_03_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA ASA TRENZADA',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/1.html',
    indice: '1',
  },
  {
    image: '13132321_03_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA DETALLES CUERO',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    image: '13132301_90_main.jpg',
    name: 'BOLSO CAPAZO RAFIA MULTICOLOR',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    image: '10492206_03_main.jpg',
    name: 'BOLSO SHOPPER RAFIA DETALLE',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    id: 27,
    image: '10492205_03_main.jpg',
    name: 'MOCHILA RAFIA SOLAPA CONTRASTE',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    id: 28,
    image: '10492204_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA CONTRASTE DETALLE',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    id: 29,
    image: '11732305_29_main.jpg',
    name: 'BOLSO TOTE ASAS CUERDA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    id: 30,
    image: '11732308_90_main.jpg',
    name: 'BOLSO BANDOLERA COMBINACIÓN RAFIAS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    id: 31,
    image: '14032310_29_pt00.jpg',
    name: 'MOCHILA NYLON TRAVELLER PORTÁTIL 15,6"',
    amount: '34,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    id: 32,
    image: '14032308_90_pt00.jpg',
    name: 'MOCHILA NYLON TRAVELLER EXPANDIBLE PORTÁTIL 13,3"',
    amount: '36,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    image: '14032307_01_pt00.jpg',
    name: 'MOCHILA NYLON TRAVELLER EXPANDIBLE PORTÁTIL 13,3"',
    amount: '36,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    image: '14032306_79_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON IDENTIFICADOR',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    image: '14032303_25_pt00.jpg',
    name: 'MOCHILA NYLON PORTATIL 15,6"',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/2.html',
    indice: '2',
  },
  {
    image: '14032322_20_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON LLAVERO',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '14032317_01_pt00.jpg',
    name: 'BOLSO PORTADOCUMENTOS NYLON BOLSILLO ACOLCHADO',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '14032314_53_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON DOBLE ASA',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '14032311_01_pt00.jpg',
    name: 'PORTADOCUMENTOS NYLON 15,6"',
    amount: '39,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '11632312_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '10392210_01_pt00.jpg',
    name: 'MOCHILA HOBO NYLON MONEDERO',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '10392211_16_pt00.jpg',
    name: 'MOCHILA NYLON MONEDERO',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '15000018_10_pt00_1.jpg',
    name: 'MOCHILA TEXTURA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '15000007_29_pt00.jpg',
    name: 'BOLSO BANDOLERA DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '11432306_58_pt00.jpg',
    name: 'MOCHILA SOLAPA BOLSILLOS',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '11432305_01_pt00.jpg',
    name: 'BOLSO DE HOMBRO CANVAS DETALLE',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '11532310_90_pt00.jpg',
    name: 'MOCHILA COLORBLOCK',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/3.html',
    indice: '3',
  },
  {
    image: '11332306_05_main.jpg',
    name: 'MOCHILA NYLON BOLSILLOS TRAVEL',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232320_03_main.jpg',
    name: 'BOLSO SHOPPER RAFIA SOFT',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232316_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA FLORES',
    amount: '21,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232314_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA BORLAS',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232303_25_main.jpg',
    name: 'BOLSO SOBRE RAFIA COLOR',
    amount: '14,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '_13232307_03_main.jpg',
    name: 'BOLSO BANDOLERA PEQUEÑA RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232311_03_main.jpg',
    name: 'BOLSO TOTE RAFIA ABALORIO',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13332303_25_main.jpg',
    name: 'BOLSO SOBRE RAFIA COLOR',
    amount: '16,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232317_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA ABALORIO',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232319_55_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232315_03_main.jpg',
    name: 'BOLSO TOTE RAFIA BORLAS',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232321_03_main.jpg',
    name: 'BOLSO CAPAZO RAFIA ABALORIO',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/4.html',
    indice: '4',
  },
  {
    image: '13232308_90_main.jpg',
    name: 'BOLSO CAPAZO RAFIA BICOLOR',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '13232312_90_main.jpg',
    name: 'BOLSO RAFIA MULTICOLOR',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '13332304_26_main.jpg',
    name: 'BOLSO SOBRE RAFIA COLOR',
    amount: '16,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '13232318_03_main_1.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '13232322_90_main.jpg',
    name: 'BOLSO CAPAZO DETALLES MULTICOLOR',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '13232301_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA FLECOS',
    amount: '14,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '_13232305_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA ASA CADENA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '13232309_03_main.jpg',
    name: 'BOLSO CAPAZO RAFIA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '12432327_02_main.jpg',
    name: 'MOCHILA BÁSICA PERFORADA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '12432303_32_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '12432307_02_main.jpg',
    name: 'BOLSO TOTE BÁSICO COLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '12432333_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/5.html',
    indice: '5',
  },
  {
    image: '13132302_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA SOLAPA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13332302_79_main.jpg',
    name: 'BOLSO SOBRE RAFIA COLOR',
    amount: '16,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13232310_03_main.jpg',
    name: 'BOLSO BANDOLERA SOLAPA RAFIA ABALORIO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13232304_79_main.jpg',
    name: 'BOLSO SOBRE RAFIA COLOR',
    amount: '14,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13232313_90_main.jpg',
    name: 'BOLSO SOBRE BORDADO MULTICOLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13232302_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA',
    amount: '14,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13232306_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA REDONDA ABALORIO',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '11932314_58_main.jpg',
    name: 'BOLSO BANDOLERA ABALORIO CUERDA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '10492212_90_main.jpg',
    name: 'MOCHILA CANVAS JUNGLE',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '11932303_79_main.jpg',
    name: 'BOLSO BANDOLERA CUERDA SOFT',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '11932307_16_main.jpg',
    name: 'MOCHILA TEXTURAS',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13332301_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA',
    amount: '16,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/6.html',
    indice: '6',
  },
  {
    image: '13132303_03_main.jpg',
    name: 'BOLSO TOTE MINI RAFIA',
    amount: '17,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '13132311_03_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '13132307_03_pt01.jpg',
    name: 'BOLSO SHOPPER RAFIA CAPAZO',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '10492208_16_main.jpg',
    name: 'BOLSO SHOPPER BASIC',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '11732319_90_main.jpg',
    name: 'BOLSO SOBRE PATCHWORK NATURAL',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '11732303_29_main.jpg',
    name: 'BOLSO BANDOLERA DETALLE CUERDA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '11832311_58_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA BORDADOS',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '11832316_55_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA COLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '12432306_16_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '12432329_02_main.jpg',
    name: 'BOLSO SHOPPER BÁSICO PERFORADO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '12432339_58_main.jpg',
    name: 'BOLSO BANDOLERA ACOLCHADA SOFT',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '11932316_16_main.jpg',
    name: 'BOLSO TOTE BÁSICO RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/7.html',
    indice: '7',
  },
  {
    image: '11932317_16_main.jpg',
    name: 'BOLSO SHOOPER BÁSICO RAFIA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '12432304_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '12432310_79_main.jpg',
    name: 'BOLSO TOTE BÁSICO COLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '12432337_02_main.jpg',
    name: 'BOLSO BANDOLERA ACOLCHADA SOFT',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '12432331_32_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '11932306_16_main.jpg',
    name: 'BOLSO BANDOLERA TEXTURAS',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '11832304_16_main.jpg',
    name: 'BOLSO BANDOLERA TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '11932310_90_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA ABALORIO',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '12432326_16_main.jpg',
    name: 'BOLSO BANDOLERA PERFORADA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '12432336_01_main.jpg',
    name: 'BOLSO BANDOLERA SPORT BOLSILLO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '11932315_16_main.jpg',
    name: 'BOLSO SOBRE PATCHWORK TEXTURAS',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '11832305_58_main.jpg',
    name: 'BOLSO TOTE TEXTURA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/8.html',
    indice: '8',
  },
  {
    image: '11832317_41_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA COLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11932311_90_main.jpg',
    name: 'BOLSO TOTE RAFIA ASAS MADERA',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11832318_90_main.jpg',
    name: 'BOLSO SOBRE RAFIA MULTICOLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11932304_16_main.jpg',
    name: 'BOLSO DE HOMBRO ASA TRENZADA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11932318_90_main_1.jpg',
    name: 'MOCHILA ESTAMPADO FLORES',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11832306_16_main.jpg',
    name: 'BOLSO TOTE TEXTURA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11832309_90_main_1.jpg',
    name: 'MOCHILA RAFIA MULTICOLOR',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11932309_16_main.jpg',
    name: 'MOCHILA CANVAS LLAVERO CUERDA',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11932301_16_main.jpg',
    name: 'BOLSO BANDOLERA CUERDA SOFT',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11832314_16_main.jpg',
    name: 'BOLSO TOTE RAFIA BICOLOR',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11932313_16_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11832319_90_main.jpg',
    name: 'BOLSO SOBRE RAFIA SOLAPA MULTICOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/9.html',
    indice: '9',
  },
  {
    image: '11832302_16_main.jpg',
    name: 'BOLSO HOBO COMBINACIÓN RAFIA',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '11932312_90_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA SOLAPA',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '11932305_58_main.jpg',
    name: 'BOLSO DE HOMBRO ASA TRENZADA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '11732311_90_main_1.jpg',
    name: 'BOLSO TOTE PATCHWORK NATURAL',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '11832315_16_main.jpg',
    name: 'BOLSO BANDOLERA COMBINACIÓN RAFIA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '11832307_58_main.jpg',
    name: 'BOLSO SHOPPER TEXTURA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '11732304_60_main.jpg',
    name: 'BOLSO TOTE ASAS CUERDA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '13132306_03_pt02.jpg',
    name: 'BOLSO BANDOLERA RAFIA CESTA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '12432334_02_main.jpg',
    name: 'BOLSO BANDOLERA SPORT BOLSILLO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '12432328_16_main.jpg',
    name: 'MOCHILA BÁSICA PERFORADA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '12432338_01_main.jpg',
    name: 'BOLSO BANDOLERA ACOLCHADA SOFT',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '13132320_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA DETALLE',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/10.html',
    indice: '10',
  },
  {
    image: '11732315_08_main.jpg',
    name: 'BOLSO TOTE MINI RAFIA COLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '12432301_02_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO ACOLCHADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '14032320_01_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON LLAVERO',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '14032309_01_pt00.jpg',
    name: 'MOCHILA NYLON TRAVELLER PORTÁTIL 15,6"',
    amount: '34,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '14032301_01_pt00.jpg',
    name: 'MOCHILA NYLON PORTATIL 15,6"',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '14032305_58_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON IDENTIFICADOR',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '12432305_29_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '12432325_02_main.jpg',
    name: 'BOLSO BANDOLERA PERFORADA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '12432335_58_main.jpg',
    name: 'BOLSO BANDOLERA SPORT BOLSILLO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '11732318_29_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE COLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '13132312_20_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE RAFIA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '13132318_90_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA SOLAPA COLORES',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/11.html',
    indice: '11',
  },
  {
    image: '13132319_90_main.jpg',
    name: 'BOLSO SHOPPER CAPAZO RAFIA COLORES',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '10492201_90_main.jpg',
    name: 'BOLSO SHOPPER RAFIA CONTRASTE',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132317_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA COLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132315_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA BORLAS',
    amount: '15,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132308_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA CIERRE CUERO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132314_25_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA CADENA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '10492211_90_main_1.jpg',
    name: 'BOLSO BANDOLERA CANVAS JUNGLE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132325_90_main.jpg',
    name: 'BOLSO BANDOLERA SOBRE RAFIA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132323_03_main.jpg',
    name: 'BOLSO TOTE RAFIA SOFT',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132304_03_main.jpg',
    name: 'BOLSO TOTE RAFIA BORLA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132324_03_main.jpg',
    name: 'BOLSO SHOPPER RAFIA CAPAZO',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132316_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA BRILLO',
    amount: '14,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/12.html',
    indice: '12',
  },
  {
    image: '13132309_03_pt01.jpg',
    name: 'BOLSO BANDOLERA RAFIA REDONDO',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '13132313_03_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA CADENA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '11732312_60_main.jpg',
    name: 'BOLSO DE HOMBRO CANVAS Y RAFIA',
    amount: '29,90 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '14032313_01_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON DOBLE ASA',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '10392212_01_pt00.jpg',
    name: 'MOCHILA NYLON MONEDERO',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '14032318_01_pt00.jpg',
    name: 'MOCHILA NYLON PORTÁTIL 15,6"',
    amount: '34,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '11732313_16_main.jpg',
    name: 'MOCHILA CANVAS Y RAFIA',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '10492213_03_main.jpg',
    name: 'BOLSO CLUTCH RAFIA BOQUILLA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '10492216_25_main.jpg',
    name: 'BOLSO BANDOLERA COLOR CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '13132310_03_main.jpg',
    name: 'BOLSO TOTE RAFIA REDONDO',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '11732310_90_main.jpg',
    name: 'BOLSO BANDOLERA PATCHWORK NATURAL',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '11732314_60_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA ASA CADENAS',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/13.html',
    indice: '13',
  },
  {
    image: '11732302_60_main.jpg',
    name: 'BOLSO BANDOLERA DETALLE CUERDA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '11732307_29_main.jpg',
    name: 'BOLSO DE HOMBRO ASA CUERDA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '10492210_90_main.jpg',
    name: 'MOCHILA ESTAMPADO JUNGLE',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '10492202_90_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA CONTRASTE',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '10492214_79_main.jpg',
    name: 'BOLSO CLUTCH RAFIA BOQUILLA COLOR',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '13132305_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '30032316_90_main.jpg',
    name: 'BOLSO SOBRE RAFIA MULTICOLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '30032321_55_main.jpg',
    name: 'BOLSO CLUTCH RAFIA COLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '30032325_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '30032335_03_main.jpg',
    name: 'BOLSO CLUTCH RAFIA NATURAL',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '10492207_16_main.jpg',
    name: 'BOLSO TOTE BASIC',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '10492215_79_main.jpg',
    name: 'BOLSO BANDOLERA COLOR CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/14.html',
    indice: '14',
  },
  {
    image: '15000017_01_pt00.jpg',
    name: 'MOCHILA TEXTURA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '14032321_25_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON LLAVERO',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '14032312_16_pt00.jpg',
    name: 'PORTADOCUMENTOS NYLON 15,6"',
    amount: '39,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '11732309_90_main.jpg',
    name: 'MOCHILA COMBINACIÓN RAFIAS',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '10492203_03_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA SOLAPA CONTRASTE',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '11732317_08_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE COLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '14032316_16_pt00.jpg',
    name: 'MOCHILA NYLON PORTATIL 13,3"',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '24032320_58_pt00.jpg',
    name: 'RIÑONERA NYLON',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '11732301_16_main.jpg',
    name: 'BOLSO BANDOLERA DETALLE CUERDA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '30032317_90_main.jpg',
    name: 'BOLSO CLUTCH RAFIA MULTICOLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '30032329_96_main.jpg',
    name: 'BOLSO SOBRE CHAROL APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '30032340_03_main.jpg',
    name: 'BOLSO CLUTCH RAFIA APLIQUE MADERA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/15.html',
    indice: '15',
  },
  {
    image: '30032313_96_main.jpg',
    name: 'BOLSO CLUTCH CHAROL',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '11732316_60_main.jpg',
    name: 'BOLSO TOTE MINI RAFIA COLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '10492217_29_main.jpg',
    name: 'BOLSO BANDOLERA COLOR CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '11732306_16_main.jpg',
    name: 'BOLSO DE HOMBRO ASA CUERDA',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '10492209_90_main.jpg',
    name: 'BOLSO BANDOLERA ESTAMPADO JUNGLE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '14032304_01_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON IDENTIFICADOR',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '10392207_16_pt00.jpg',
    name: 'BOLSO BANDOLERA NYLON MONEDERO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '10392203_32_pt00.jpg',
    name: 'BOLSO BANDOLERA NYLON SPORT BOLSILLOS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '11332307_16_main.jpg',
    name: 'MOCHILA NYLON BOLSILLOS TRAVEL',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '10392201_16_pt00.jpg',
    name: 'BOLSO BANDOLERA NYLON SPORT BOLSILLOS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '24032321_01_pt00.jpg',
    name: 'RIÑONERA NYLON TRAVELLER',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '14032302_26_pt00.jpg',
    name: 'MOCHILA NYLON PORTATIL 15,6"',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/16.html',
    indice: '16',
  },
  {
    image: '10392216_01_pt00.jpg',
    name: 'BOLSO TOTE NYLON LLAVERO',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '10392214_01_pt00.jpg',
    name: 'BOLSO SHOPPER NYLON LLAVERO',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '11632308_01_main.jpg',
    name: 'BOLSO TOTE CANVAS BLACK',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '11632306_01_main.jpg',
    name: 'MOCHILA CANVAS SOLAPA BLACK',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '11632313_01_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA ASA CADENAS',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '11632318_01_main.jpg',
    name: 'BOLSO SOBRE RAFIA DETALLE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '10392205_08_pt00.jpg',
    name: 'MOCHILA NYLON BOLSILLOS',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '10392202_01_pt00.jpg',
    name: 'BOLSO BANDOLERA NYLON SPORT BOLSILLOS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '10392213_16_pt00.jpg',
    name: 'BOLSO SHOPPER NYLON LLAVERO',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '14032319_25_pt00.jpg',
    name: 'MOCHILA NYLON PORTATIL 15,6"',
    amount: '34,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '10392209_16_pt00.jpg',
    name: 'MOCHILA HOBO NYLON MONEDERO',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '14032315_01_pt00.jpg',
    name: 'MOCHILA NYLON PORTATIL 13,3"',
    amount: '32,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/17.html',
    indice: '17',
  },
  {
    image: '30032322_25_main.jpg',
    name: 'BOLSO CLUTCH RAFIA COLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '30032326_03_main.jpg',
    name: 'BOLSO CLUTCH RAFIA CIERRE APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '30032336_03_main.jpg',
    name: 'BOLSO CLUTCH RAFIA JOYAS',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '30032357_90_main.jpg',
    name: 'BOLSO CLUTCH RAFIA MULTICOLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '11632317_20_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE MONOCOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '11632309_01_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA Y TEJIDO BLACK',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '15000009_05_pt00.jpg',
    name: 'BOLSO BANDOLERA DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '11632316_01_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE MONOCOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '11632315_20_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA APLIQUE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '10392204_08_pt00.jpg',
    name: 'BOLSO BANDOLERA NYLON COLOR SPORT',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '10392206_01_pt00.jpg',
    name: 'MOCHILA NYLON PORTA PC 13,3"',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '10392208_01_pt00.jpg',
    name: 'BOLSO BANDOLERA NYLON MONEDERO',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/18.html',
    indice: '18',
  },
  {
    image: '10392215_16_pt00.jpg',
    name: 'BOLSO TOTE NYLON LLAVERO',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11632310_01_main.jpg',
    name: 'MOCHILA RAFIA Y TEJIDO BLACK',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11632311_01_main.jpg',
    name: 'BOLSO TOTE RAFIA Y TEJIDO BLACK',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11432318_25_pt00.jpg',
    name: 'BOLSO DE HOMBRO ASA CADENAS',
    amount: '21,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11432316_71_pt00.jpg',
    name: 'BOLSO DE HOMBRO ASA CADENAS',
    amount: '21,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '30032330_03_main.jpg',
    name: 'BOLSITO RAFIA ASAS MARMOL',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '30032323_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA SOLAPA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '30032319_65_main.jpg',
    name: 'BOLSO SOBRE CLUTCH RAFIA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '21532304_90_pt00.jpg',
    name: 'ESTUCHE SOBRE RAFIA Y TEJIDO MULTICOLOR',
    amount: '15,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11632302_58_main.jpg',
    name: 'BOLSO BANDOLERA DOBLE COMPARTIMENTO',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11532314_58_pt00.jpg',
    name: 'BOLSO BANDOLERA FLECOS ASA CADENA',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11532315_53_pt00.jpg',
    name: 'BOLSO BANDOLERA FLECOS ASA CADENA',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/19.html',
    indice: '19',
  },
  {
    image: '11532302_16_pt00.jpg',
    name: 'BOLSO TOTE RAFIA CAMEL',
    amount: '28,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11532301_16_pt00.jpg',
    name: 'BOLSO BANDOLERA RAFIA CAMEL',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11532316_58_pt00.jpg',
    name: 'BOLSO TOTE CANVAS',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '15000002_50_pt00.jpg',
    name: 'BOLSO TOTE DETALLES CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '15000011_10_pt00_1.jpg',
    name: 'BOLSO SHOPPER BÁSICO TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11432312_01_pt00.jpg',
    name: 'BOLSO BANDOLERA BOLSILLOS',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11532303_90_pt00.jpg',
    name: 'MOCHILA RAFIA Y TEJIDO MULTICOLOR',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11532307_58_pt00.jpg',
    name: 'BOLSO TOTE BICOLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11432309_90_pt00.jpg',
    name: 'BOLSO TOTE EFECTO RAFIA SPORT',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11432313_01_pt00.jpg',
    name: 'BOLSO BANDOLERA MONOCOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '11432317_79_pt00.jpg',
    name: 'BOLSO DE HOMBRO ASA CADENAS',
    amount: '21,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '15000015_29_pt00_1.jpg',
    name: 'BOLSO BANDOLERA TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/20.html',
    indice: '20',
  },
  {
    image: '15000019_10_pt00_1.jpg',
    name: 'BOLSO BANDOLERA TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11232315_01_main.jpg',
    name: 'MOCHILA BÁSICA MONOCOLOR',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11432302_90_pt00.jpg',
    name: 'BOLSO BANDOLERA EFECTO RAFIA DETALLE',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11432310_01_pt00.jpg',
    name: 'BOLSO BANDOLERA CANVAS DETALLE',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11432314_01_pt00.jpg',
    name: 'BOLSO TOTE MONOCOLOR',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '15000004_01_pt00.jpg',
    name: 'BOLSO TOTE DETALLES CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '15000012_01_pt00.jpg',
    name: 'BOLSO SHOPPER BÁSICO TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11632301_01_main.jpg',
    name: 'BOLSO BANDOLERA DOBLE COMPARTIMENTO',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '12432217_01_pt00.jpg',
    name: 'BOLSO BANDOLERA BÁSICO SOLAPA CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11532309_90_pt00.jpg',
    name: 'BOLSO BANDOLERA COLORBLOCK',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11432303_90_pt00.jpg',
    name: 'MOCHILA EFECTO RAFIA DETALLE',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '11432315_01_pt00.jpg',
    name: 'BOLSO DE HOMBRO ASA CADENAS',
    amount: '21,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/21.html',
    indice: '21',
  },
  {
    image: '15000001_29_pt00.jpg',
    name: 'BOLSO TOTE DETALLES CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '15000005_29_pt00.jpg',
    name: 'BOLSO SHOPPER DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '11632314_01_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA APLIQUE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '11532311_58_pt00.jpg',
    name: 'BOLSO BANDOLERA BICOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '11532308_61_pt00.jpg',
    name: 'BOLSO TOTE BICOLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '11532312_61_pt00.jpg',
    name: 'BOLSO BANDOLERA BICOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '11532305_90_pt00.jpg',
    name: 'BOLSO DE HOMBRO RAFIA Y TEJIDO MULTICOLOR',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '11532304_90_pt00.jpg',
    name: 'BOLSO TOTE RAFIA Y TEJIDO MULTICOLOR',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '12332329_29_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '12332326_16_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '12332327_25_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '12332322_29_main.jpg',
    name: 'BOLSO BANDOLERA CREMALLERAS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/22.html',
    indice: '22',
  },
  {
    image: '11432311_58_pt00.jpg',
    name: 'BOLSO BANDOLERA BOLSILLOS',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '12332309_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA CREMALLERAS',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '11432307_01_pt00.jpg',
    name: 'MOCHILA SOLAPA BOLSILLOS',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '12332324_01_main.jpg',
    name: 'BOLSO BANDOLERA CREMALLERAS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '30032333_90_main.jpg',
    name: 'BOLSO CLUTCH RAFIA MULTICOLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '30032337_03_main.jpg',
    name: 'BOLSO DE MANO RAFIA ASA CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '30032327_03_main.jpg',
    name: 'BOLSO RAFIA ASA MARMOL',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '30032331_03_main.jpg',
    name: 'BOLSO SOBRE RAFIA APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '12332315_29_main.jpg',
    name: 'BOLSO BANDOLERA DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '12332323_16_main.jpg',
    name: 'BOLSO BANDOLERA CREMALLERAS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '11232307_25_main.jpg',
    name: 'BOLSO BANDOLERA NYLON SPORT SOFT',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '12332313_58_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO DEPARTAMENTOS',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/23.html',
    indice: '23',
  },
  {
    image: '12332318_79_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA DOS BOLSILOS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332334_01_main.jpg',
    name: 'MOCHILA BÁSICA BOLSILLOS',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332302_16_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO NYLON ESPECIAL',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332319_29_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA DOS BOLSILOS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332331_41_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332307_16_main.jpg',
    name: 'MOCHILA BÁSICA NYLON ESPECIAL',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332316_68_main.jpg',
    name: 'BOLSO BANDOLERA DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332321_16_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA DOS BOLSILOS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332328_01_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332304_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO NYLON ESPECIAL',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332308_25_main.jpg',
    name: 'MOCHILA BÁSICA NYLON ESPECIAL',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332312_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO DEPARTAMENTOS',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/24.html',
    indice: '24',
  },
  {
    image: '12332317_05_main.jpg',
    name: 'BOLSO BANDOLERA DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '12332320_05_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA DOS BOLSILOS',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '12332325_02_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '12332333_29_main.jpg',
    name: 'MOCHILA BÁSICA BOLSILLOS',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '12332332_01_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '11332318_55_main.jpg',
    name: 'BOLSO BANDOLERA TEXTIL ACOLCHADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '11332317_05_main.jpg',
    name: 'BOLSO BANDOLERA EFECTO DENIM',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '11332301_05_main.jpg',
    name: 'BOLSO BANDOLERA NYLON TRAVEL',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '11532317_90_pt00.jpg',
    name: 'BOLSO BANDOLERA RAFIA Y TEJIDO MULTICOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '12332330_16_main.jpg',
    name: 'BOLSO BANDOLERA BOLSILLO FRONTAL SOLAPA',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '11432304_01_pt00.jpg',
    name: 'BOLSO BANDOLERA SOLAPA CANVAS',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '11432308_90_pt00.jpg',
    name: 'BOLSO BANDOLERA EFECTO RAFIA SPORT',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/25.html',
    indice: '25',
  },
  {
    image: '15000003_05_pt00.jpg',
    name: 'BOLSO TOTE DETALLES CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '11532313_16_pt00.jpg',
    name: 'BOLSO BANDOLERA MONOCOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '11532306_16_pt00.jpg',
    name: 'BOLSO DE HOMBRO MONOCOLOR',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '15000016_01_pt00.jpg',
    name: 'BOLSO BANDOLERA TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '15000013_29_pt00_1.jpg',
    name: 'BOLSO TOTE BÁSICO TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '15000014_01_pt00.jpg',
    name: 'BOLSO TOTE BÁSICO TEXTURA',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '11632305_01_main.jpg',
    name: 'BOLSO DE HOMBRO RAFIA DETALLES BLACK',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '11632303_01_main.jpg',
    name: 'BOLSO BANDOLERA RAFIA SOLAPA BLACK',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '11632304_01_main.jpg',
    name: 'BOLSO TOTE RAFIA DETALLES BLACK',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '11632307_01_main.jpg',
    name: 'BOLSO BANDOLERA CANVAS BLACK',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '15000010_01_pt00.jpg',
    name: 'BOLSO BANDOLERA DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '15000008_50_pt00.jpg',
    name: 'BOLSO BANDOLERA DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/26.html',
    indice: '26',
  },
  {
    image: '15000006_05_pt00.jpg',
    name: 'BOLSO SHOPPER DETALLES CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '21232305_60_main.jpg',
    name: 'PORTA MÓVIL NYLON SOFT 6,7"',
    amount: '15,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '11332304_16_main.jpg',
    name: 'BOLSO BANDOLERA NYLON BOLSILLOS',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '12332310_16_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA CREMALLERAS',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '11232312_01_main.jpg',
    name: 'BOLSO TOTE PEQUEÑO MONOCOLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '12332311_58_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA CREMALLERAS',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '12432219_68_pt00.jpg',
    name: 'BOLSO DE HOMBRO BÁSICO ASA CONTRASTE',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '11332310_90_main.jpg',
    name: 'MOCHILA COLORBLOCK',
    amount: '29,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '21332306_90_main.jpg',
    name: 'PORTA MÓVIL COLORBLOCK 6,2"',
    amount: '16,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '12432224_05_pt00.jpg',
    name: 'BOLSO TOTE BÁSICO ASA CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '11332305_16_main.jpg',
    name: 'BOLSO BANDOLERA NYLON SOLAPA',
    amount: '27,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '30032301_96_main.jpg',
    name: 'BOLSO CLUTCH NUDE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/27.html',
    indice: '27',
  },
  {
    image: '30032305_50_main.jpg',
    name: 'BOLSO SOBRE SOLAPA BRILLO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '30032309_65_main.jpg',
    name: 'BOLSO CLUTCH RED METALIZADO',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '11232301_01_main.jpg',
    name: 'BOLSO BANDOLERA NYLON TRAVELLER',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '11332303_05_main.jpg',
    name: 'BOLSO BANDOLERA NYLON BOLSILLOS',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '12432221_01_pt00.jpg',
    name: 'BOLSO DE HOMBRO BÁSICO ASA CONTRASTE',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '30032302_65_main.jpg',
    name: 'BOLSO CLUTCH METALIZADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '11232302_25_main.jpg',
    name: 'BOLSO BANDOLERA NYLON TRAVELLER',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '11332315_01_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE TEXTURA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '30032303_96_main.jpg',
    name: 'BOLSO CLUTCH ABALORIOS MULTICOLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '30032307_50_main.jpg',
    name: 'BOLSO SOBRE CLUTCH BRILLO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '30032315_90_main.jpg',
    name: 'BOLSO CLUTCH RAFIA MULTICOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '30032320_65_main.jpg',
    name: 'BOLSO SOBRE CIERRE CONCHA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/28.html',
    indice: '28',
  },
  {
    image: '30032324_03_main.jpg',
    name: 'BOLSO CLUTCH RAFIA APLIQUES',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '30032334_90_main.jpg',
    name: 'BOLSO CLUTCH RAFIA COLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '11332312_90_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO COLORBLOCK',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '12432223_68_pt00.jpg',
    name: 'BOLSO TOTE BÁSICO ASA CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '30032308_96_main.jpg',
    name: 'BOLSO CLUTCH RED METALIZADO',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '30032312_50_main.jpg',
    name: 'BOLSO CLUTCH BRILLO APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '11232304_16_main.jpg',
    name: 'BOLSO BANDOLERA TRAVELLER BOLSILLOS',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '11332308_90_main.jpg',
    name: 'BOLSO BANDOLERA SOLAPA COLORBLOCK',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '12432220_05_pt00.jpg',
    name: 'BOLSO DE HOMBRO BÁSICO ASA CONTRASTE',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '12432228_01_pt00.jpg',
    name: 'MOCHILA BÁSICA ASAS CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '11232308_16_main.jpg',
    name: 'BOLSO BANDOLERA NYLON SPORT SOFT',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '11232309_01_main.jpg',
    name: 'BOLSO BANDOLERA NYLON SPORT SOFT',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/29.html',
    indice: '29',
  },
  {
    image: '12332303_08_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO NYLON ESPECIAL',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '12432227_68_pt00.jpg',
    name: 'MOCHILA BÁSICA ASAS CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '12432216_68_pt00.jpg',
    name: 'BOLSO BANDOLERA BÁSICO SOLAPA CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '11332302_16_main.jpg',
    name: 'BOLSO BANDOLERA NYLON TRAVEL',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '11232303_01_main.jpg',
    name: 'BOLSO BANDOLERA TRAVELLER BOLSILLOS',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '11232313_60_main.jpg',
    name: 'BOLSO TOTE PEQUEÑO MONOCOLOR',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '11232310_60_main.jpg',
    name: 'BOLSO DE HOMBRO BAGUETTE NYLON MONOCOLOR',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '11332311_90_main.jpg',
    name: 'MOCHILA BOLSILLO FRONTAL',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '12432218_29_pt00.jpg',
    name: 'BOLSO DE HOMBRO BÁSICO ASA CONTRASTE',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '12432222_29_pt00.jpg',
    name: 'BOLSO TOTE BÁSICO ASA CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '11232314_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICA MONOCOLOR',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '11232311_01_main.jpg',
    name: 'BOLSO TOTE BÁSICO SPORT',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/30.html',
    indice: '30',
  },
  {
    image: '12432215_29_pt00.jpg',
    name: 'BOLSO BANDOLERA BÁSICO SOLAPA CONTRASTE',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '12332301_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO NYLON ESPECIAL',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '12332305_16_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO NYLON ESPECIAL',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '12432225_01_pt00.jpg',
    name: 'BOLSO TOTE BÁSICO ASA CONTRASTE',
    amount: '23,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '11332314_01_main.jpg',
    name: 'MOCHILA TEXTURA BOLSILLO FRONTAL',
    amount: '25,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '30032310_96_main.jpg',
    name: 'BOLSO CLUTCH BRILLO APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '30092218_20_pt00.jpg',
    name: 'BOLSO CLUTCH ANTELINA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '30032306_65_main.jpg',
    name: 'BOLSO SOBRE CLUTCH BRILLO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '11332309_90_main.jpg',
    name: 'BOLSO BANDOLERA SOLAPA COLORBLOCK',
    amount: '26,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '11332313_01_main.jpg',
    name: 'BOLSO BANDOLERA BÁSICO TEXTURA',
    amount: '24,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '21232306_25_main.jpg',
    name: 'PORTA MÓVIL NYLON SOFT 6,7"',
    amount: '15,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '30032311_65_main.jpg',
    name: 'BOLSO CLUTCH BRILLO APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/31.html',
    indice: '31',
  },
  {
    image: '30092221_65_pt00.jpg',
    name: 'BOLSO CLUTCH CAJA METAL',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092216_01_pt00.jpg',
    name: 'BOLSO CLUTCH PEDRERIA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092220_41_pt00.jpg',
    name: 'BOLSO CLUTCH ANTELINA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092223_19_pt00.jpg',
    name: 'BOLSO CLUTCH CAJA METAL',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092222_50_pt00.jpg',
    name: 'BOLSO CLUTCH CAJA METAL',
    amount: '22,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092225_65_pt00.jpg',
    name: 'BOLSO CLUTCH MALLA METÁLICA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092233_01_main.jpg',
    name: 'BOLSO BANDOLERA APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092226_50_pt00.jpg',
    name: 'BOLSO CLUTCH MALLA METÁLICA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30032339_20_main.jpg',
    name: 'BOLSO CLUTCH RAFIA COLOR',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30032304_65_main.jpg',
    name: 'BOLSO SOBRE SOLAPA BRILLO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092228_50_pt00.jpg',
    name: 'BOLSO CLUTCH TEXTURAS',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30032328_96_main.jpg',
    name: 'BOLSO CHAROL',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/32.html',
    indice: '32',
  },
  {
    image: '30092232_01_pt00.jpg',
    name: 'BOLSO CLUTCH ASA CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30032332_96_main.jpg',
    name: 'BOLSO SOBRE DESGASTADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092231_90_pt00.jpg',
    name: 'BOLSO CLUTCH LENTEJUELAS',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092230_65_pt00.jpg',
    name: 'BOLSO CLUTCH METALIZADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092240_90_main.jpg',
    name: 'BOLSO SOBRE LENTEJUELAS',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092235_20_main.jpg',
    name: 'BOLSO SOBRE ANTELINA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092234_01_main.jpg',
    name: 'BOLSO SOBRE APLIQUE',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092229_01_pt00.jpg',
    name: 'BOLSO CLUTCH TEXTURAS',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092245_90_main.jpg',
    name: 'BOLSO BANDOLERA COLORS BRILLO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092239_65_main.jpg',
    name: 'BOLSO SOBRE METALIZADO',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30092248_01_main.jpg',
    name: 'BOLSO ASA CADENA',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '30032211_01_main.jpg',
    name: 'BOLSO CLUTCH POUCH GRABADO ANIMAL',
    amount: '19,99 ',
    categoria: 'bolsos',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/bolsos/page/33.html',
    indice: '33',
  },
  {
    image: '40932357_90_main.jpg',
    name: 'LLAVERO CABALLITO MAR',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40532333_58_main.jpg',
    name: 'SOMBRERO BUCKET RAFIA',
    amount: '10,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932349_25_pt02.jpg',
    name: 'PINZA PELO BRILLO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932353_03_main.jpg',
    name: 'DIADEMA RAFIA NUDO',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40532337_20_pt02.jpg',
    name: 'SOMBRERO BUCKET CANVAS CUERDA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40532301_03_main.jpg',
    name: 'CINTURON RAFIA BICOLOR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932338_90_main.jpg',
    name: 'PINZA PELO MULTICOLOR',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932346_90_pt01.jpg',
    name: 'LLAVERO SPECIAL DAY LILA',
    amount: '3,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40532330_03_pt03.jpg',
    name: 'SOMBRERO RAFIA MULTICOLOR',
    amount: '15,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932350_90_main.jpg',
    name: 'SET PINZAS MULTICOLOR',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932352_03_pt02.jpg',
    name: 'DIADEMA RAFIA TRENZADA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932356_90_pt04.jpg',
    name: 'SET COLETEROS',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/0.html',
    indice: '0',
  },
  {
    image: '40932357_90_main.jpg',
    name: 'LLAVERO CABALLITO MAR',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40532333_58_main.jpg',
    name: 'SOMBRERO BUCKET RAFIA',
    amount: '10,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932349_25_pt02.jpg',
    name: 'PINZA PELO BRILLO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932353_03_main.jpg',
    name: 'DIADEMA RAFIA NUDO',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40532337_20_pt02.jpg',
    name: 'SOMBRERO BUCKET CANVAS CUERDA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40532301_03_main.jpg',
    name: 'CINTURON RAFIA BICOLOR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932338_90_main.jpg',
    name: 'PINZA PELO MULTICOLOR',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932346_90_pt01.jpg',
    name: 'LLAVERO SPECIAL DAY LILA',
    amount: '3,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40532330_03_pt03.jpg',
    name: 'SOMBRERO RAFIA MULTICOLOR',
    amount: '15,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932350_90_main.jpg',
    name: 'SET PINZAS MULTICOLOR',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932352_03_pt02.jpg',
    name: 'DIADEMA RAFIA TRENZADA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932356_90_pt04.jpg',
    name: 'SET COLETEROS',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/1.html',
    indice: '1',
  },
  {
    image: '40932347_55_main.jpg',
    name: 'PINZA PELO BRILLO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40532302_03_pt04.jpg',
    name: 'CINTURON RAFIA HEBILLA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40932351_90_pt04.jpg',
    name: 'SET PINZAS MULTICOLOR',
    amount: '3,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40532327_90_main.jpg',
    name: 'SOMBRERO BUCKET RAFIA MULTICOLOR',
    amount: '10,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40932345_90_main.jpg',
    name: 'LLAVERO SPECIAL DAY TURQUESA',
    amount: '3,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40932339_90_pt01.jpg',
    name: 'PINZA PELO MULTICOLOR',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40532338_32_main.jpg',
    name: 'SOMBRERO BUCKET CANVAS CUERDA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40932325_58_main.jpg',
    name: 'DIADEMA RAFIA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40932348_08_pt02.jpg',
    name: 'PINZA PELO BRILLO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40532303_03_pt01.jpg',
    name: 'CINTURON RAFIA LISO',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40932358_90_main.jpg',
    name: 'LLAVERO ESTRELLA MAR',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40832301_90_pt01.jpg',
    name: 'PAÑUELO ESTAMPADO',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/2.html',
    indice: '2',
  },
  {
    image: '40932312_79_main.jpg',
    name: 'PINZA PELO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40932343_90_pt01.jpg',
    name: 'CUELGA GAFAS CADENA',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40532314_03_main.jpg',
    name: 'SOMBRERO RAFIA ABALORIOS',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40832306_90_pt01.jpg',
    name: 'PAÑUELO ESTAMPADO PAISLEY MULTICOLOR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40932316_90_main.jpg',
    name: 'PINZA PELO',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40532304_03_main.jpg',
    name: 'CINTURON RAFIA HEBILLA NÁCAR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40932336_25_main.jpg',
    name: 'PINZA PELO COLOR MATE',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40532319_03_main.jpg',
    name: 'SOMBRERO BUCKET RAFIA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40932323_90_main.jpg',
    name: 'COLETERO NACAR',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40832309_90_main.jpg',
    name: 'PAÑUELO ESTAMPADO HOJAS',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40932359_90_main.jpg',
    name: 'LLAVERO FLOR',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40932330_58_main.jpg',
    name: 'DIADEMA TELA',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/3.html',
    indice: '3',
  },
  {
    image: '40532307_08_main.jpg',
    name: 'GORRA BASIC',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40232304_90_main.jpg',
    name: 'SET COLLARES MEDALLA',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40832302_90_pt04.jpg',
    name: 'PAÑUELO ESTAMPADO PAISLEY',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932307_01_main_r.jpg',
    name: 'PINZA PELO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932318_58_main.jpg',
    name: 'DIADEMA NUDO',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932324_90_main.jpg',
    name: 'COLETERO NACAR',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932313_59_pt01.jpg',
    name: 'PINZA PELO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40532305_58_main.jpg',
    name: 'BUCKET CUERDA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40532308_04_pt01.jpg',
    name: 'GORRA BASIC',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932327_58_main.jpg',
    name: 'DIADEMA CANVAS',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932355_90_main.jpg',
    name: 'LLAVERO BORLA RAFIA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932326_90_main.jpg',
    name: 'DIADEMA RAFIA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/4.html',
    indice: '4',
  },
  {
    image: '40932333_01_main.jpg',
    name: 'PINZA PELO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40932360_90_main.jpg',
    name: 'LLAVERO HOJA TROPICAL',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40532316_03_main.jpg',
    name: 'GORRA RAFIA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40932337_79_main.jpg',
    name: 'PINZA PELO COLOR MATE',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40232301_90_main.jpg',
    name: 'SET COLLARES ESTRELLA',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40532315_03_pt01.jpg',
    name: 'SOMBRERO RAFIA ABALORIOS',
    amount: '15,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40832304_90_main.jpg',
    name: 'PAÑUELO ESTAMPADO TIE-DYE',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40532328_03_main.jpg',
    name: 'SOMBRERO RAFIA PAÑUELO',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40932310_68_main.jpg',
    name: 'PINZA PELO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40832307_90_main.jpg',
    name: 'PAÑUELO ESTAMPADO FLORES',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40532306_04_main.jpg',
    name: 'BUCKET CUERDA',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40932314_08_main.jpg',
    name: 'PINZA PELO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/5.html',
    indice: '5',
  },
  {
    image: '40932319_25_main.jpg',
    name: 'DIADEMA TRENZADA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40932308_50_main.jpg',
    name: 'SET PINZAS',
    amount: '3,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40232305_90_main.jpg',
    name: 'SET COLLARES ABALORIOS',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40532309_58_main.jpg',
    name: 'GORRA BASIC',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40932344_90_main.jpg',
    name: 'LLAVERO COLORS',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40932328_58_main.jpg',
    name: 'DIADEMA RAFIA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40932311_04_main.jpg',
    name: 'PINZA PELO',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40832305_90_main.jpg',
    name: 'PAÑUELO ESTAMPADO TIE-DYE ROSE',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40932341_90_pt04.jpg',
    name: 'CUELGA GAFAS CONCHAS',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40532317_01_pt02.jpg',
    name: 'SOMBRERO BUCKET RAFIA AJUSTABLE',
    amount: '10,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40232302_90_main.jpg',
    name: 'SET COLLARES MAR',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40532320_03_main.jpg',
    name: 'SOMBRERO RAFIA BORLAS',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/6.html',
    indice: '6',
  },
  {
    image: '40932361_90_main.jpg',
    name: 'LLAVERO MULTICOLOR',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932334_08_pt01.jpg',
    name: 'PINZA PELO COLOR',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932315_90_main.jpg',
    name: 'PINZA PELO',
    amount: '2,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932321_04_main.jpg',
    name: 'DIADEMA NUDO',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40832308_90_main.jpg',
    name: 'PAÑUELO ESTAMPADO FLORES',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932309_65_main.jpg',
    name: 'SET PINZAS',
    amount: '3,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40532310_25_main.jpg',
    name: 'GORRA BASIC',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932354_90_main.jpg',
    name: 'LLAVERO BORLA RAFIA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932329_04_main.jpg',
    name: 'DIADEMA TELA',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932335_60_main.jpg',
    name: 'PINZA PELO COLOR',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40932342_90_pt03.jpg',
    name: 'CUELGA GAFAS CONCHAS MULTICOLOR',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40532318_58_main.jpg',
    name: 'SOMBRERO BUCKET RAFIA AJUSTABLE',
    amount: '10,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/7.html',
    indice: '7',
  },
  {
    image: '40232303_90_main.jpg',
    name: 'SET COLLARES CONCHA',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '20492204_90_main.jpg',
    name: 'ESTUCHE CANVAS PRINT JUNGLE',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '40932305_90_main.jpg',
    name: 'LLAVERO CORAZÓN',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '24032329_53_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR DOBLE COMPARTIMENTO',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '40532313_03_pt03.jpg',
    name: 'SOMBRERO RAFIA ABALORIOS',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '40232202_90_main.jpg',
    name: 'SET COLLARES SUMMER',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '24032326_25_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '40592218_25_pt01.jpg',
    name: 'GORRA NYLON',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '40932213_01_main.jpg',
    name: 'CORDÓN CUELGA GAFAS - MASCARILLA CADENA',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '24032328_79_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR DOBLE COMPARTIMENTO',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '40932304_90_main.jpg',
    name: 'LLAVERO KISS ME',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '24032327_20_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/8.html',
    indice: '8',
  },
  {
    image: '40932251_90_main.jpg',
    name: 'CORDÓN CUELGA GAFAS-MASCARILLA CADENA MULTICOLOR',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40532323_03_main.jpg',
    name: 'SOMBRERO RAFIA CUERDA',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40992207_19_main.jpg',
    name: 'PINZA PELO LISA',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '22132233_16_pt00.jpg',
    name: 'ESTUCHE SOBRE GRABADO ANIMAL',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40992225_90_pt00.jpg',
    name: 'LLAVERO CONEJITO BOLSO',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40992208_55_main.jpg',
    name: 'PINZA PELO LISA',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40992220_19_pt00.jpg',
    name: 'LLAVERO POMPÓN MOUSE',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40932246_25_main.jpg',
    name: 'LLAVERO OSO NYLON',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40932306_90_main.jpg',
    name: 'LLAVERO SEND LOVE',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40992217_55_pt00.jpg',
    name: 'LLAVERO POM POM PAÑUELO',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40232204_90_main.jpg',
    name: 'SET COLLARES FLY',
    amount: '7,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '22132238_04_pt00.jpg',
    name: 'ESTUCHE SOBRE GRABADO ANIMAL COLOR',
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/9.html',
    indice: '9',
  },
  {
    image: '40592203_01_pt01.jpg',
    name: 'GORRA NYLON SPORT',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40932242_01_main.jpg',
    name: 'DIADEMA EFECTO RAFIA',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40992222_55_pt00.jpg',
    name: 'LLAVERO OSO',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40592201_58_pt03.jpg',
    name: 'GORRA SPORT COLOR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40232207_90_main.jpg',
    name: 'SET PULSERAS CORAZÓN',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40992243_19_pt04.jpg',
    name: 'LLAVERO OSO',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40592204_56_pt01.jpg',
    name: 'GORRA NYLON SPORT',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40992226_90_pt00.jpg',
    name: 'LLAVERO CONEJITO BOLSO',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40932257_90_main.jpg',
    name: 'CORDÓN CUELGA GAFAS-MASCARILLA CADENA MULTICOLOR',
    amount: '8,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40992216_01_pt00.jpg',
    name: 'LLAVERO POM POM PAÑUELO',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40932230_60_main.jpg',
    name: 'SET COLETEROS YELLOW',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '40232206_90_main.jpg',
    name: 'SET PULSERAS MAR',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/10.html',
    indice: '10',
  },
  {
    image: '_dsc1191.jpg',
    name: 'GORRA SPORT COLOR',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40992206_25_main.jpg',
    name: 'PINZA PELO LISA',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40932247_55_main.jpg',
    name: 'LLAVERO OSO NYLON',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40400112_19_pt00.jpg',
    name: "FUNDA PORTÁTIL 10,1'",
    amount: '12,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40992205_20_main.jpg',
    name: 'PINZA PELO SEMIREDONDA',
    amount: '1,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40592217_01_pt01.jpg',
    name: 'GORRA NYLON',
    amount: '9,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40932248_08_main.jpg',
    name: 'LLAVERO OSO NYLON',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40400115_19_main.jpg',
    name: "FUNDA PORTÁTIL 15,6'",
    amount: '15,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40932166_90_main.jpg',
    name: 'CORDÓN CUELGA GAFAS MULTICOLOR',
    amount: '6,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40932229_90_main.jpg',
    name: 'SET COLETEROS VICHY',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40992227_55_pt04.jpg',
    name: 'LLAVERO RABBIT',
    amount: '4,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '40232210_90_main.jpg',
    name: 'SET PULSERAS MULTICOLOR',
    amount: '5,99 ',
    categoria: 'accesorios',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/accesorios/page/11.html',
    indice: '11',
  },
  {
    image: '62300150_59_main.jpg',
    name: 'V WALK BAG CABINA',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62210323_25_main.jpg',
    name: 'V FLOW MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '14032314_53_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON DOBLE ASA',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62330323_59-malva_pt00.jpg',
    name: 'V SMART MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62211113_04_pt00.jpg',
    name: 'V EDITION CABINA',
    amount: '110,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '40400160_56_pt00_1.jpg',
    name: 'BOLSA DE VIAJE EXPANDIBLE',
    amount: '44,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62239923_32_pt00.jpg',
    name: 'V LIVING MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62239923_30_pt00_1.jpg',
    name: 'V LIVING MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62126733_53_pt00.jpg',
    name: 'V SUMMIT GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62300145_05_main.jpg',
    name: 'V WALK BAG BOLSA DE VIAJE',
    amount: '25,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '14032322_20_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON LLAVERO',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62211133_01_pt00.jpg',
    name: 'V EDITION GRANDE',
    amount: '135,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/0.html',
    indice: '0',
  },
  {
    image: '62300150_59_main.jpg',
    name: 'V WALK BAG CABINA',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62210323_25_main.jpg',
    name: 'V FLOW MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '14032314_53_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON DOBLE ASA',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62330323_59-malva_pt00.jpg',
    name: 'V SMART MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62211113_04_pt00.jpg',
    name: 'V EDITION CABINA',
    amount: '110,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '40400160_56_pt00_1.jpg',
    name: 'BOLSA DE VIAJE EXPANDIBLE',
    amount: '44,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62239923_32_pt00.jpg',
    name: 'V LIVING MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62239923_30_pt00_1.jpg',
    name: 'V LIVING MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62126733_53_pt00.jpg',
    name: 'V SUMMIT GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62300145_05_main.jpg',
    name: 'V WALK BAG BOLSA DE VIAJE',
    amount: '25,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '14032322_20_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON LLAVERO',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '62211133_01_pt00.jpg',
    name: 'V EDITION GRANDE',
    amount: '135,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/1.html',
    indice: '1',
  },
  {
    image: '14032304_01_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON IDENTIFICADOR',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62239913_26_pt00.jpg',
    name: 'V LIVING CABINA',
    amount: '65,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '_dsc1262.jpg',
    name: 'V DESTINY GRANDE',
    amount: '105,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62126733_04_main.jpg',
    name: 'V SUMMIT GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '40100017_60_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62300145_01_main.jpg',
    name: 'V WALK BAG BOLSA DE VIAJE',
    amount: '25,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '40400161_01_pt00.jpg',
    name: 'BOLSA DE VIAJE CONVERTIBLE EN MOCHILA',
    amount: '49,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '14032305_58_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON IDENTIFICADOR',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62300145_59_main.jpg',
    name: 'V WALK BAG BOLSA DE VIAJE',
    amount: '25,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62210333_04_main.jpg',
    name: 'V FLOW GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62330333_59-malva_pt00.jpg',
    name: 'V SMART GRANDE',
    amount: '95,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62301123_01_pt00.jpg',
    name: 'V WALK MEDIANA',
    amount: '68,99 ',
    categoria: 'viaje',
    precio_especial: '47,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/2.html',
    indice: '2',
  },
  {
    image: '62100513_59-malva_pt01.jpg',
    name: 'V UNIVERSE CABINA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '53140072_15_main.jpg',
    name: 'PORTA LIQUIDOS VIAJE',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62126723_53_pt00.jpg',
    name: 'V SUMMIT MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '14032313_01_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON DOBLE ASA',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62239933_32_pt00.jpg',
    name: 'V LIVING GRANDE',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '53141018_90_main.jpg',
    name: 'IDENTIFICADOR DE VIAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62330333_01-negro_pt00.jpg',
    name: 'V SMART GRANDE',
    amount: '95,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62211133_04_pt00.jpg',
    name: 'V EDITION GRANDE',
    amount: '135,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62330323_01-negro_pt00.jpg',
    name: 'V SMART MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '14032306_79_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON IDENTIFICADOR',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62126713_30_main-3.jpg',
    name: 'V SUMMIT CABINA',
    amount: '75,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62277723_01_main.jpg',
    name: 'V LIMITED MEDIANA',
    amount: '115,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/3.html',
    indice: '3',
  },
  {
    image: '62239933_25_pt00_1.jpg',
    name: 'V LIVING GRANDE',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '_dsc1281.jpg',
    name: 'V UNIVERSE MEDIANA',
    amount: '105,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62300170_59_main_1.jpg',
    name: 'V WALK BAG MEDIANA',
    amount: '37,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62211113_01_pt00.jpg',
    name: 'V EDITION CABINA',
    amount: '110,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '14032321_25_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON LLAVERO',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62301133_01_pt00.jpg',
    name: 'V WALK GRANDE',
    amount: '85,99 ',
    categoria: 'viaje',
    precio_especial: '59,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62239913_61_pt00.jpg',
    name: 'V LIVING CABINA',
    amount: '65,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62301113_01_pt00.jpg',
    name: 'V WALK CABINA',
    amount: '57,99 ',
    categoria: 'viaje',
    precio_especial: '39,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62126713_53_pt00.jpg',
    name: 'V SUMMIT CABINA',
    amount: '75,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62100523_01_main.jpg',
    name: 'V UNIVERSE MEDIANA',
    amount: '105,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62220213_60_main.jpg',
    name: 'V ROUTE CABINA',
    amount: '109,00 ',
    categoria: 'viaje',
    precio_especial: '75,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '40100015_90_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/4.html',
    indice: '4',
  },
  {
    image: '62109222_19_main.jpg',
    name: 'V ACTION MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '61900113_40_main.jpg',
    name: 'V ROYAL CABINA',
    amount: '59,99 ',
    categoria: 'viaje',
    precio_especial: '49,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '14032320_01_pt00.jpg',
    name: 'BOLSA DE VIAJE NYLON LLAVERO',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '62009113_04_main_1.jpg',
    name: 'V ROLLING CABINA',
    amount: '85,99 ',
    categoria: 'viaje',
    precio_especial: '49,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '62211123_04_pt00.jpg',
    name: 'V EDITION MEDIANA',
    amount: '125,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '_dsc1242.jpg',
    name: 'V UNIVERSE GRANDE',
    amount: '115,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '61900113_05_main.jpg',
    name: 'V ROYAL CABINA',
    amount: '59,99 ',
    categoria: 'viaje',
    precio_especial: '49,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '62239933_04_pt00_1.jpg',
    name: 'V LIVING GRANDE',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '62239913_79_pt00_1.jpg',
    name: 'V LIVING CABINA',
    amount: '65,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '62330313_59-malva_pt00.jpg',
    name: 'V SMART CABINA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '62300150_05_main.jpg',
    name: 'V WALK BAG CABINA',
    amount: '32,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '53617901_90_main.jpg',
    name: 'IDENTIFICADOR DE VIAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/5.html',
    indice: '5',
  },
  {
    image: '_dsc1250.jpg',
    name: 'V FLOW CABINA',
    amount: '75,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '62009123_04_main_1.jpg',
    name: 'V ROLLING MEDIANA',
    amount: '109,00 ',
    categoria: 'viaje',
    precio_especial: '65,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '62211123_01_pt00.jpg',
    name: 'V EDITION MEDIANA',
    amount: '125,00 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '40100012_90_main.jpg',
    name: 'CORREA SEGURIDAD MALETAS',
    amount: '5,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '62126723_30_main-3.jpg',
    name: 'V SUMMIT MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '53615518_79_main.jpg',
    name: 'CANDADO LLAVE',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '62000570_56_pt00.jpg',
    name: 'TROLLEY MEDIANO NOMAD',
    amount: '59,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '53140082_90_main.jpg',
    name: 'IDENTIFICADOR DE VIAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '62330313_01-negro_pt00.jpg',
    name: 'V SMART CABINA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '53140458_72_main.jpg',
    name: 'ALMOHADA VIAJE',
    amount: '9,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '62201523_04_pt00.jpg',
    name: 'V DESTINY MEDIANA',
    amount: '95,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '62000570_19_pt00.jpg',
    name: 'TROLLEY MEDIANO NOMAD',
    amount: '59,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/6.html',
    indice: '6',
  },
  {
    image: '40100002_01_pt00.jpg',
    name: 'NECESER DE VIAJE PEQUEÑO',
    amount: '19,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '53615518_20_main.jpg',
    name: 'CANDADO LLAVE',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '62239913_04_pt00_1.jpg',
    name: 'V LIVING CABINA',
    amount: '65,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '62277713_63_main.jpg',
    name: 'V LIMITED CABINA',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '62109222_56_pt01.jpg',
    name: 'V ACTION MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '53140108_90_main.jpg',
    name: 'IDENTIFICADOR DE VIAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '62100533_59_main.jpg',
    name: 'V UNIVERSE GRANDE',
    amount: '115,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '62300170_05_main.jpg',
    name: 'V WALK BAG MEDIANA',
    amount: '37,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '_dsc5275_1.jpg',
    name: 'V COOL',
    amount: '72,99 ',
    categoria: 'viaje',
    precio_especial: '49,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '53615519_59_main.jpg',
    name: 'CANDADO TSA COMBINACIÓN',
    amount: '7,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '40100045_59_main.jpg',
    name: 'NECESER DE VIAJE MEDIANO',
    amount: '22,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '53615520_65_pt01.jpg',
    name: 'CANDADO TSA COMBINACIÓN',
    amount: '7,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/7.html',
    indice: '7',
  },
  {
    image: '61901833_59_main.jpg',
    name: 'V ELEGANT NEW GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '40100013_08_pt00.jpg',
    name: 'BOTELLAS SILICONA PORTALÍQUIDOS VIAJE',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '62239913_32_pt00_1.jpg',
    name: 'V LIVING CABINA',
    amount: '65,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '40100020_90_main.jpg',
    name: 'FUNDA MALETA MEDIANA',
    amount: '13,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '40100009_90_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '62210313_04_main.jpg',
    name: 'V FLOW CABINA',
    amount: '75,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '62277733_63_main.jpg',
    name: 'V LIMITED GRANDE',
    amount: '125,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '40100043_20_main.jpg',
    name: 'CANDADO COMBINACION',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '40100002_59_main_1.jpg',
    name: 'NECESER DE VIAJE PEQUEÑO',
    amount: '19,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '62201513_21_pt00.jpg',
    name: 'V DESTINY CABINA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '_dsc7048.jpg',
    name: 'V ACTION CABINA',
    amount: '75,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '40100007_90_pt00.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/8.html',
    indice: '8',
  },
  {
    image: '62126723_04_main-3.jpg',
    name: 'V SUMMIT MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '61901823_59_main.jpg',
    name: 'V ELEGANT NEW MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '53615520_01_main.jpg',
    name: 'ACCESORIOS VIAJE PACO MARTINEZ-0322 CAND',
    amount: '7,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '61900123_40_main.jpg',
    name: 'V ROYAL MEDIANA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '59,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '40100013_25_main.jpg',
    name: 'BOTELLAS SILICONA PORTALÍQUIDOS VIAJE',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '40100010_05_main.jpg',
    name: 'CANDADO TSA COMBINACIÓN',
    amount: '6,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '62201523_01_pt00.jpg',
    name: 'V DESTINY MEDIANA',
    amount: '95,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '62277723_63_main.jpg',
    name: 'V LIMITED MEDIANA',
    amount: '115,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '40100042_01_main.jpg',
    name: 'CANDADO COMBINACION',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '40100014_90_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '62239923_25_pt00_1.jpg',
    name: 'V LIVING MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '62201513_01_pt00.jpg',
    name: 'V DESTINY CABINA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/9.html',
    indice: '9',
  },
  {
    image: '62277733_01_main.jpg',
    name: 'V LIMITED GRANDE',
    amount: '125,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '53140602_20_pt00.jpg',
    name: 'CORREA SEGURIDAD EQUIPAJE',
    amount: '3,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62009133_04_main_1.jpg',
    name: 'V ROLLING GRANDE',
    amount: '124,00 ',
    categoria: 'viaje',
    precio_especial: '75,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62239933_30_pt00_1.jpg',
    name: 'V LIVING GRANDE',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62239913_25_pt00_1.jpg',
    name: 'V LIVING CABINA',
    amount: '65,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '40100001_59_main.jpg',
    name: 'NECESER DE VIAJE COLGAR',
    amount: '17,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62126733_30_main.jpg',
    name: 'V SUMMIT GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62020311_05_main.jpg',
    name: 'V JUMP CABINA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '40100028_90_main.jpg',
    name: 'FUNDA DE VIAJE MALETA PEQUEÑA PRINT UNIVERSE',
    amount: '11,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62201533_01_pt00.jpg',
    name: 'V DESTINY GRANDE',
    amount: '105,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62201513_04_pt00.jpg',
    name: 'V DESTINY CABINA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '40100002_05_pt00.jpg',
    name: 'NECESER DE VIAJE PEQUEÑO',
    amount: '19,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/10.html',
    indice: '10',
  },
  {
    image: '62239913_30_pt00_1.jpg',
    name: 'V LIVING CABINA',
    amount: '65,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '62099923_01_main_1.jpg',
    name: 'V FOCUS MEDIANA',
    amount: '95,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '40100044_08_main.jpg',
    name: 'CANDADO COMBINACION',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '62210323_04_main.jpg',
    name: 'V FLOW MEDIANA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '61900133_05_main.jpg',
    name: 'V ROYAL GRANDE',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '53140458_33_pt00.jpg',
    name: 'ALMOHADA VIAJE',
    amount: '9,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '40100039_05_pt00.jpg',
    name: 'ALMOHADA DE VIAJE VISCOELÁSTICA',
    amount: '12,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '62126713_04_main-3.jpg',
    name: 'V SUMMIT CABINA',
    amount: '75,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '40100001_05_pt00.jpg',
    name: 'NECESER DE VIAJE COLGAR',
    amount: '17,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '53615518_08_main.jpg',
    name: 'CANDADO LLAVE',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '61900133_01_main.jpg',
    name: 'V ROYAL GRANDE',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '61901813_59_main.jpg',
    name: 'V ELEGANT NEW CABINA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/11.html',
    indice: '11',
  },
  {
    image: '40100041_55_main.jpg',
    name: 'ALMOHADA DE VIAJE VISCOELÁSTICA',
    amount: '12,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '62109212_56_pt01.jpg',
    name: 'V ACTION CABINA',
    amount: '75,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '40100016_08_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '62239923_04_pt00.jpg',
    name: 'V LIVING MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '61900123_01_main.jpg',
    name: 'V ROYAL MEDIANA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '59,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '_dsc1268.jpg',
    name: 'V EASY CABINA',
    amount: '59,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '53615143_07_main.jpg',
    name: 'CANDADO TSA LLAVE',
    amount: '7,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '53140200_01_main.jpg',
    name: 'RIÑONERA SEGURIDAD',
    amount: '6,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '53615177_90_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '62020311_39_main.jpg',
    name: 'V JUMP CABINA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '53615179_05_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '62277713_01_main.jpg',
    name: 'V LIMITED CABINA',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/12.html',
    indice: '12',
  },
  {
    image: '53140458_20_pt00.jpg',
    name: 'ALMOHADA VIAJE',
    amount: '9,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '62099913_01_main_1.jpg',
    name: 'V FOCUS CABINA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '53617902_90_main.jpg',
    name: 'IDENTIFICADOR DE VIAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '53140639_05_pt00.jpg',
    name: 'BASCULA DIGITAL',
    amount: '6,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '62000550_56_pt00.jpg',
    name: 'TROLLEY CABINA NOMAD',
    amount: '49,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '53140018_08_pt00.jpg',
    name: 'CORREA SEGURIDAD EQUIPAJE COMBINACION',
    amount: '5,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '53615505_05_main.jpg',
    name: 'CANDADO COMBINACION',
    amount: '5,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '61901823_01_main.jpg',
    name: 'V ELEGANT NEW MEDIANA',
    amount: '79,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '53615146_07_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '40100005_90_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '40100011_90_main.jpg',
    name: 'CORREA SEGURIDAD MALETAS',
    amount: '5,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '62002011_01_main.jpg',
    name: 'V EASY CABINA',
    amount: '59,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/13.html',
    indice: '13',
  },
  {
    image: '40100029_90_main.jpg',
    name: 'FUNDA DE VIAJE MALETA MEDIANA PRINT UNIVERSE',
    amount: '13,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '40100003_05_main.jpg',
    name: 'NECESER O BOLSA DE ASEO',
    amount: '12,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '61901833_01_pt01.jpg',
    name: 'V ELEGANT NEW GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '62000550_19_pt00.jpg',
    name: 'TROLLEY CABINA NOMAD',
    amount: '49,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '53615123_01_pt00.jpg',
    name: 'ANTIFAZ',
    amount: '4,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '62100513_01-negro_main.jpg',
    name: 'V UNIVERSE CABINA',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '53615505_65_main.jpg',
    name: 'CANDADO COMBINACION',
    amount: '5,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '61900113_01_main.jpg',
    name: 'V ROYAL CABINA',
    amount: '59,99 ',
    categoria: 'viaje',
    precio_especial: '49,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '61900123_05_main.jpg',
    name: 'V ROYAL MEDIANA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '59,99 ',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '40100008_90_pt00.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '53140143_90_main.jpg',
    name: 'IDENTIFICADOR DE VIAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '40100003_01_pt00.jpg',
    name: 'NECESER O BOLSA DE ASEO',
    amount: '12,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/14.html',
    indice: '14',
  },
  {
    image: '61901813_01_main.jpg',
    name: 'V ELEGANT NEW CABINA',
    amount: '69,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '40100019_90_main.jpg',
    name: 'FUNDA MALETA PEQUEÑA',
    amount: '11,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '53140120_01_main.jpg',
    name: 'CARTERA SEGURIDAD',
    amount: '6,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '53140458_05_pt00.jpg',
    name: 'ALMOHADA VIAJE',
    amount: '9,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '53610148_32_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '53140458_82_main.jpg',
    name: 'ALMOHADA VIAJE',
    amount: '9,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '53615145_08_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '61900133_40_main.jpg',
    name: 'V ROYAL GRANDE',
    amount: '89,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '40100018_25_main.jpg',
    name: 'IDENTIFICADOR DE EQUIPAJE',
    amount: '2,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '62210333_25_main.jpg',
    name: 'V FLOW GRANDE',
    amount: '99,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '53142018_08_main.jpg',
    name: 'ALMOHADA VIAJE HINCHABLE',
    amount: '3,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '40100040_08_main.jpg',
    name: 'ALMOHADA DE VIAJE VISCOELÁSTICA',
    amount: '12,99 ',
    categoria: 'viaje',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/viaje/page/15.html',
    indice: '15',
  },
  {
    image: '22732307_58_main.jpg',
    name: 'MONEDERO TARJETERO COMBI SNAKE',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '21932313_90_main.jpg',
    name: 'BILLETERA MEDIANA ESTAMPADO FLORES',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '21932309_90_main.jpg',
    name: 'BILLETERA MEDIANA ESTAMPADO FLORAL',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '21932307_90_main.jpg',
    name: 'MONEDERO ESTAMPADO FLORES',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '21932302_90_main.jpg',
    name: 'BILLETERA MEDIANA RAFIA MULTICOLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '21832304_16_main.jpg',
    name: 'BILLETERA TARJETERO BICOLOR RAFIA',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '21832303_90_main.jpg',
    name: 'BILLETERA GRANDE RAFIA MULTICOLOR',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '21832301_90_main.jpg',
    name: 'MONEDERO RAFIA MULTICOLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '22832308_60_main.jpg',
    name: 'BILLETERA PEQUEÑA COLOR ABALORIO',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '22832304_90_main.jpg',
    name: 'PORTA MÓVIL ESTAMPADO FRUTAS 6,1"',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '22832312_26_main.jpg',
    name: 'MONEDERO COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '22832301_90_main.jpg',
    name: 'BILLETERA GRANDE ESTAMPADO FRUTAS',
    amount: '13,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/0.html',
    indice: '0',
  },
  {
    image: '22732307_58_main.jpg',
    name: 'MONEDERO TARJETERO COMBI SNAKE',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '21932313_90_main.jpg',
    name: 'BILLETERA MEDIANA ESTAMPADO FLORES',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '21932309_90_main.jpg',
    name: 'BILLETERA MEDIANA ESTAMPADO FLORAL',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '21932307_90_main.jpg',
    name: 'MONEDERO ESTAMPADO FLORES',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '21932302_90_main.jpg',
    name: 'BILLETERA MEDIANA RAFIA MULTICOLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '21832304_16_main.jpg',
    name: 'BILLETERA TARJETERO BICOLOR RAFIA',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '21832303_90_main.jpg',
    name: 'BILLETERA GRANDE RAFIA MULTICOLOR',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '21832301_90_main.jpg',
    name: 'MONEDERO RAFIA MULTICOLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '22832308_60_main.jpg',
    name: 'BILLETERA PEQUEÑA COLOR ABALORIO',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '22832304_90_main.jpg',
    name: 'PORTA MÓVIL ESTAMPADO FRUTAS 6,1"',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '22832312_26_main.jpg',
    name: 'MONEDERO COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '22832301_90_main.jpg',
    name: 'BILLETERA GRANDE ESTAMPADO FRUTAS',
    amount: '13,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/1.html',
    indice: '1',
  },
  {
    image: '22732311_16_main.jpg',
    name: 'BILLETERA MEDIANA PARCHES',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '22732302_58_main.jpg',
    name: 'BILLETERA MEDIANA LOGO SNAKE',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '22732301_58_main.jpg',
    name: 'MONEDERO LOGO SNAKE',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21932314_90_main.jpg',
    name: 'PORTA MÓVIL ESTAMPADO FLORES',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21932308_90_main.jpg',
    name: 'MONEDERO TARJETERO ESTAMPADO FLORES',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21932306_16_main.jpg',
    name: 'PORTA MÓVIL COMBINACIÓN TEXTURAS',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21932305_16_main.jpg',
    name: 'BILLETERA GRANDE COMBINACIÓN TEXTURAS',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21932304_16_main.jpg',
    name: 'MONEDERO COMBINACIÓN TEXTURAS',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21932303_90_main.jpg',
    name: 'PORTA MÓVIL RAFIA MULTICOLOR',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21932301_90_main.jpg',
    name: 'MONEDERO RAFIA MULTICOLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '22132231_16_pt00.jpg',
    name: 'BILLETERA GRANDE GRABADO ANIMAL',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '22132229_16_pt00.jpg',
    name: 'MONEDERO GRABADO ANIMAL',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/2.html',
    indice: '2',
  },
  {
    image: '21732310_90_main.jpg',
    name: 'BILLETERA MEDIANA PATCHWORK NATURAL',
    amount: '13,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832309_26_main.jpg',
    name: 'BILLETERA PEQUEÑA COLOR ABALORIO',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832305_90_main.jpg',
    name: 'BILLETERA PEQUEÑA ESTAMPADO FRUTAS',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832302_90_main.jpg',
    name: 'BILLETERA MEDIANA ESTAMPADO FRUTAS',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832306_60_main.jpg',
    name: 'BILLETERA PEQUEÑA COLOR',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832310_90_main.jpg',
    name: 'MONEDERO ESTAMPADO FRUTAS',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832303_90_main.jpg',
    name: 'MONEDERO TARJETERO ESTAMPADO FRUTAS',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832307_79_main.jpg',
    name: 'BILLETERA PEQUEÑA COLOR',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22832311_79_main.jpg',
    name: 'MONEDERO COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '21832305_16_main.jpg',
    name: 'BILLETERA MEDIANA BICOLOR RAFIA',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '21832309_25_main.jpg',
    name: 'MONEDERO RAFIA COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '22732312_16_main.jpg',
    name: 'BILLETERA GRANDE PARCHES',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/3.html',
    indice: '3',
  },
  {
    image: '21932312_90_main.jpg',
    name: 'BILLETERA TARJETERO ESTAMPADO FLORES',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '22732306_16_main.jpg',
    name: 'PORTA MÓVIL LOGO SNAKE',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '22732309_16_main.jpg',
    name: 'MONEDERO PARCHES SNAKE',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '21832302_90_main.jpg',
    name: 'BILLETERA MEDIANA RAFIA MULTICOLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '22732310_16_main.jpg',
    name: 'BILLETERA MEDIANA PARCHES SNAKE',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '21832306_55_main.jpg',
    name: 'MONEDERO RAFIA COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '21832307_79_main.jpg',
    name: 'MONEDERO RAFIA COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '22732303_58_main.jpg',
    name: 'BILLETERA GRANDE LOGO SNAKE BOLSILLO',
    amount: '16,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '21932310_90_main_1.jpg',
    name: 'BILLETERA GRANDE ESTAMPADO FLORES',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '22732304_58_main.jpg',
    name: 'BILLETERA GRANDE LOGO SNAKE',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '21832308_41_main.jpg',
    name: 'MONEDERO RAFIA COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '21932311_90_main.jpg',
    name: 'BILLETERA GRANDE ESTAMPADO FLORES',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/4.html',
    indice: '4',
  },
  {
    image: '21932315_90_main.jpg',
    name: 'BILLETERA MEDIANA ESTAMPADO FLORES',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '22732305_58_main.jpg',
    name: 'PORTA MÓVIL LOGO SNAKE',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '22732308_58_main.jpg',
    name: 'BILLETERA GRANDE COMBI SNAKE',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '22732313_90_main.jpg',
    name: 'BILLETERA GRANDE PRINT FLOWER',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '22732317_32_main.jpg',
    name: 'BILLETERA PEQUEÑA CHAROL',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '22732321_32_main.jpg',
    name: 'MONEDERO TARJETERO CHAROL',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '22732315_90_main.jpg',
    name: 'BILLETERA MEDIANA PRINT FLOWER',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '21732301_08_main.jpg',
    name: 'MONEDERO RAFIA Y CANVAS',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '21732304_60_main.jpg',
    name: 'BILLETERA RAFIA Y CANVAS',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '21732309_90_main.jpg',
    name: 'BILLETERA MEDIANA PATCHWORK NATURAL',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '20492204_90_main.jpg',
    name: 'ESTUCHE CANVAS PRINT JUNGLE',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '20492202_90_main.jpg',
    name: 'MONEDERO CANVAS ESTAMPADO JUNGLE',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/5.html',
    indice: '5',
  },
  {
    image: '20492207_90_main.jpg',
    name: 'BILLETERA ESTAMPADO JUNGLE',
    amount: '11,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '20492211_79_main.jpg',
    name: 'MONEDERO ACOLCHADO COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22632310_90_main.jpg',
    name: 'MONEDERO TARJETERO COLORBLOCK COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22632314_20_main.jpg',
    name: 'MONEDERO TARJETERO COCO COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22732325_32_main.jpg',
    name: 'MONEDERO CHAROL',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22732314_90_main.jpg',
    name: 'MONEDERO TARJETERO PRINT FLOWER',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22732318_08_main.jpg',
    name: 'BILLETERA PEQUEÑA CHAROL',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22632318_90_main.jpg',
    name: 'MONEDERO ESTAMPADO TROPICAL',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22732322_58_main.jpg',
    name: 'MONEDERO TARJETERO CHAROL',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '21732306_90_main.jpg',
    name: 'BILLETERA MEDIANA RAFIA',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '21732302_60_main.jpg',
    name: 'MONEDERO RAFIA Y CANVAS',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22732326_04_main.jpg',
    name: 'MONEDERO CHAROL',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/6.html',
    indice: '6',
  },
  {
    image: '22732319_90_main.jpg',
    name: 'BILLETERA MEDIANA PRINT FLOWER',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '22732323_04_main.jpg',
    name: 'MONEDERO TARJETERO CHAROL',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '22732320_08_main.jpg',
    name: 'BILLETERA MEDIANA CHAROL',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '20492203_90_main.jpg',
    name: 'BILLETERA CANVAS PRINT JUNGLE',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '22632311_90_main.jpg',
    name: 'BILLETERA PEQUEÑA COLORS',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '20492208_90_main.jpg',
    name: 'BILLETERA GRANDE SOLAPA ESTAMPADO JUNGLE',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '20492212_25_main.jpg',
    name: 'MONEDERO ACOLCHADO COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '21732303_08_main.jpg',
    name: 'BILLETERA RAFIA Y CANVAS',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '22632315_25_main.jpg',
    name: 'MONEDERO TARJETERO COCO COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '22632319_90_main.jpg',
    name: 'BILLETERA GRANDE ESTAMPADO TROPICAL',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '22732316_90_main.jpg',
    name: 'BILLETERA PEQUEAÑA PRINT FLOWER',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '22732324_90_main.jpg',
    name: 'MONEDERO PRINT FLOWER',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/7.html',
    indice: '7',
  },
  {
    image: '21732307_90_main.jpg',
    name: 'BILLETERA GRANDE RAFIA',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '21732311_90_main.jpg',
    name: 'BILLETERA GRANDE PATCHWORK NATURAL',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '20492205_90_main.jpg',
    name: 'MONEDERO ESTAMPADO JUNGLE',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '20492209_90_main.jpg',
    name: 'BILLETERA GRANDE ESTAMPADO JUNGLE',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '20492213_79_main.jpg',
    name: 'BILLETERA ACOLCHADA COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '22632312_90_main.jpg',
    name: 'BILLETERA PEQUEÑA ESTAMPADO TROPICAL',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '24032324_29_pt00.jpg',
    name: 'PORTA MÓVIL NYLON TRAVELLER 6,2"',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '24032328_79_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR DOBLE COMPARTIMENTO',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '22632316_20_main.jpg',
    name: 'MONEDERO COCO COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '22632320_90_main.jpg',
    name: 'BILLETERA MEDIANA ESTAMPADO TROPICAL',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '21732305_08_main.jpg',
    name: 'MONEDERO MEDIANO RAFIA Y CANVAS',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '21732308_90_main.jpg',
    name: 'PORTA MÓVIL COMBINACIÓN RAFIAS 6,1"',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/8.html',
    indice: '8',
  },
  {
    image: '20492210_90_main.jpg',
    name: 'PORTA MÓVIL ESTAMPADO JUNGLE 6,1"',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '20492214_25_main.jpg',
    name: 'BILLETERA ACOLCHADA COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '22632309_90_main.jpg',
    name: 'BILLETERA PEQUEÑA COLORBLOCK COLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '22632313_02_main.jpg',
    name: 'MONEDERO TARJETERO COCO COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '22632317_25_main.jpg',
    name: 'MONEDERO COCO COLOR',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '22632321_90_main.jpg',
    name: 'BILLETERA MEDIANA COLORBLOCK TROPICAL',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '24032322_58_pt00.jpg',
    name: 'PORTA MÓVIL NYLON TRAVELLER 6,2"',
    amount: '16,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '24032326_25_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '22132212_90_main.jpg',
    name: 'MONEDERO NYLON ESTAMPADO FLORES',
    amount: '7,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '22132216_90_main.jpg',
    name: 'BILLETERA NYLON ESTAMPADO FLORES COMPARTIMENTOS',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '20492201_90_main.jpg',
    name: 'MONEDERO CANVAS ESTAMPADO JUNGLE',
    amount: '8,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '20492206_90_main.jpg',
    name: 'MONEDERO TARJETERO ESTAMPADO JUNGLE',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/9.html',
    indice: '9',
  },
  {
    image: '24032325_01_pt00.jpg',
    name: 'PORTA MÓVIL NYLON TRAVELLER 6,2"',
    amount: '18,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '24032329_53_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR DOBLE COMPARTIMENTO',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '20392202_14_pt00.jpg',
    name: 'MONEDERO NYLON BICOLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '20392218_01_pt00.jpg',
    name: 'PORTA MÓVIL NYLON BOLSILLOS 6,1"',
    amount: '16,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '20392210_08_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '20392206_16_pt00.jpg',
    name: 'BILLETERA NYLON COLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '24032327_20_pt00.jpg',
    name: 'ESTUCHE NYLON COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '24032323_01_pt00.jpg',
    name: 'PORTA MÓVIL NYLON TRAVELLER 6,2"',
    amount: '16,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '20392214_16_pt00.jpg',
    name: 'ESTUCHE BOLSO NYLON',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '20392203_01_pt00.jpg',
    name: 'MONEDERO NYLON DOBLE BOLSILLO',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '22132238_04_pt00.jpg',
    name: 'ESTUCHE SOBRE GRABADO ANIMAL COLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '22132213_90_main.jpg',
    name: 'BILLETERA MEDIANA NYLON ESTAMPADO FLORES',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/10.html',
    indice: '10',
  },
  {
    image: '22132214_90_main.jpg',
    name: 'MONEDERO NYLON ESTAMPADO FLORES BOLSILLOS',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '20392204_32_pt00.jpg',
    name: 'MONEDERO NYLON DOBLE BOLSILLO',
    amount: '10,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '20392207_01_pt00.jpg',
    name: 'BILLETERA NYLON COLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '20392211_16_pt00.jpg',
    name: 'PORTA MÓVIL NYLON SPORT 6,1"',
    amount: '16,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '20392215_01_pt00.jpg',
    name: 'ESTUCHE BOLSO NYLON',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '22132236_04_pt00.jpg',
    name: 'BILLETERA GRANDE GRABADO ANIMAL COLOR',
    amount: '13,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '22132235_04_pt00.jpg',
    name: 'BILLETERA MEDIANA GRABADO ANIMAL COLOR',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '22132233_16_pt00.jpg',
    name: 'ESTUCHE SOBRE GRABADO ANIMAL',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '22132230_16_pt00.jpg',
    name: 'BILLETERA MEDIANA GRABADO ANIMAL',
    amount: '12,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '22132237_04_pt00.jpg',
    name: 'BILLETERA GRANDE GRABADO ANIMAL COLOR',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '22132234_04_pt00.jpg',
    name: 'MONEDEO TARJETERO GRABADO ANIMAL COLOR',
    amount: '9,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '22132215_90_main.jpg',
    name: 'BILLETERA NYLON ESTAMPADO FLORES DOBLE COMPARTIMENTO',
    amount: '15,99 ',
    categoria: 'carteras',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/carteras/page/11.html',
    indice: '11',
  },
  {
    image: '40400162_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE MALETÍN 15"',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400157_01_pt00.jpg',
    name: 'BOLSO BANDOLERA DE VIAJE',
    amount: '35,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400160_56_pt00_1.jpg',
    name: 'BOLSA DE VIAJE EXPANDIBLE',
    amount: '44,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400159_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE EXPANDIBLE',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400156_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE UNISEX PEQUEÑA',
    amount: '79,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400167_01_pt00.jpg',
    name: 'MOCHILA WORKER PORTÁTIL 15"',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400172_56_pt00.jpg',
    name: 'BOLSO BANDOLERA BOLSILLOS ADVENTURE',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40532309_58_main.jpg',
    name: 'GORRA BASIC',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400161_01_pt00.jpg',
    name: 'BOLSA DE VIAJE CONVERTIBLE EN MOCHILA',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400168_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE - MALETÍN EXPANDIBLE',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '24032321_01_pt00.jpg',
    name: 'RIÑONERA NYLON TRAVELLER',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400171_56_pt00.jpg',
    name: 'MOCHILA NYLON ADVENTURE',
    amount: '37,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/0.html',
    indice: '0',
  },
  {
    image: '40400162_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE MALETÍN 15"',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400157_01_pt00.jpg',
    name: 'BOLSO BANDOLERA DE VIAJE',
    amount: '35,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400160_56_pt00_1.jpg',
    name: 'BOLSA DE VIAJE EXPANDIBLE',
    amount: '44,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400159_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE EXPANDIBLE',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400156_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE UNISEX PEQUEÑA',
    amount: '79,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400167_01_pt00.jpg',
    name: 'MOCHILA WORKER PORTÁTIL 15"',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400172_56_pt00.jpg',
    name: 'BOLSO BANDOLERA BOLSILLOS ADVENTURE',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40532309_58_main.jpg',
    name: 'GORRA BASIC',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400161_01_pt00.jpg',
    name: 'BOLSA DE VIAJE CONVERTIBLE EN MOCHILA',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400168_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE - MALETÍN EXPANDIBLE',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '24032321_01_pt00.jpg',
    name: 'RIÑONERA NYLON TRAVELLER',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400171_56_pt00.jpg',
    name: 'MOCHILA NYLON ADVENTURE',
    amount: '37,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/1.html',
    indice: '1',
  },
  {
    image: '40400169_01_pt00.jpg',
    name: 'MOCHILA WORKER NYLON 15"',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400158_01_pt00.jpg',
    name: 'BOLSO BANDOLERA NYLON SPORT',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400155_01_pt00.jpg',
    name: 'MOCHILA DE VIAJE UNISEX GRANDE',
    amount: '85,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400166_01_pt00.jpg',
    name: 'MOCHILA WORKER PORTÁTIL 15"',
    amount: '54,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40532307_08_main.jpg',
    name: 'GORRA BASIC',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400170_19_pt00.jpg',
    name: 'MOCHILA WORKER NYLON 15"',
    amount: '45,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '52100956_01_main.jpg',
    name: 'BILLETERA GRANDE HORIZONRAL CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400077_01_pt00.jpg',
    name: 'MOCHILA REFLECTANTE HOMBRE',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '53800011_01_pt01.jpg',
    name: 'CAJA REGALO HOMBRE',
    amount: '24,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '_dsc7006.jpg',
    name: 'CINTURON CASUAL PIEL AJUSTABLE',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400102_19_pt00.jpg',
    name: 'WEEKENDER NYLON',
    amount: '35,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400151_56_main.jpg',
    name: 'PORTA MÓVIL NYLON COLOR',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/2.html',
    indice: '2',
  },
  {
    image: '40400135_56_pt00.jpg',
    name: "MOCHILA NYLON BASIC PORTÁTIL 13,3'",
    amount: '32,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '53900028_01_main.jpg',
    name: 'TARJETERO PIEL CONTRASTE',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '40532305_58_main.jpg',
    name: 'BUCKET CUERDA',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '40400097_19_pt00.jpg',
    name: 'BANDOLERA HOMBRE',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '_dsc6987.jpg',
    name: 'MOCHILA CABALLERO',
    amount: '34,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '52700955_05_main.jpg',
    name: 'BILLETERA PIEL MARINO',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '52500956_01_main.jpg',
    name: 'BILLETERA PIEL TIRA',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '52400956_01_main.jpg',
    name: 'BILLETERA PIEL LISA',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '40400153_01_main.jpg',
    name: 'PORTA MÓVIL NYLON COLOR SPORT',
    amount: '13,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '40400125_01_pt00.jpg',
    name: 'BANDOLERA LIMITLESS CABALLERO',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '_dsc7010.jpg',
    name: 'CINTURON PIEL REVERSIBLE',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '59000001_19_pt00.jpg',
    name: 'BILLETERA BITONO CABALLERO',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/3.html',
    indice: '3',
  },
  {
    image: '54100072_12_main.jpg',
    name: 'BILLETERA PEQUEÑA VERTICAL SPORT',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '53800014_12_main.jpg',
    name: 'CAJA REGALO HOMBRE',
    amount: '24,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '52800954_12_main.jpg',
    name: 'BILLETERA PIEL BOLSILLO FRONTAL',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '40400099_19_main.jpg',
    name: 'MOCHILA NYLON',
    amount: '34,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '40400154_41_main.jpg',
    name: 'PORTA MÓVIL NYLON COLOR SPORT',
    amount: '13,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '40503504_12_main.jpg',
    name: 'CINTURON BÁSICO AJUSTABLE PIEL',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '40400108_19_pt00.jpg',
    name: 'ORGANIZADOR ELECTRÓNICO',
    amount: '10,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '40592217_01_pt01.jpg',
    name: 'GORRA NYLON',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '53800013_01_main.jpg',
    name: 'CAJA REGALO HOMBRE',
    amount: '24,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '54000028_05_main.jpg',
    name: 'TARJETERO PIEL TEXTURA TRENZADA',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '53800012_01_pt01_1.jpg',
    name: 'CAJA REGALO HOMBRE',
    amount: '24,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '40400070_01_main.jpg',
    name: 'BOLSO BANDOLERA EFECTO PIEL SOLAPA',
    amount: '27,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/4.html',
    indice: '4',
  },
  {
    image: '40400152_05_main.jpg',
    name: 'PORTA MÓVIL NYLON COLOR',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '52300955_12_main.jpg',
    name: 'BILLETERA PIEL TEXTURA',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '40400115_19_main.jpg',
    name: "FUNDA PORTÁTIL 15,6'",
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '_dsc6839.jpg',
    name: 'MALETÍN BUSINESS PORTA PC',
    amount: '37,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '59000013_56_main.jpg',
    name: 'BILLETERA NYLON BASIC',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '_dsc6853.jpg',
    name: 'NECESER ASA MUÑECA',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '40400134_01_pt00.jpg',
    name: "MOCHILA NYLON BASIC PORTÁTIL 13,3'",
    amount: '34,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '40400067_01_pt00.jpg',
    name: 'NECESER CABALLERO',
    amount: '21,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '40503508_12_main.jpg',
    name: 'CINTURON CASUAL PIEL AJUSTABLE',
    amount: '18,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '53300011_12_pt00.jpg',
    name: 'MONEDERO LLAVERO CABALLERO',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '52100949_01_main.jpg',
    name: 'BILLETERA PIEL CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '40400040_01_main.jpg',
    name: 'PORTADOCUMENTOS MULTIPOSICIONES',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/5.html',
    indice: '5',
  },
  {
    image: '40400002_12_pt00.jpg',
    name: 'BANDOLERA HOMBRE TEXTURA PIEL',
    amount: '29,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '52400029_01_main.jpg',
    name: 'TARJETERO AUTOMÁTICO PIEL LISA',
    amount: '24,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '40400143_41_main.jpg',
    name: 'RIÑONERA STREET',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '40503501_90_pt01.jpg',
    name: 'CINTURÓN TRENZADO TEXTIL ELÁSTICO',
    amount: '14,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '_dsc6954.jpg',
    name: 'BOLSO MANO NECESER HOMBRE LIMITLESS',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '59000014_19_main.jpg',
    name: 'BILLETERA NYLON BASIC SPORT',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '_dsc6994.jpg',
    name: 'CINTURON TRENZADO ELÁSTICO PIEL',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '59000004_05_pt00.jpg',
    name: 'BILLETERA BITONO CABALLERO',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '40400149_01_main.jpg',
    name: 'BOLSO MANO NECESER HOMBRE NYLON',
    amount: '23,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '59000015_56_main.jpg',
    name: 'BILLETERA NYLON BASIC SPORT',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '52100956_01_main.jpg',
    name: 'BILLETERA PORTUGAL CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '54000013_05_main.jpg',
    name: 'MONEDERO PIEL TEXTURA TRENZADA',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/6.html',
    indice: '6',
  },
  {
    image: '40400113_19_main_1.jpg',
    name: "FUNDA PORTÁTIL 13,3'",
    amount: '14,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '59000022_11_main.jpg',
    name: 'MONEDERO TARJETERO EFECTO PIEL',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '54000051_05_main.jpg',
    name: 'BILLETERA HORIZONTAL PIEL TEXTURA TRENZADA',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '59000012_19_main.jpg',
    name: 'BILLETERA NYLON BASIC',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '40592203_01_pt01.jpg',
    name: 'GORRA NYLON SPORT',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '40400147_01_main.jpg',
    name: 'BOLSO SOBRE NECESER ASA MUÑECA',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '40400100_01_main.jpg',
    name: 'PORTADOCUMENTOS CABALLERO',
    amount: '34,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '53900028_12_main.jpg',
    name: 'TARJETERO PIEL CONTRASTE',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '54000032_05_main.jpg',
    name: 'BILLETERA PEQUEÑA HORIZONTAL PIEL TEXTURA TRENZADA',
    amount: '16,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '40592218_25_pt01.jpg',
    name: 'GORRA NYLON',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '59000023_11_main.jpg',
    name: 'BILLETERA EFECTO PIEL',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '_dsc6881.jpg',
    name: 'BANDOLERA NYLON',
    amount: '23,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/7.html',
    indice: '7',
  },
  {
    image: '40400039_01_pt00.jpg',
    name: 'BANDOLERA RECTANGULAR ASA CORTA',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400044_56_pt00.jpg',
    name: 'BANDOLERA PEQUEÑA HOMBRE',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400138_01_pt00.jpg',
    name: 'MOCHILA CABALLERO MULTIBOLSILLOS',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '52600082_12_pt00.jpg',
    name: 'BILLETERA PIEL VERTICAL CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '52100949_12_main.jpg',
    name: 'BILLETERA PIEL CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400112_19_pt00.jpg',
    name: "FUNDA PORTÁTIL 10,1'",
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400130_01_pt00.jpg',
    name: 'MOCHILA CRUZADA NYLON URBAN',
    amount: '27,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400073_12_pt00.jpg',
    name: 'BANDOLERA CABALLERO',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400139_41_main.jpg',
    name: 'MOCHILA CABALLERO MULTIBOLSILLOS',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400133_56_pt00.jpg',
    name: "MOCHILA BASIC NYLON PORTÁTIL 13,2'",
    amount: '29,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '_dsc6982.jpg',
    name: 'BOLSA DE VIAJE CABALLERO AGARRE',
    amount: '36,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400057_01_pt00.jpg',
    name: 'NECESER CABALLERO DOBLE BOLSILLO',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/8.html',
    indice: '8',
  },
  {
    image: '40400064_12_pt00.jpg',
    name: 'BANDOLERA MEDIANA CABALLERO',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '52800051_12_pt00.jpg',
    name: 'BILLETERA AMERICANO CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '40400145_01_main.jpg',
    name: 'NECESER ASA MUÑECA',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '_dsc6924.jpg',
    name: "MOCHILA NYLON PORTÁTIL 13,2'",
    amount: '32,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '40400012_30_pt00.jpg',
    name: 'NECESER CABALLERO',
    amount: '14,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '40400074_01_main.jpg',
    name: 'MOCHILA HOMBRE NYLON RESINADO',
    amount: '32,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '53700074_01_pt00.jpg',
    name: 'BILLETERA PEQUEÑA VERTICAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '52700042_05_pt00.jpg',
    name: 'BILLETERA PIEL SAFFIANO HORIZONTAL',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '40400110_19_pt00.jpg',
    name: 'MOCHILA CRUZADA CABALLERO',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '40400109_19_pt00.jpg',
    name: 'ORGANIZADOR ELECTRÓNICO',
    amount: '10,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '40400058_12_pt00.jpg',
    name: 'NECESER CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '40400111_19_pt00.jpg',
    name: 'BOLSA DE VIAJE CABALLERO',
    amount: '29,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/9.html',
    indice: '9',
  },
  {
    image: '52800072_12_pt00.jpg',
    name: 'BILLETERA PEQUEÑA VERTICAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40400101_19_pt00.jpg',
    name: 'NECESER NYLON',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '_dsc6914.jpg',
    name: 'BANDOLERA BOLSILLO',
    amount: '29,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '52100011_01_pt00.jpg',
    name: 'MONEDERO PIEL CABALLERO',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40503507_01_main.jpg',
    name: 'CINTURON CASUAL PIEL AJUSTABLE',
    amount: '18,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40400132_01_pt00.jpg',
    name: "MOCHILA BASIC NYLON PORTÁTIL 13,2'",
    amount: '32,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '52700082_05_pt00.jpg',
    name: 'BILLETERA PIEL SAFFIANO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40400033_01_pt00.jpg',
    name: 'MOCHILA ESTRUCTURA USB',
    amount: '37,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '53700024_01_pt00.jpg',
    name: 'TARJETERO MONEDERO CABALLERO',
    amount: '10,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40400122_19_pt00.jpg',
    name: 'MOCHILA MALETÍN NOMAD',
    amount: '45,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40400128_01_pt00.jpg',
    name: 'BANDOLERA BOLSILLOS REGULAR',
    amount: '23,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40400029_01_pt00.jpg',
    name: 'BANDOLERA REFLECTANTE HOMBRE',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/10.html',
    indice: '10',
  },
  {
    image: '40400142_01_pt00.jpg',
    name: 'RIÑONERA STREET',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '53700043_01_pt00.jpg',
    name: 'BILLETERA MEDIANA HORIZONTAL CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '40400103_19_pt00.jpg',
    name: 'BOLSO NYLON CABALLERO',
    amount: '32,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '53100062_12_pt00.jpg',
    name: 'BILLETERA CREMALLERA PESPUNTES PIEL',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '52100011_12_pt00.jpg',
    name: 'MONEDERO PIEL CABALLERO',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '40400035_01_pt00.jpg',
    name: 'MOCHILA TROLLEY ASA TELESCÓPICA',
    amount: '75,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '52700013_05_pt00.jpg',
    name: 'MONEDERO CABALLERO',
    amount: '10,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '40400114_19_pt00.jpg',
    name: "FUNDA PORTÁTIL 10,1'",
    amount: '10,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '52700032_05_pt00.jpg',
    name: 'BILLETERA PEQUEÑA PIEL HORIZONTAL SAFFIANO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '40400068_01_pt00.jpg',
    name: 'MOCHILA CABALLERO',
    amount: '34,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '52200011_01_pt00.jpg',
    name: 'MONEDERO CABALLERO',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '40400063_01_pt00.jpg',
    name: 'BANDOLERA MEDIANA CABALLERO',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/11.html',
    indice: '11',
  },
  {
    image: '52200044_01_pt00.jpg',
    name: 'BILLETERA GRABADO PIEL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '40400027_01_pt00.jpg',
    name: 'MOCHILA RESINADO MULTIPOSICIÓN',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '52900047_12_pt00.jpg',
    name: 'BILLETERA MEDIANA VINTAGE PIEL',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '52900078_12_pt00.jpg',
    name: 'BILLETERA VERTICAL PIEL GRABADA',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '40400144_01_pt00.jpg',
    name: 'BOLSA DE VIAJE MOCHILA MULTIBOLSILLOS',
    amount: '45,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '52500011_01_pt00.jpg',
    name: 'MONEDERO PIEL CABALLERO',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '40400043_01_pt00.jpg',
    name: 'BANDOLERA CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '52500051_01_pt00.jpg',
    name: 'BILLETERA PIEL PESTAÑA',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '40400137_01_pt00.jpg',
    name: 'MOCHILA MALETIN USB BOLSILLOS',
    amount: '39,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '52300084_12_pt00.jpg',
    name: 'BILLETERA GRANDE VERTICAL LÍNEAS CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '52300045_12_pt00.jpg',
    name: 'BILLETERA MEDIANA LÍNEAS HORIZONTAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '40400072_01_pt00.jpg',
    name: 'BANDOLERA CABALLERO',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/12.html',
    indice: '12',
  },
  {
    image: '52900012_12_pt00.jpg',
    name: 'MONEDERO LLAVERO VINTAGE PIEL',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '40400079_01_pt00.jpg',
    name: 'BANDOLERA CABALLERO',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '52900062_12_pt00.jpg',
    name: 'BILLETERA CREMALLERA VINTAGE PIEL',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '40400028_01_pt00.jpg',
    name: 'MOCHILA CONEXIÓN USB',
    amount: '45,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '52500081_01_pt00.jpg',
    name: 'BILLETERA PIEL PESPUNTE CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '40400045_01_pt00.jpg',
    name: 'BANDOLERA PEQUEÑA SOLAPA',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '52400027_01_pt00.jpg',
    name: 'TARJETERO CABALLERO',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '53200033_12_pt00.jpg',
    name: 'BILLETERA PIEL DESGASTADO CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '40400131_01_pt00.jpg',
    name: 'RIÑONERA NYLON URBAN',
    amount: '17,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '53200011_12_pt00.jpg',
    name: 'MONEDERO PIEL DESGASTADO',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '40400126_01_pt00.jpg',
    name: 'MOCHILA NYLON LIMITLESS CABALLERO',
    amount: '45,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '52500041_01_pt00.jpg',
    name: 'BILLETERA PIEL PESPUNTE',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/13.html',
    indice: '13',
  },
  {
    image: '40400129_01_pt00.jpg',
    name: "MOCHILA ALL-COMPLETE PORTÁTIL 15,6'",
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52100022_01_pt00.jpg',
    name: 'TARJETERO SLIM PIEL CABALLERO',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52300052_12_pt00.jpg',
    name: 'BILLETERA AMERICANO LÍNEAS CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '53300023_12_pt00.jpg',
    name: 'TARJETERO CABALLERO',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52300026_12_pt00.jpg',
    name: 'TARJETERO CABALLERO',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52100052_01_pt00.jpg',
    name: 'BILLETERA PIEL PESTAÑA',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52100083_12_pt00.jpg',
    name: 'BILLETERA VERTICAL PIEL PESTAÑA',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52600012_12_pt00.jpg',
    name: 'MONEDERO CABALLERO',
    amount: '9,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52200075_01_pt00.jpg',
    name: 'BILLETERA GRABADO VERTICAL PIEL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '53400072_01_pt00.jpg',
    name: 'BILLETERA PEQUEÑA VERTICAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '52500021_01_pt00.jpg',
    name: 'CARTERA PIEL PESPUNTE',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '53400073_01_pt00.jpg',
    name: 'BILLETERA PEQUEÑA VERTICAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/14.html',
    indice: '14',
  },
  {
    image: '53500032_12_pt00.jpg',
    name: 'BILLETERA PEQUEÑA HORIZONTAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '62000550_19_pt00.jpg',
    name: 'TROLLEY CABINA NOMAD',
    amount: '49,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '52600042_12_pt00.jpg',
    name: 'BILLETERA PIEL CIERRE CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '52100052_12_pt00.jpg',
    name: 'BILLETERA PIEL PESTAÑA',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '53500061_12_pt00.jpg',
    name: 'BILLETERA PIEL CREMALLERA CABALLERO',
    amount: '25,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '52100022_12_pt04.jpg',
    name: 'TARJETERO SLIM PIEL CABALLERO',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '53200077_12_pt00.jpg',
    name: 'BILLETERA VERTICAL PIEL NATURAL',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '40400041_01_pt00.jpg',
    name: 'PORTADOCUMENTOS CABALLERO',
    amount: '35,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '53300031_12_pt00.jpg',
    name: 'BILLETERA PEQUEÑA HORIZONTAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '40400127_01_pt00.jpg',
    name: 'MOCHILA CRUZADA NYLON LIMITLESS CABALLERO',
    amount: '26,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '53500025_12_pt00.jpg',
    name: 'CARTERA MÁGICA CABALLERO',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '59000003_05_pt00.jpg',
    name: 'BILLETERA BITONO CABALLERO CREMALLERA',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/15.html',
    indice: '15',
  },
  {
    image: '52600031_12_pt00.jpg',
    name: 'BILLETERA HORIZONTAL PIEL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '_dsc6927.jpg',
    name: 'BANDOLERA GRANDE CABALLERO',
    amount: '29,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '40400098_19_pt00.jpg',
    name: 'BANDOLERA NYLON HOMBRE',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '52100083_01_pt00.jpg',
    name: 'BILLETERA VERTICAL PIEL PESTAÑA',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '40400124_01_pt00.jpg',
    name: 'RIÑONERA LIMITLESS CABALLERO',
    amount: '22,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '54100031_01_main.jpg',
    name: 'BILLETERA PEQUEÑA HORIZONTAL CABALLERO',
    amount: '15,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '53100048_12_pt00.jpg',
    name: 'BILLETERA MEDIANA HORIZONTAL CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '40400150_01_main.jpg',
    name: 'PORTA MÓVIL NYLON COLOR',
    amount: '12,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '59000024_19_main.jpg',
    name: 'TARJETERO NYLON SPORT',
    amount: '8,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '53500029_12_pt0.jpg',
    name: 'TARJETERO AUTOMÁTICO PIEL GRABADA',
    amount: '24,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '52400071_01_pt00.jpg',
    name: 'BILLETERA PIEL LISA CABALLERO',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
  {
    image: '54100052_01_main.jpg',
    name: 'BILLETERA HORIZONTAL PIEL SPORT',
    amount: '19,99 ',
    categoria: 'hombre',
    precio_especial: '0',
    link: 'https://www.pacomartinez.com/es/hombre/page/16.html',
    indice: '16',
  },
];
