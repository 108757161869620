export const ADD_PRODUCT_REQUEST = 'cesta/ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'cesta/ADD_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT_REQUEST = 'cesta/REMOVE_PRODUCT_REQUEST';
export const REMOVE_PRODUCT_SUCCESS = 'cesta/REMOVE_PRODUCT_SUCCESS';
export const ADD_CODE_REQUEST = 'cesta/ADD_CODE_REQUEST';
export const INCREMENT_PRODUCT_UNITS = 'cesta/INCREMENT_PRODUCT_UNITS';
export const DECREMENT_PRODUCT_UNITS = 'cesta/DECREMENT_PRODUCT_UNITS';
export const EMPTY_PRODUCTS = 'cesta/EMPTY_PRODUCTS';

const initialState = {
  isAddingProduct: false,
  isDeletingProduct: false,
  isAddingPromotionalCode: false,
  isRemovingPromotionalCode: false,
  products: [],
  promotionalCode: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        isAddingProduct: true,
      };
    case ADD_PRODUCT_SUCCESS:
      const foundIndex =
        state.products.findIndex(
          product => product.id === action.product.id,
        ) !== -1;
      return {
        ...state,
        products: foundIndex
          ? state.products.map(product =>
              product.id === action.product.id
                ? { ...product, amount: product.amount + action.product.amount }
                : product,
            )
          : [...state.products, action.product],
        isAddingProduct: false,
      };
    case REMOVE_PRODUCT_REQUEST:
      return {
        ...state,
        isDeletingProduct: true,
      };
    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        products: state.products.filter(product => product.id !== action.id),
        isDeletingProduct: false,
      };
    case INCREMENT_PRODUCT_UNITS:
      return {
        ...state,
        products: state.products.map(product =>
          product.id === action.id
            ? { ...product, amount: product.amount + 1 }
            : product,
        ),
      };
    case DECREMENT_PRODUCT_UNITS:
      return {
        ...state,
        products: state.products.map(product =>
          product.id === action.id
            ? {
                ...product,
                amount:
                  product.amount > 1 ? product.amount - 1 : product.amount,
              }
            : product,
        ),
      };
    case EMPTY_PRODUCTS:
      return {
        ...state,
        products: [],
      };
    default:
      return state;
  }
};

export const addProduct = product => {
  return dispatch => {
    dispatch({
      type: ADD_PRODUCT_REQUEST,
    });

    return setTimeout(() => {
      dispatch({
        type: ADD_PRODUCT_SUCCESS,
        product,
      });
    }, 300);
  };
};

export const removeProduct = id => {
  return dispatch => {
    dispatch({
      type: REMOVE_PRODUCT_REQUEST,
    });

    return dispatch({
      type: REMOVE_PRODUCT_SUCCESS,
      id,
    });
  };
};

export const incrementProductUnits = id => {
  return dispatch => {
    dispatch({
      type: INCREMENT_PRODUCT_UNITS,
      id,
    });
  };
};

export const decrementProductUnits = id => {
  return dispatch => {
    dispatch({
      type: DECREMENT_PRODUCT_UNITS,
      id,
    });
  };
};

export const emptyProducts = () => {
  return dispatch => {
    dispatch({
      type: EMPTY_PRODUCTS,
    });
  };
};
