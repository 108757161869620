import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import {
  ProductWrapper,
  ProductImage,
  Divider,
  ProductDetails,
  estiloPrecio,
  estiloPrecioEspecial,
} from '../styles';

function ProductItem({
  product,
  index,
  array,
  openProductDialog,
  isPacoMartinez = false,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <ProductWrapper
      key={product.id}
      index={index}
      onClick={() => openProductDialog(product, index, array)}
    >
      <ProductImage>
        {!imageLoaded && <Skeleton variant="rect" width="100%" height="100%" />}
        <img
          src={
            product.image.indexOf('http') === 0
              ? product.image
              : isPacoMartinez
              ? `./assets/pacomartinez/${product.image}`
              : `https://totem.bigbenconnected.es/${product.image}`
          }
          draggable={false}
          onLoad={() => setImageLoaded(true)}
          style={
            !imageLoaded ? { visibility: 'hidden', width: 0, height: 0 } : {}
          }
          alt={product.name}
        />
      </ProductImage>
      <Divider />
      <ProductDetails>
        <h5 style={{ marginTop: 8 }}>{product.name}</h5>
        {isPacoMartinez && (
          <h3
            style={{
              marginTop: 0,
              fontSize: 22,
              fontWeight: 400,
              ...(product.precio_especial > 0 &&
              product.precio_especial < product.amount
                ? estiloPrecioEspecial
                : null),
            }}
          >
            {product.precio_especial > 0 &&
            product.precio_especial < product.amount ? (
              <span>
                <span
                  style={{
                    textDecoration: 'line-through',
                    marginRight: '0.5rem',
                    color: 'gray',
                  }}
                >
                  {new Intl.NumberFormat('es-Es', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(parseFloat(product.amount))}
                </span>

                {new Intl.NumberFormat('es-Es', {
                  style: 'currency',
                  currency: 'EUR',
                }).format(parseFloat(product.precio_especial))}
              </span>
            ) : (
              new Intl.NumberFormat('es-Es', {
                style: 'currency',
                currency: 'EUR',
              }).format(parseFloat(product.amount))
            )}
          </h3>
        )}
        {!isPacoMartinez && (
          <h3
            style={{
              marginTop: 0,
              fontSize: 22,
              fontWeight: 400,
            }}
          >
            {new Intl.NumberFormat('es-Es', {
              style: 'currency',
              currency: 'EUR',
            }).format(product.price.amount)}
          </h3>
        )}
      </ProductDetails>
    </ProductWrapper>
  );
}

ProductItem.propTypes = {
  product: PropTypes.object,
  index: PropTypes.number,
  array: PropTypes.array,
  openProductDialog: PropTypes.func,
};

export default ProductItem;
