import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  removeProduct,
  incrementProductUnits,
  decrementProductUnits,
} from 'modules/cesta';

import {
  ListItemText,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import AddUnitsIcon from '@material-ui/icons/AddCircleOutline';
import RemoveUnitsIcon from '@material-ui/icons/RemoveCircleOutline';
import TrashIcon from '@material-ui/icons/DeleteOutlined';

import { useStyles } from './styles';

function Cesta({
  products,
  incrementProductUnits,
  decrementProductUnits,
  removeProduct,
}) {
  const classes = useStyles();

  return products.length > 0 ? (
    <TableBody className={classes.tableBody}>
      {products.map(product => (
        <TableRow key={product.id} className={classes.tableRow}>
          <TableCell className={classes.tableCell} style={{ width: 84 }}>
            <img
              src={
                product.image.indexOf('http') === 0
                  ? product.image
                  : `https://totem.bigbenconnected.es/${product.image}`
              }
              alt={product.name}
              style={{
                display: 'block',
                margin: '0 auto',
                maxWidth: 60,
                maxHeight: 60,
              }}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <ListItemText
              primary={product.name}
              secondary={product.descripcion}
              primaryTypographyProps={{
                variant: 'subtitle2',
              }}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <ListItemText
              primary={
                <div
                  className="d-flex align-items-center"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  <IconButton
                    size="small"
                    onClick={() => decrementProductUnits(product.id)}
                  >
                    <RemoveUnitsIcon fontSize="small" />
                  </IconButton>
                  {product.amount}
                  <IconButton
                    size="small"
                    onClick={() => incrementProductUnits(product.id)}
                  >
                    <AddUnitsIcon fontSize="small" />
                  </IconButton>
                </div>
              }
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <ListItemText
              primary={
                <div style={{ whiteSpace: 'nowrap' }}>
                  {new Intl.NumberFormat('es-Es', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(product.amount * product.price.amount)}
                </div>
              }
              primaryTypographyProps={{
                variant: 'subtitle1',
              }}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <ListItemText
              primary={
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => removeProduct(product.id)}
                >
                  <TrashIcon fontSize="small" />
                </IconButton>
              }
            />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  ) : null;
}

Cesta.propTypes = {
  products: PropTypes.array.isRequired,
  removeProduct: PropTypes.func.isRequired,
  incrementProductUnits: PropTypes.func.isRequired,
  decrementProductUnits: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      removeProduct,
      incrementProductUnits,
      decrementProductUnits,
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(Cesta);
