import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Grow } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import Cesta from 'components/cesta';
import {
  Nav,
  Menu,
  MenuItem,
  MenuLink,
  MenuIcon,
  ProductsNumber,
  ShakeContainer,
} from './styles';

function Header({ location, products, isAddingProduct }) {
  const cestaMenuRef = useRef(null);
  const [openCesta, setOpenCesta] = useState(false);

  const productUnits = products.reduce(
    (a, b) => a + (isNaN(b.amount) ? 0 : b.amount),
    0,
  );

  return (
    <header style={{ paddingTop: 48 }}>
      <Nav>
        <div className="container">
          <Menu>
            {/* <MenuItem>
              <MenuLink to="/" exact style={{ opacity: 1 }}>
                <span className="iconGame brand">GAME</span>
              </MenuLink>
            </MenuItem> */}
            <MenuItem>
              <MenuLink
                to="/accesorios-telefonia"
                isActive={(match, location) =>
                  location.pathname === '/' || match !== null
                }
              >
                Accesorios telefonía
              </MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/hogar-conectado">Hogar conectado</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/deportes-salud">Deportes y salud</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/gaming">Gaming</MenuLink>
            </MenuItem>
            <MenuItem>
              <MenuLink to="/paco-martinez">Paco Martinez</MenuLink>
            </MenuItem>
            <MenuItem ref={cestaMenuRef}>
              <MenuIcon type="button" onClick={() => setOpenCesta(true)}>
                <Grow
                  in={products.length > 0}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(products.length > 0 ? { timeout: 150 } : {})}
                >
                  <ProductsNumber>{productUnits}</ProductsNumber>
                </Grow>
                <ShakeContainer shake={isAddingProduct}>
                  <ShoppingCartIcon color="inherit" />
                </ShakeContainer>
              </MenuIcon>
              <Cesta
                open={openCesta}
                onClose={() => setOpenCesta(false)}
                anchorEl={cestaMenuRef}
                products={products}
              />
            </MenuItem>
          </Menu>
        </div>
      </Nav>
    </header>
  );
}

Header.propTypes = {
  products: PropTypes.array.isRequired,
  isAddingProduct: PropTypes.bool,
};

const mapStateToProps = ({ cesta }) => ({
  products: cesta.products,
  isAddingProduct: cesta.isAddingProduct,
});

export default connect(mapStateToProps)(Header);
