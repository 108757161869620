import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addProduct } from 'modules/cesta';
import request from 'utils/request';

import { Skeleton } from '@material-ui/lab';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Slide,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddUnitsIcon from '@material-ui/icons/AddCircleOutline';
import RemoveUnitsIcon from '@material-ui/icons/RemoveCircleOutline';
import ArrowPrevIcon from '@material-ui/icons/ChevronLeft';
import ArrowNextIcon from '@material-ui/icons/ChevronRight';
import CartIcon from '@material-ui/icons/ShoppingCartOutlined';
import ReactMarkdown from 'react-markdown';

import {
  useStyles,
  ImageContainer,
  ProductTitle,
  ProductCard,
  AddButton,
  UnitsCaption,
  Wrapper,
  ProductWrapper,
  ProductImage,
  ProductDetails,
  DialogNav,
  DialogNavItem,
  DialogNavArrow,
} from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProductDialog({
  open,
  onClose,
  product,
  openNextProduct,
  openPrevProduct,
  hasNextProduct,
  hasPrevProduct,
  openProductDialog,
  addProduct,
  isPacoMartinez = false,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [units, setUnits] = useState(1);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loadingSimilarProducts, setLoadingSimilarProducts] = useState(false);
  const classes = useStyles();

  const handleKeyPress = ({ key }) => {
    if (key === 'ArrowRight' && hasNextProduct) {
      openNextProduct();
    }
    if (key === 'ArrowLeft' && hasPrevProduct) {
      openPrevProduct();
    }
  };

  useEffect(() => {
    if (open && product) {
      document.addEventListener('keydown', handleKeyPress);
      setUnits(1);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [open]);

  useEffect(() => {
    if (product) {
      setLoadingSimilarProducts(true);
      request(
        `https://api.bigbenespana.es/api/totem/products/${product.id}/similar?limit=3`,
      )
        .then(data => {
          setSimilarProducts(data);
          setLoadingSimilarProducts(false);
        })
        .catch(error => {
          setLoadingSimilarProducts(false);
          console.log(error);
        });
    }
  }, [product]);

  const handleAddProduct = product => {
    addProduct({ ...product, amount: units });
    setUnits(1);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      scroll="body"
      TransitionComponent={Transition}
      disableEscapeKeyDown
      classes={{
        paperScrollBody: classes.paperScrollBody,
      }}
    >
      <DialogNav>
        {hasPrevProduct && (
          <DialogNavItem>
            <DialogNavArrow type="button" onClick={openPrevProduct}>
              <ArrowPrevIcon fontSize="large" style={{ color: '#fff' }} />
            </DialogNavArrow>
          </DialogNavItem>
        )}
        {hasNextProduct && (
          <DialogNavItem inverted>
            <DialogNavArrow type="button" onClick={openNextProduct}>
              <ArrowNextIcon fontSize="large" style={{ color: '#fff' }} />
            </DialogNavArrow>
          </DialogNavItem>
        )}
      </DialogNav>
      <DialogTitle style={{ padding: '16px 16px 8px' }}>
        <div className="d-flex align-items-center justify-content-between">
          <div />
          <IconButton
            type="button"
            size="small"
            onClick={onClose}
            title="Cerrar"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ padding: '8px 24px' }}>
        {product && (
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-start">
              <ImageContainer>
                <img
                  src={
                    product.image.indexOf('http') === 0
                      ? product.image
                      : isPacoMartinez
                      ? `./assets/pacomartinez/${product.image}`
                      : `https://totem.bigbenconnected.es/${product.image}`
                  }
                  draggable={false}
                  alt={product.name}
                />
              </ImageContainer>
              <ProductCard>
                <ProductTitle>
                  <h2>{product.name}</h2>
                  {!isPacoMartinez && (
                    <>
                      <p>{product.description.body}</p>
                      <ReactMarkdown source={product.description.technical} />
                    </>
                  )}
                </ProductTitle>
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className="d-flex flex-column justify-content-between align-items-center align-self-stretch flex-grow-1"
                      style={{ padding: 6, flexBasis: '50%' }}
                    >
                      <UnitsCaption>Precio</UnitsCaption>
                      <h2
                        className="text-center"
                        style={{ padding: 16, margin: 0 }}
                      >
                        {!isPacoMartinez &&
                          new Intl.NumberFormat('es-Es', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(product.price.amount)}
                        {isPacoMartinez &&
                          new Intl.NumberFormat('es-Es', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(parseFloat(product.amount))}
                      </h2>
                    </div>
                    <div
                      className="d-flex flex-column justify-content-between align-items-center align-self-stretch flex-grow-1"
                      style={{
                        padding: 6,
                        borderLeft: '1px solid #dedede',
                        flexBasis: '50%',
                      }}
                    >
                      <UnitsCaption>Unidades</UnitsCaption>
                      <div
                        className="d-flex justify-content-between align-items-center"
                        style={{ padding: 16 }}
                      >
                        <IconButton
                          size="small"
                          onClick={() =>
                            setUnits(prevState =>
                              prevState > 1 ? prevState - 1 : 1,
                            )
                          }
                        >
                          <RemoveUnitsIcon style={{ color: '#0070c9' }} />
                        </IconButton>
                        <div
                          style={{
                            marginLeft: 8,
                            marginRight: 8,
                            fontSize: '2rem',
                            lineHeight: 1.2,
                          }}
                        >
                          {units}
                        </div>
                        <IconButton
                          size="small"
                          onClick={() => setUnits(prevState => prevState + 1)}
                        >
                          <AddUnitsIcon style={{ color: '#0070c9' }} />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 24 }}>
                    <AddButton
                      type="button"
                      onClick={() => handleAddProduct(product)}
                    >
                      <div style={{ paddingLeft: 8, paddingRight: 8 }}>
                        <CartIcon />
                      </div>
                      <span>
                        <span>Añadir al carrito</span>
                      </span>
                    </AddButton>
                  </div>
                </div>
              </ProductCard>
            </div>
            <h4
              className="text-center"
              style={{
                marginTop: 32,
                marginBottom: 32,
                paddingTop: 32,
              }}
            >
              También te puede interesar
            </h4>
            <Wrapper>
              {(loadingSimilarProducts
                ? Array.from(new Array(3))
                : similarProducts
              ).map((item, index) => (
                <ProductWrapper
                  key={loadingSimilarProducts ? index : item.id}
                  onClick={
                    loadingSimilarProducts
                      ? null
                      : () => openProductDialog(item)
                  }
                >
                  <ProductImage>
                    {loadingSimilarProducts ? (
                      <Skeleton variant="rect" width="100%" height="100%" />
                    ) : (
                      <img
                        src={
                          item.image.indexOf('http') === 0
                            ? item.image
                            : `https://totem.bigbenconnected.es/${item.image}`
                        }
                        draggable={false}
                        alt={item.name}
                      />
                    )}
                  </ProductImage>
                  <ProductDetails>
                    {loadingSimilarProducts ? (
                      <React.Fragment>
                        <Skeleton width="100%" style={{ margin: '8px 0' }} />
                        <Skeleton width="60%" style={{ margin: '0 0 8px' }} />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <h5 style={{ marginTop: 8 }}>{item.name}</h5>
                        <h3
                          style={{
                            marginTop: 0,
                            fontSize: 22,
                            fontWeight: 400,
                          }}
                        >
                          {new Intl.NumberFormat('es-Es', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(item.price.amount)}
                        </h3>
                      </React.Fragment>
                    )}
                  </ProductDetails>
                </ProductWrapper>
              ))}
            </Wrapper>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

ProductDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.object,
  openProductDialog: PropTypes.func.isRequired,
  openNextProduct: PropTypes.func.isRequired,
  openPrevProduct: PropTypes.func.isRequired,
  hasNextProduct: PropTypes.bool,
  hasPrevProduct: PropTypes.bool,
  addProduct: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addProduct }, dispatch);

export default connect(
  null,
  mapDispatchToProps,
)(ProductDialog);
