import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { sakeAnimation } from 'utils/animations';

export const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #313131;
  z-index: 30;
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
`;

export const MenuItem = styled.li`
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
`;

export const MenuLink = styled(NavLink)`
  display: inline-block;
  padding: 0 10px;
  color: #fff;
  font-size: 14px;
  line-height: 3.44286;
  font-weight: 200;
  text-decoration: none;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-tap-highlight-color: transparent;
  outline-offset: -7px;

  &:hover,
  &.active {
    color: #fff;
    opacity: 0.65;
  }
`;

export const MenuIcon = styled.button`
  display: inline-block;
  position: relative;
  padding: 0 10px;
  background: none;
  border: 0;
  color: #fff;
  line-height: 2.75286;
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-tap-highlight-color: transparent;
  outline-offset: -7px;
  cursor: pointer;

  &:hover,
  &.active {
    opacity: 0.65;
  }
`;

export const ProductsNumber = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2px;
  right: 0;
  padding: 3px;
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  border: 2px solid #313131;
  border-radius: 50%;
  font-size: 10px;
  vertical-align: top;
  z-index: 1;
  user-select: none;
`;

export const ShakeContainer = styled.div`
  animation-name: ${({ shake }) => (shake ? sakeAnimation : 'unset')};
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  transform: translate3d(0, 0, 0);
  transform-origin: center center;
  backface-visibility: hidden;
`;
