import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const useStyles = makeStyles(theme => ({
  input: {
    fontSize: 24,
    fontWeight: 300,
  },
}));

export const CarouselItem = styled.div`
  min-height: 440px;
  background-color: #fff;
`;

export const CarouselCaptionLeft = styled.div`
  top: 50% !important;
  left: auto !important;
  right: calc(50% + 32px) !important;
  bottom: auto !important;
  text-align: right !important;

  & h2 {
    display: inline-block;
    color: #333;
    font-size: 48px;
    line-height: 1.04167;
    text-align: left;
  }

  & a {
    display: block;
    font-size: 22px;
    font-weight: 200;
    line-height: 1.45455;
    letter-spacing: 0.083px;
  }
`;

export const CarouselImageRight = styled.img`
  position: absolute;
  top: 40px;
  left: calc(50% + 32px);
  height: 640px;
  user-select: none;
`;

export const SearchTitle = styled.h3`
  margin: 32px 0 0 0;
  padding-bottom: 26px;
  font-weight: normal;
`;

export const SearchInput = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 44px;
  padding: 7px 16px;
  width: 65%;
  background-color: #fafafa;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  color: #333;
  font-size: 24px;
  line-height: 1.20849;
`;

export const CategoriesContainer = styled.div`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  top: ${({ fixed }) => (fixed ? '48px' : 0)};
  left: 0;
  right: 0;
  margin-top: 0;
  background-color: ${({ fixed }) => (fixed ? '#fff' : 'none')};
  box-shadow: ${({ fixed }) =>
    fixed
      ? '0px 1px 8px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px 0px rgba(0, 0, 0, 0.04), 0px 3px 3px -2px rgba(0, 0, 0, 0.04)'
      : 'none'};
  z-index: ${({ fixed }) => (fixed ? 29 : 'unset')};
  transition: background-color ${({ fixed }) => (fixed ? '150ms' : 0)} ease-out;
`;
