export const orangeShops = [
  {
    id: 1,
    name: 'Tienda Orange Zaragoza',
    via: 'Plaza',
    address: 'España',
    number: 4,
    city: 'Zaragoza',
    cp: '50001',
  },
  {
    id: 2,
    name: 'Tienda Orange San Sebastián',
    via: 'Calle',
    address: 'Loyola',
    number: 10,
    city: 'Donostia - San Sebastián',
    cp: '20005',
  },
  {
    id: 3,
    name: 'Tienda Orange Málaga',
    via: 'Calle',
    address: 'Nueva',
    number: 32,
    city: 'Málaga',
    cp: '29005',
  },
  {
    id: 4,
    name: 'Tienda Orange La Coruña',
    via: 'Plaza',
    address: 'Pontevedra',
    number: 9,
    city: 'La Coruña',
    cp: '15004',
  },
  {
    id: 5,
    name: 'Tienda Orange Córdoba',
    via: 'Calle',
    address: 'Jose Cruz Conde',
    number: 6,
    city: 'Córdoba',
    cp: '14002',
  },
  {
    id: 6,
    name: 'Tienda Orange Bilbao',
    via: 'Calle',
    address: 'Iparraguirre',
    number: 37,
    city: 'Bilbao',
    cp: '48011',
  },
  {
    id: 7,
    name: 'Tienda Orange Alicante',
    via: 'Avenida',
    address: 'de Maisonnave',
    number: 38,
    city: 'Alicante',
    cp: '03003',
  },
];

export const shops = [
  {
    id: '002',
    alias: 'ATOCHA',
    name: 'Madrid - Atocha',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'Pª SANTA Mª DE LA CABEZA Nº1',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28045',
    phone: '914681674',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '006',
    alias: 'TORREJON',
    name: 'Torrejon - Parque Corredor',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CTR.TORREJON-ALJAVIR,KM 5, P.CORREDOR-C/OCIO,L 53',
    city: 'TORREJON DE ARDOZ',
    province: 'MADRID',
    cp: '28864',
    phone: '916568001',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '007',
    alias: 'SAN_BLAS',
    name: 'Madrid - Rosas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LAS ROSAS, AV.GUADALAJARA Nº2, LOCAL 13',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28032',
    phone: '917754180',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '009',
    alias: 'PRECIADOS',
    name: 'Madrid - Preciados',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CL. PRECIADOS Nº34',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28013',
    phone: '918309408',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '010',
    alias: 'DIAGONAL_MAR',
    name: 'Barcelona - Diagonal Mar',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. DIAGONAL MAR,AV.. DIAGONAL 3, LOCAL 1480',
    city: 'BARCELONA',
    province: 'BARCELONA',
    cp: '08019',
    phone: '933560539',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '011',
    alias: 'OVIEDO',
    name: 'Oviedo - Prados',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. FERNANDEZ LADREDA, S/N-C.C.LOS PRADOS,LOCAL 12',
    city: 'OVIEDO',
    province: 'ASTURIAS',
    cp: '33011',
    phone: '985110116',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '012',
    alias: 'BONAIRE',
    name: 'Valencia - Bonaire',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BONAIRE, AUTOVIA A-3, KM. 345, LOCAL B-352',
    city: 'ALDAIA',
    province: 'VALENCIA',
    cp: '46960',
    phone: '961900394',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '013',
    alias: 'CADIZ_SF',
    name: 'San Fernando - Bahia Sur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BAHIA SUR LOCAL C-17/18, C/CAÑO HERRERA S/N',
    city: 'SAN FERNANDO',
    province: 'CADIZ',
    cp: '11100',
    phone: '956550784',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '014',
    alias: 'AVILA',
    name: 'Avila - Boulevar',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL BULEVARAV. JUAN CARLOS I Nº 45 LOCAL L-51',
    city: 'AVILA',
    province: 'AVILA',
    cp: '05004',
    phone: '920219836',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '015',
    alias: 'ALCORCON',
    name: 'Alcorcon - Tres Aguas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. TRES AGUAS, AV DE AMERICA Nº 7-9 LOCAL 158',
    city: 'ALCORCON',
    province: 'MADRID',
    cp: '28922',
    phone: '918194255',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '016',
    alias: 'LHOSPITALET',
    name: 'Hospitalet - Gran Via',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. GRAN VÍA 2, AV. DE LA GRAN VIA 75, LOCAL B-11',
    city: 'L´HOSPITALET DE LLOBREGAT',
    province: 'BARCELONA',
    cp: '08908',
    phone: '932591380',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '017',
    alias: 'GIJON',
    name: 'Gijon - Fresnos',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LOS FRENOS LOCAL B-63, C/RIO DE ORO, 3',
    city: 'GIJON',
    province: 'ASTURIAS',
    cp: '33209',
    phone: '985384608',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '018',
    alias: 'FERROL',
    name: 'El Ferrol - Dolce Vita Odeon',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. DOLCE VITA ODEON, POL INDUSTRIAL A GANDARA, C/ CATALUÑA S/N, LOCAL 21',
    city: 'NARON',
    province: 'A CORUÑA',
    cp: '15578',
    phone: '981380662',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '019',
    alias: 'CUENCA',
    name: 'Cuenca - Mirador',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL MIRADOR LOCAL 60 AVDA DEL MEDITERRANEO S/N',
    city: 'CUENCA',
    province: 'CUENCA',
    cp: '16004',
    phone: '969232461',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '020',
    alias: 'PALENCIA',
    name: 'Palencia - Huertas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LAS HUERTAS AV. DE MADRID Nº 37, LOCAL 47',
    city: 'PALENCIA',
    province: 'PALENCIA',
    cp: '34004',
    phone: '979727748',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '026',
    alias: 'BADALONA_OLOF',
    name: 'Badalona - Montigala',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MONTIGALÀ, PASEIG OLOF PALME, 28-36,LOCAL 25-26',
    city: 'BADALONA',
    province: 'BARCELONA',
    cp: '08917',
    phone: '934653240',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '028',
    alias: 'PAU_CLARIS',
    name: 'Barcelona - Pau Claris',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CARRER DE PAU CLARIS 97',
    city: 'BARCELONA',
    province: 'BARCELONA',
    cp: '08009',
    phone: '934127221',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '031',
    alias: 'SANTS',
    name: 'Barcelona - Sants',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CARRER DE SANTS 17',
    city: 'BARCELONA',
    province: 'BARCELONA',
    cp: '08014',
    phone: '933327045',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '037',
    alias: 'CORDOBA',
    name: 'Cordoba - Zoco',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C.ZOCO CORDOBA, LOCAL 15-A, C/JOSE MARTORELL, S/N',
    city: 'CORDOBA',
    province: 'CORDOBA',
    cp: '14005',
    phone: '957622390',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '041',
    alias: 'GRANADA',
    name: 'Granada - Recogidas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. RECOGIDAS Nº39',
    city: 'GRANADA',
    province: 'GRANADA',
    cp: '18005',
    phone: '958264518',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '056',
    alias: 'SEVILLA_ARCOS',
    name: 'Sevilla - Arcos',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LOS ARCOS, AVDA. ANDALUCIA S/N',
    city: 'SEVILLA',
    province: 'SEVILLA',
    cp: '41007',
    phone: '954518695',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '058',
    alias: 'VALENCIA_SALER',
    name: 'Valencia - Saler',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL SALER LOCAL 32, A - AVDDEL PROFESOR LOPEZ PIÑERO 16',
    city: 'VALENCIA',
    province: 'VALENCIA',
    cp: '46013',
    phone: '963355873',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '059',
    alias: 'VALENCIA_VICENT',
    name: 'Valencia - San Vicente',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ SAN VICENTE MARTIR, Nº 104',
    city: 'VALENCIA',
    province: 'VALENCIA',
    cp: '46002',
    phone: '963940511',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '063',
    alias: 'ZARAGOZA',
    name: 'Zaragoza - Cadiz',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CADIZ, 14, LOCAL',
    city: 'ZARAGOZA',
    province: 'ZARAGOZA',
    cp: '50004',
    phone: '976796182',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '074',
    alias: 'FIGUERAS',
    name: 'Figueres - Moreria',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CARRER DE LA MORERIA 10',
    city: 'FIGUERES',
    province: 'GIRONA',
    cp: '17600',
    phone: '972673135',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '088',
    alias: 'BARBERA',
    name: 'Barbera Valles - Baricentro',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BARICENTRO, CTRA. NACIONAL 150, KM 6, LOCAL A31-A32',
    city: 'BARBERÀ DEL VALLES',
    province: 'BARCELONA',
    cp: '08210',
    phone: '937296840',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '091',
    alias: 'MAQUINISTA',
    name: 'Barcelona - Maquinista',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA MAQUINISTA, C. DE POTOSÍ DE POTOSI 2, LOCAL B-31',
    city: 'BARCELONA',
    province: 'BARCELONA',
    cp: '08030',
    phone: '933608175',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '098',
    alias: 'VILLALBA',
    name: 'Villalba - Planetocio',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. JUAN CARLOS I, Nº 46, C.C.PLANETOCIO,L-58/59',
    city: 'COLLADO VILLALBA',
    province: 'MADRID',
    cp: '28400',
    phone: '911030154',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '106',
    alias: 'CIUDAD_REAL',
    name: 'Ciudad Real - Parque',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. DE EUROPA 45, LOCAL 001 (C.C. EL PARQUE EROSKI)',
    city: 'CIUDAD REAL',
    province: 'CIUDAD REAL',
    cp: '13005',
    phone: '926254484',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '108',
    alias: 'SANTIAGO_AREA',
    name: 'Santiago - Area Central',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. AREA CENTRAL - LOCAL 17 G',
    city: 'SANTIAGO DE COMPOSTELA',
    province: 'A CORUÑA',
    cp: '15707',
    phone: '981588608',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '109',
    alias: 'ZARAGOZA_AUGUST',
    name: 'Zaragoza - Augusta',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA DE NAVARRA 180, I-50 (C.C. AUGUSTA)',
    city: 'ZARAGOZA',
    province: 'ZARAGOZA',
    cp: '50011',
    phone: '976327252',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '110',
    alias: 'MALAGA_INGENIO',
    name: 'Velez Malaga - Ingenio',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'CARRETERA TORRE DEL MAR-- VELEZ MALAGA. C.C. EL INGENIOLOCAL B-45',
    city: 'TORRE DEL MAR',
    province: 'MÁLAGA',
    cp: '29700',
    phone: '952540587',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '111',
    alias: 'ALMERIA_COPO',
    name: 'El Ejido - Copo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CARRETERA DE ALMERIMAR S/N. C.C. Y DE OCIO COPO L-106-107',
    city: 'EL EJIDO',
    province: 'ALMERIA',
    cp: '04700',
    phone: '950488277',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '113',
    alias: 'CORDOBA_SIERRA',
    name: 'Cordoba - Sierra',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CL. POETA EMILIO PRADOS, S/N (C.C. LA SIERRA)',
    city: 'CORDOBA',
    province: 'CORDOBA',
    cp: '14012',
    phone: '957281036',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '114',
    alias: 'SANTANDER',
    name: 'Santander - Valle Real',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'ALDAY S/N C.C. VALLE REAL LOCAL B4',
    city: 'MALIAÑO',
    province: 'CANTABRIA',
    cp: '39600',
    phone: '942260187',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '119',
    alias: 'ZARAGOZA_G_CASA',
    name: 'Zaragoza - Gran Casa',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ MARIA ZAMBRANO 35 C.C. GRAN CASA LOCAL-069',
    city: 'ZARAGOZA',
    province: 'ZARAGOZA',
    cp: '50018',
    phone: '976740456',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '121',
    alias: 'PLAZA_NORTE2',
    name: 'San Sebastián - Plaza Norte 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. PLAZA NORTE 2 - AVDA. JUNCAL, SN LOCAL B-122 CTRA N-1 (BURGOS), SALIDA 19',
    city: 'SAN SEBASTIAN DE LOS REYES',
    province: 'MADRID',
    cp: '28700',
    phone: '916591225',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '122',
    alias: 'TALAVERA_AZUL',
    name: 'Talavera - Alfares',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. AZUL L- N50,AVDA. DED MADRID, S/N',
    city: 'TALAVERA DE LA REINA',
    province: 'TOLEDO',
    cp: '45600',
    phone: '925702448',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '124',
    alias: 'PRINCIPE_PIO',
    name: 'Madrid - Principe Pio',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'PASEO DE LA FLORIDA Nº 2 C.C. PRINCIPE PIO LOCAL G28',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28008',
    phone: '915484576',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '125',
    alias: 'LEGANES_P_SUR',
    name: 'Leganes - Parque Sur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA GRAN BRETAÑA S/NC.C.PARQUE SUR.LOCAL 2',
    city: 'LEGANES',
    province: 'MADRID',
    cp: '28916',
    phone: '916876282',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '127',
    alias: 'FUENLABRADA',
    name: 'Fuenlabrada - Plaza Estacion',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ HUNGRIA, ITALIA, SUIZA (SITA CON LAS 3) LOCAL Nº 4',
    city: 'FUENLABRADA',
    province: 'MADRID',
    cp: '28943',
    phone: '916086013',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '129',
    alias: 'SANT_CUGAT',
    name: 'Sant Cugat - Sant Cugat',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. SANT CUGAT, AV. VIA AUGUSTA 2-14, LOCAL 35',
    city: 'SANT CUGAT DEL VALLÉS',
    province: 'BARCELONA',
    cp: '08190',
    phone: '935891664',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '130',
    alias: 'PARQUE_PRINCIPA',
    name: 'Oviedo - Parque Principado',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. PARQUE PRICIPADO LOCAL 11 BIS AUTOVIA A-66, KM. 4,5 ACCESO SANTANDER',
    city: 'PAREDES (LUGONES)',
    province: 'ASTURIAS',
    cp: '33429',
    phone: '985262199',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '135',
    alias: 'MADRID_PLENILUN',
    name: 'Madrid - Plenilunio',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PLENILUNIO, LOCLA L-018, GLORIETA EISENHOWER S/N',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28022',
    phone: '917475997',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '136',
    alias: 'PINTO_EBOLI',
    name: 'Pinto - Plaza Eboli',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CAMINO DE SAN ANTÓN, S/NC.C. PLAZA ÉBOLI L-020-021',
    city: 'PINTO',
    province: 'MADRID',
    cp: '28320',
    phone: '916706885',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '137',
    alias: 'CASTELLON_SALER',
    name: 'Castellon - Salera',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA SALERA, LOCAL C-48, AVDA ENRIQUE GIMENO S/N',
    city: 'CASTELLON DE LA PLANA',
    province: 'CASTELLON',
    cp: '12006',
    phone: '964293535',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '138',
    alias: 'GAVA_BARNASUD',
    name: 'Gava - Barnasud',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BARNASUD, C. DEL PROGRÉS 69, LOCAL 040-041',
    city: 'GAVÀ',
    province: 'BARCELONA',
    cp: '08850',
    phone: '936389996',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '140',
    alias: 'GIRONA_ESPAI',
    name: 'Girona - Espai Girones',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ESPAI GIRONÉS, CAMÍ DELS CARLINS 10, LOCAL 1.49B',
    city: 'GIRONA',
    province: 'GIRONA',
    cp: '17190',
    phone: '972439074',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '141',
    alias: 'LUGO_AS_TERMAS',
    name: 'Lugo - Termas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. AS TERMAS, L 67,AVDA. INFANTA ELENA DUQUESA DE LUGO',
    city: 'LUGO',
    province: 'LUGO',
    cp: '27003',
    phone: '982206034',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '142',
    alias: 'MURCIA_THADER',
    name: 'Murcia - Thader',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. THADER, AVDA. JUAN CARLOS I, S/N, LOCAL B-18',
    city: 'MURCIA',
    province: 'MURCIA',
    cp: '30006',
    phone: '968385929',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '143',
    alias: 'ALBACETE_IMAGIN',
    name: 'Albacete - Imaginalia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. IMAGINALIA, L-51, AVDA. DE LA ILUSTRACIÓN, 51',
    city: 'ALBACETE',
    province: 'ALBACETE',
    cp: '02006',
    phone: '967246940',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '144',
    alias: 'VALENCIA_N_CENT',
    name: 'Valencia - Nuevo Centro',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AV. PIO XII 2-4-6 C. C. NUEVO CENTRO (LOCAL 40)',
    city: 'VALENCIA',
    province: 'VALENCIA',
    cp: '46009',
    phone: '963461180',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '145',
    alias: 'GANDIA_LA_VITAL',
    name: 'Gandia - Vital',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA VITAL, LOCAL B-19, AVINGUDA DE LA VITAL, S/N',
    city: 'GANDIA',
    province: 'VALENCIA',
    cp: '46700',
    phone: '962881029',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '146',
    alias: 'JAEN_LA_LOMA',
    name: 'Jaen - Loma',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA LOMA, LOCAL 19B CARRETERA BAILÉN-MOTRIL 323, KM. 37,7',
    city: 'JAEN',
    province: 'JAEN',
    cp: '23009',
    phone: '953254723',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '147',
    alias: 'GETAFE_NASSICA',
    name: 'Getafe - Nassica',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. NASSICA LOCAL B-001 BB, AUTOVÍA DEL SUR (A-4) SALIDA 17, AVDA. RIO GUADALQUIVIR S/N',
    city: 'GETAFE',
    province: 'MADRID',
    cp: '28906',
    phone: '916918589',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '148',
    alias: 'SANJAVIER2MARES',
    name: 'San Javier - Dos Mares',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. DOS MARES, LOCAL B-6A CARRETERA N-332, CRUCE EL MIRADOR',
    city: 'SAN JAVIER',
    province: 'MURCIA',
    cp: '30730',
    phone: '968193499',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '149',
    alias: 'XIRIVELLA_TURIA',
    name: 'Valencia - Gran Turia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. GRAN TURIA, LOCAL 147C PLAZA DE EUROPA, S/N',
    city: 'XIRIVELLA',
    province: 'VALENCIA',
    cp: '46950',
    phone: '963138281',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '150',
    alias: 'ALCALA_LADEHESA',
    name: 'Alcala - Dehesa',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA DEHESA LOCAL 55-56 AUTOVÍA MADRID-BARCELONA, KM. 34',
    city: 'ALCALA DE HENARES',
    province: 'MADRID',
    cp: '28805',
    phone: '918776957',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '151',
    alias: 'CACERES_RUTA_PL',
    name: 'Caceres - Ruta Plata',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. RUTA DE LA PLATA, LOCAL Nº 28, C/ LONDRES, Nº 1',
    city: 'CACERES',
    province: 'CACERES',
    cp: '10005',
    phone: '927233659',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '152',
    alias: 'ALICANTE_PLZ_M2',
    name: 'Alicante - Plaza Mar 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PLAZA MAR 2, LOCAL B-046, AVENIDA DE DENIA, S/N',
    city: 'ALICANTE',
    province: 'ALICANTE',
    cp: '03016',
    phone: '965220495',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '153',
    alias: 'LOGROÑO_P_RIOJA',
    name: 'Logroño - Parque Rioja',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PARQUE RIOJA, LOCAL 64, CAMINO DE LAS TEJERAS, S/N',
    city: 'LOGROÑO',
    province: 'LA RIOJA',
    cp: '26006',
    phone: '941519662',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '154',
    alias: 'SEVILLA_ALCORES',
    name: 'Sevilla - Alcores',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. LOS ALCORES, L-10CRTA. A92 SEVILLA-MÁLAGA KM.8,800. URB.CERRO CABEZA HERMONA',
    city: 'ALCALÁ DE GUADAIRA',
    province: 'SEVILLA',
    cp: '41500',
    phone: '954102570',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '155',
    alias: 'VITORIA_BOULEVA',
    name: 'Vitoria - Boulevard Vitoria',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BOULEVARD DE VITORIA. L-30,ZARAMAGA, 1',
    city: 'VITORIA',
    province: 'ALAVA',
    cp: '01013',
    phone: '945206273',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '156',
    alias: 'MALAGA_PZ_MAYOR',
    name: 'Malaga - Plaza Mayor',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C.PLAZA MAYOR, L-51 C/ ALFONSO  PONCE DE LEÓN, 3',
    city: 'MALAGA',
    province: 'MALAGA',
    cp: '29046',
    phone: '951763098',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '157',
    alias: 'CARCAIXENT_RIBE',
    name: 'Valencia - Ribera Xuquer',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. BOTICARIO BODI S/N, C.C. RIBERA DEL XUNQUER, LOCAL B-72',
    city: 'CARCAIXENT',
    province: 'VALENCIA',
    cp: '46740',
    phone: '962433386',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '158',
    alias: 'MAIRENA_METROMA',
    name: 'Mairena - Metromar',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. METROMAR, LOCAL LB-27, AVENIDA DE LOS DESCUBRIMIENTOS S/N',
    city: 'MAIRENA DE ALJARAFE',
    province: 'SEVILLA',
    cp: '41927',
    phone: '954180025',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '159',
    alias: 'CASTILLEJA_AIRE',
    name: 'Sevilla - Airesur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'CTRA CASTILLEJA DE LA CUESTA-TOMERES-FINCA EL VALERO C.C. AIRE SUR, LOCAL B33',
    city: 'CASTILLEJA DE LA CUESTA',
    province: 'SEVILLA',
    cp: '41950',
    phone: '954165743',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '161',
    alias: 'CAN_MERIDIANO',
    name: 'Tenerife - Meridiano',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MERIDIANO, C\\ AUREA DÍAZ FLORES S/N',
    city: 'TENERIFE',
    province: 'TENERIFE',
    cp: '38005',
    phone: '922882569',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '162',
    alias: 'CAN_7_PALMA',
    name: 'Las Palmas -7 Palmas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. 7 PALMAS, L-208, C/PINTOR FELO MONZON',
    city: 'LAS PALMAS G.C.',
    province: 'LAS PALMAS G.C.',
    cp: '35013',
    phone: '928428909',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '163',
    alias: 'CAN_CHIL',
    name: 'Las Palmas - Chil',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'PASEO DE CHIL Nº 309',
    city: 'LAS PALMAS G.C.',
    province: 'LAS PALMAS G.C.',
    cp: '35010',
    phone: '928278376',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '165',
    alias: 'CAN_BALLENA',
    name: 'Las Palmas - Ballena',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CTRA. GENERAL NORTE,12 C.C. LA BALLENA,L,1.5.2.',
    city: 'LAS PALMAS G.C.',
    province: 'LAS PALMAS G.C.',
    cp: '35012',
    phone: '928396920',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '166',
    alias: 'CAN_VECINDARIO',
    name: 'Vecindario - Atlantico',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ATLANTICO, LOCAL 29, PLANTA ALTA',
    city: 'VECINDARIO',
    province: 'LAS PALMAS G.C.',
    cp: '35110',
    phone: '928757499',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '167',
    alias: 'CAN_VILLA2',
    name: 'Tenerife - Villa 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA VILLA 2 LOCAL 29 PLANTA BAJA',
    city: 'LA OROTAVA',
    province: 'TENERIFE',
    cp: '38312',
    phone: '922323516',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '168',
    alias: 'MARBELLA_CAÑADA',
    name: 'Marbella - Cañada',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. LA CAÑADA, LOCAL 179,AVDA. DEL MEDITERRÁNEO, S/N, SALIDA OJÉN',
    city: 'MARBELLA',
    province: 'MALAGA',
    cp: '29600',
    phone: '951317492',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '169',
    alias: 'LHOSPITALET_FAR',
    name: 'Hospitalet - Farga',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA FARGA, AV. JOSEP TARRADELLAS S/N, LOCAL P-4',
    city: 'L´HOSPITALET DE LLOBREGAT',
    province: 'BARCELONA',
    cp: '08901',
    phone: '933377869',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '170',
    alias: 'PAMPLONA_PINTOR',
    name: 'Pamplona - Pintor Asarta',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ PINTOR ASARTANº 7',
    city: 'PAMPLONA',
    province: 'NAVARRA',
    cp: '31007',
    phone: '948276208',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '172',
    alias: 'HUARTE_ITAROA',
    name: 'Pamplona - Itaroa',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. INTXAURDIA 5,C.C.ITAROA LOCAL B-24',
    city: 'HUARTE',
    province: 'NAVARRA',
    cp: '31620',
    phone: '948337977',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '176',
    alias: 'MALAGA_LARIOS',
    name: 'Malaga - Larios',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LARIOS L-B.201 AVENIDA DE LA AURORA, 25',
    city: 'MALAGA',
    province: 'MALAGA',
    cp: '29002',
    phone: '952369863',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '177',
    alias: 'MADRID_XANADU_2',
    name: 'Arroyomolino - Xanadu (2º)',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'CRTA. DE EXTREMADURA (N-V), KM 23.5, C.C. MADRID XANADU LOCAL 202',
    city: 'ARROYOMOLINOS',
    province: 'MADRID',
    cp: '28939',
    phone: '910538447',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '178',
    alias: 'MADRID_P_ALUCHE',
    name: 'Madrid - Aluche',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PLAZA ALUCHE, LOCAL B33, AVENIDA DE LOS POBLADOS, Nº 25',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28047',
    phone: '917054695',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '181',
    alias: 'SEGOVIA_LUZ_CAS',
    name: 'Segovia - Luz Castilla',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. LUZ CASTILLA L-B13, CTRA. MADRID-SEGOVIA, S/N, POLG IND. EL CERRO',
    city: 'SEGOVIA',
    province: 'SEGOVIA',
    cp: '40006',
    phone: '921448138',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '182',
    alias: 'VALLADOLID_VSUR',
    name: 'Valladolid - Vallsur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. VALLSUR L-0-43, CAMINO VIEJO DE SIMANCAS S/N.',
    city: 'VALLADOLID',
    province: 'VALLADOLID',
    cp: '47008',
    phone: '983275146',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '183',
    alias: 'MALAGA_ROSALEDA',
    name: 'Malaga - Rosaleda',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ROSALEDA L-20, AVDA. SIMÓN BOLIVAR, S/N',
    city: 'MALAGA',
    province: 'MALAGA',
    cp: '29011',
    phone: '952273571',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '184',
    alias: 'BILBAO_ARTEA',
    name: 'Bilbao - Artea',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ARTEA. L-39 C/BARRIO PERURI Nº13',
    city: 'LEIOA',
    province: 'VIZCAYA',
    cp: '48940',
    phone: '944606891',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '185',
    alias: 'SANLUCAR_DUNAS',
    name: 'Sanlucar - Dunas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. DUNAS, AVDA. EL PUERTO DE SANTA MARÍA, S/N, LOCAL 43-A',
    city: 'SANLUCAR DE BARRAMEDA',
    province: 'CADIZ',
    cp: '11540',
    phone: '956963940',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '186',
    alias: 'MURCIA_CONDOMI',
    name: 'Murcia - Nueva Condomina',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. NUEVA CONDOMINA, LOCALA-46, CRUCE CARRETERA A-7 CON AVENIDAD JUAN CARLOS I, S/N',
    city: 'MURCIA',
    province: 'MURCIA',
    cp: '30008',
    phone: '968813206',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '187',
    alias: 'ARMILLA_NEVADA',
    name: 'Armilla - Nevada Shopping',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. NEVADA SHOPPING, LOCAL 89, C/ HIPÓCRATES, S/N',
    city: 'ARMILLA',
    province: 'GRANADA',
    cp: '18100',
    phone: '958179577',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '188',
    alias: 'MALAGA_VIALIA',
    name: 'Malaga - Vialia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. VIALIA ESTACIÓN DE MÁLAGA, C/ HÉROES DE SOSTOA, Nº 2, LOCAL FO-27',
    city: 'MALAGA',
    province: 'MALAGA',
    cp: '29002',
    phone: '952346480',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '189',
    alias: 'SALAMANCA_TORME',
    name: 'Salamanca - Tormes',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. C. EL TORMES, LOCAL 141-142, AVD/ SALAMANCA S/N',
    city: 'SANTA MARTA DE TORMES',
    province: 'SALAMANCA',
    cp: '37900',
    phone: '923130732',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '190',
    alias: 'LOGROÑO-BERCEO',
    name: 'Logroño - Berceo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BERCEO, LOCAL 159, CALLE LÉRIDA, Nº 1',
    city: 'LOGROÑO',
    province: 'LA RIOJA',
    cp: '26006',
    phone: '941256738',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '191',
    alias: 'BURGOS_MIRADOR',
    name: 'Burgos - Mirador',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL MIRADOR DE BURGOS, LOCAL 47, CRTA SANTANDE',
    city: 'BURGOS',
    province: 'BURGOS',
    cp: '09006',
    phone: '947241592',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '192',
    alias: 'CARTAGENA_ESP_M',
    name: 'Cartagena - Espacio Medite.',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. ESPACIO MEDITERRANEO, LOCAL 1-75, JUNTO POL. IND. CABEZO BAEZA',
    city: 'CARTAGENA',
    province: 'MURCIA',
    cp: '30205',
    phone: '968197244',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '194',
    alias: 'GETAFE_GETAFE_3',
    name: 'Getafe - Getafe III',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. GETAFE III, LOCAL 19, PLAZA DE JUAN CARLOS I, S/N',
    city: 'GETAFE',
    province: 'MADRID',
    cp: '28905',
    phone: '916846256',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '195',
    alias: 'ALCALA_MAGNA',
    name: 'Alcala - Alcala Magna',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCALÁ MAGNA, CUESTA DE TEATINOS, S/NLOCAL 87',
    city: 'ALCALA DE HENARES',
    province: 'MADRID',
    cp: '28801',
    phone: '918776800',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '197',
    alias: 'SORIA-CAMARETAS',
    name: 'Soria - Camaretas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LAS CAMARETAS LOCAL L-7, CALLE A S/N',
    city: 'GOLMAYO',
    province: 'SORIA',
    cp: '42001',
    phone: '975245477',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '198',
    alias: 'CORDOBA_ARCANGE',
    name: 'Cordoba - Arcangel',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL ARCÁNGEL, AVENIDA DEL ARCÁNGEL S/N, LOCAL B-44 A',
    city: 'CORDOBA',
    province: 'CORDOBA',
    cp: '14010',
    phone: '957752760',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '199',
    alias: 'MADRID_MAD_SUR',
    name: 'Madrid - Madrid Sur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MADRID SUR, CALLE PABLO NERUDA, 91-97, LOCAL  1,52',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28018',
    phone: '917857077',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '200',
    alias: 'BADAJOZ_CONQUIS',
    name: 'Badajoz - Conquistadores',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. CONQUISTADORES,LOCALB-12/13,AVDA DE ELVAS, S/N',
    city: 'BADAJOZ',
    province: 'BADAJOZ',
    cp: '06006',
    phone: '924278551',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '203',
    alias: 'TORREMOLINOS-PI',
    name: 'Torremolinos - Pinillo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. EL PINILLO LOCAL B-30, CRTA, CIRCUNVALACIÓN S/N, ANTIGUA CARRETERA Nº 340',
    city: 'TORREMOLINOS',
    province: 'MALAGA',
    cp: '29620',
    phone: '952054418',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '204',
    alias: 'LEON_ESPACIO_LE',
    name: 'Leon - Espacio Leon',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ESPACIO LEÓN, AVDA. PAÍS LEONES, Nº 12, LOCAL 133',
    city: 'LEON',
    province: 'LEON',
    cp: '24010',
    phone: '987274607',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '205',
    alias: 'MADRID_MORATALA',
    name: 'Madrid - Alcampo Moratalaz',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO MORATALAZ, LOCAL 17-18, CAMINO DE VINATEROS S/N',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28030',
    phone: '913281609',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '206',
    alias: 'JEREZ_AREA_SUR',
    name: 'Jerez - Area Sur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. AREA SUR, CRTA Nº IV- CONEXIÓN CRTA A-4, LOCAL B-95',
    city: 'JEREZ DE LA FRONTERA',
    province: 'CADIZ',
    cp: '11407',
    phone: '856813132',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '207',
    alias: 'ZARAGOZA_ENLACE',
    name: 'Zaragoza - Enlaces',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. LOS ENLACES, LOCAL L-18, CRTA. ANTIGUA NACIONAL II MADRID-ZARAGOZA, KM 315,2',
    city: 'ZARAGOZA',
    province: 'ZARAGOZA',
    cp: '50012',
    phone: '976346422',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '210',
    alias: 'ENTREGO_VALLE_N',
    name: 'Entrego - Valle Nalon',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. VALLE DEL NALÓN, CARRETERA AS-17, KM 47',
    city: 'EL ENTREGO',
    province: 'ASTURIAS',
    cp: '33940',
    phone: '985662869',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '212',
    alias: 'ALCORCON_P_OEST',
    name: 'Alcorcon - Parque Oeste',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. PARQUE OESTE LOCAL L-30, AVDA. DE EUROPA, S/N, CARRETERA DE EXTREMADURA, N-V, KM 15',
    city: 'ALCORCON',
    province: 'MADRID',
    cp: '28922',
    phone: '916891429',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '219',
    alias: 'SSEBASTIAN_GARB',
    name: 'San Sebastian - Garbera',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. GARBERA, TRAVESÍA DE GARBERA, Nº 1, LOCAL 0.37',
    city: 'SAN SEBASTIAN',
    province: 'GUIPUZCOA',
    cp: '20017',
    phone: '943390936',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '220',
    alias: 'CORVERA_P_ASTUR',
    name: 'Corvera - Parque Astur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PARQUE ASTUR, C/ EL PEDRERO, Nº 50, LOCAL 4-5',
    city: 'TRASONA-CORVERA',
    province: 'ASTURIAS',
    cp: '33468',
    phone: '985516869',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '221',
    alias: 'ANTEQUERA_VERON',
    name: 'Antequera - Veronica',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA VERONICA, CRTA A-343, KM 6,5, LOCAL 1.07',
    city: 'ANTEQUERA',
    province: 'MALAGA',
    cp: '29200',
    phone: '952840456',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '222',
    alias: 'CAN_GHOST_STORE',
    name: 'CAN_GHOST_STORE',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ VIRGILIO Nº9',
    city: 'POZUELO DE ALARCON',
    province: 'MADRID',
    cp: '28223',
    phone: '902171819',
  },
  {
    id: '225',
    alias: 'CORNELLA_LLOBRE',
    name: 'Cornella - Llobregat',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LLOBREGAT CENTRE, CRTA ESPLUGUES 1-19, LOCAL MA-01',
    city: 'CORNELLÀ DE LLOBREGAT',
    province: 'BARCELONA',
    cp: '08940',
    phone: '934752165',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '226',
    alias: 'MADRID_ISLAZUL',
    name: 'Madrid - Islazul',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ISLAZUL, C/ CALDERILLA, Nº 1, LOCAL 063',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28054',
    phone: '915116478',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '227',
    alias: 'ALMERIA_MEDITER',
    name: 'Almeria - Mediterraneo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. MEDITERRANEO, PROLONGACIÓN AVDA. MEDITERRANEO, S/N, LOCAL 35',
    city: 'ALMERIA',
    province: 'ALMERIA',
    cp: '04009',
    phone: '950149801',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '228',
    alias: 'PEÑACASTILLO_CA',
    name: 'Peñacastillo - Carrefour',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PEÑACASTILLO, PEÑACASTILLO-EL EMPALME, LOCAL 52',
    city: 'SANTANDER',
    province: 'CANTABRIA',
    cp: '39011',
    phone: '942320901',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '229',
    alias: 'SSEBASTIAN_URBI',
    name: 'San Sebastian - Urbil',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. URBIL, C/ POLIG. ASTEASUAIN,  Nº 7, TXIKI ERDI',
    city: 'USURBIL',
    province: 'GUIPUZCOA',
    cp: '20170',
    phone: '943374315',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '231',
    alias: 'ORIHUELA_OCIOPI',
    name: 'Orihuela - Ociopia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. OCIOPIA, C/ OBISPO VICTORIO OLIVER, Nº 2, LOC 33',
    city: 'ORIHUELA',
    province: 'ALICANTE',
    cp: '03300',
    phone: '965302698',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '232',
    alias: 'LORCA_ALMENARA',
    name: 'Lorca - Almenara',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALMENARA, AUTOVÍA LORCA-AGUILAS, S/N, LOCAL 94',
    city: 'LORCA',
    province: 'MURCIA',
    cp: '30800',
    phone: '968465867',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '234',
    alias: 'MALAGA_ALAMEDA2',
    name: 'Malaga - Alameda II',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALAMEDA II, C/ SAINT EXUPERY, Nº 1 LOCAL 12+13',
    city: 'MALAGA',
    province: 'MALAGA',
    cp: '29007',
    phone: '952334228',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '236',
    alias: 'ALMERIA_ESTACIO',
    name: 'Almeria - Estacion',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AV. DE LA ESTACION Nº 14',
    city: 'ALMERIA',
    province: 'ALMERIA',
    cp: '04005',
    phone: '950251011',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '237',
    alias: 'PONFERRADA_ROSA',
    name: 'Ponferrada - El Rosal',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL ROSAL, AVD. DE LA CONSTITUCIÓN, Nº 2, LOCAL 133',
    city: 'PONFERRADA',
    province: 'LEON',
    cp: '24400',
    phone: '987407660',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '238',
    alias: 'BURGOS_CMO_PLAT',
    name: 'Burgos - Camino Plata',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. CAMINO DE LA PLATA, AVDA CASTILLA Y LEÓN, Nº 22, LOCAL 6-7-8',
    city: 'BURGOS',
    province: 'BURGOS',
    cp: '09006',
    phone: '947242025',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '239',
    alias: 'CAMAS_ALJARAFE',
    name: 'Camas - Aljarafe',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALJARAFE, C/ POETA MUÑOZ SAN ROMÁN, S/N, LOCAL 2',
    city: 'CAMAS',
    province: 'SEVILLA',
    cp: '41900',
    phone: '955983064',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '240',
    alias: 'TARRASA_CARREFO',
    name: 'Tarrasa - Carrefour',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. CARREFOUR , AUTOPISTA A18, KM 17, LOCAL B-15-2',
    city: 'TERRASSA',
    province: 'BARCELONA',
    cp: '08228',
    phone: '937314784',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '241',
    alias: 'PORTUGALETE_BAL',
    name: 'Portugalete - Ballonti',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BALLONTI, PORTUGALETE, LOCAL B-43',
    city: 'PORTUGALETE',
    province: 'VIZCAYA',
    cp: '48920',
    phone: '944960075',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '242',
    alias: 'LERIDA_MAJOR',
    name: 'Lerida - Major',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CARRER MAJOR 68',
    city: 'LLEIDA',
    province: 'LLEIDA',
    cp: '25007',
    phone: '973234082',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '243',
    alias: 'GUADALAJARA_FER',
    name: 'Guadalajara - Ferial Plaza',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. FERIAL PLAZA , CIRCUNVALACIÓN SAN ROQUE SANZ VAZQUEZ, S/N LOCAL 08',
    city: 'GUADALAJARA',
    province: 'GUADALAJARA',
    cp: '19001',
    phone: '949315723',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '244',
    alias: 'MADRID_VAGUADA2',
    name: 'Madrid - Vaguada (2º)',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AV.MONFORTE LEMOS 36 C.C.VAGUADA T-136',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28029',
    phone: '917310148',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '245',
    alias: 'BARAKALDO_MAX_2',
    name: 'Barakaldo - Kareaga (2º)',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ BARRIO KAREAGA S/N LOCAL A-8(C.C. MAX CENTER)',
    city: 'BARAKALDO',
    province: 'VIZCAYA',
    cp: '48903',
    phone: '944978334',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '246',
    alias: 'BILBAO_ZUBIARTE',
    name: 'Bilbao - Zubiarte',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ZUBIARTE, C/ LEHENDAKARI LEIZAOLA, Nº 2, LOC A-24',
    city: 'BILBAO',
    province: 'VIZCAYA',
    cp: '48011',
    phone: '944414451',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '247',
    alias: 'ALCOBENDAS_VEGA',
    name: 'Alcobendas - Vega',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA VEGA, AVD. DE LA GUINDALERA, Nº 9, LOCAL B-37',
    city: 'ALCOBENDAS',
    province: 'MADRID',
    cp: '28108',
    phone: '916613460',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '248',
    alias: 'SANT_BOI_SANTBO',
    name: 'Sant Boi - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. ALCAMPO, POL. IND. LES SALINES, C. ALBEREDES 6-8, LOCAL 26-B',
    city: 'SANT BOI DE LLOBREGAT',
    province: 'BARCELONA',
    cp: '08830',
    phone: '936617338',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '249',
    alias: 'VILADECANS_VILA',
    name: 'Viladecans - Vilamarina',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. VILAMARINA, AV. SEGLE XXI 6, LOCAL 0,38',
    city: 'VILADECANS',
    province: 'BARCELONA',
    cp: '08840',
    phone: '936476781',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '251',
    alias: 'CAN_LANZAROTE_V',
    name: 'Lanzarote - Valls',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/CORONEL ILDEFONSO VALLS DE LA TORRE Nº3 LOCAL 8',
    city: 'LANZAROTE',
    province: 'LAS PALMAS G.C.',
    cp: '35500',
    phone: '928808992',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '252',
    alias: 'CAN_FUERTEV_ROT',
    name: 'Fuerteventura - Rotondas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LAS ROTONDAS, C/ PI Y ARSUAGA, Nº 2, LOCAL 12',
    city: 'FUERTEVENTURA',
    province: 'LAS PALMAS G.C.',
    cp: '35610',
    phone: '928532842',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '253',
    alias: 'MOLINASEG_VEGA',
    name: 'Molina - Vega Plaza',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. VEGA PLAZA, AVDA. DE GRANADA, S/N, LOCAL 28',
    city: 'MOLINA DE SEGURA',
    province: 'MURCIA',
    cp: '30500',
    phone: '968610909',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '255',
    alias: 'LALINEA_GRANSUR',
    name: 'La Linea - Gran Sur',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. GRAN SUR, AVDA DE LA COLONIA, Nº 9, LOCAL 50-52',
    city: 'LA LINEA DE LA CONCEPCION',
    province: 'CADIZ',
    cp: '11300',
    phone: '956171527',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '256',
    alias: 'MOSTOLES_CARREF',
    name: 'Mostoles - Carrefour',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. CARREFOUR MÓSTOLES, CRTA NACIONAL V, KM, 19, LOCAL 21',
    city: 'MOSTOLES',
    province: 'MADRID',
    cp: '28935',
    phone: '916143098',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '261',
    alias: 'RIVAS_H2OCIO',
    name: 'Rivas-Rivas Futura',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. H2OCIO LOCAL LB 27 PL. BAJA - C/ MARIE CURIE Nº4',
    city: 'RIVAS VACIAMADRID',
    province: 'MADRID',
    cp: '28529',
    phone: '917136607',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '262',
    alias: 'ONDARA_PRTL_MAR',
    name: 'Ondara - Portal Marina',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. COSTA BLANCA, Nº 1 LOCAL B-15',
    city: 'ONDARA',
    province: 'ALICANTE',
    cp: '03760',
    phone: '966477034',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '263',
    alias: 'OVIEDO_ESPAC_BU',
    name: 'Oviedo - Espacio Buenavista',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ ARTURO ÁLVAREZ BUYLLA, S/N, LOCAL 1,18',
    city: 'OVIEDO',
    province: 'ASTURIAS',
    cp: '33005',
    phone: '985274647',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '264',
    alias: 'COLMENAR_VENTAN',
    name: 'Colmenar - Ventanal',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'SECTOR 1 LA MAQUINILLA, PARCELA CC, LOCAL 34',
    city: 'COLMENAR VIEJO',
    province: 'MADRID',
    cp: '28770',
    phone: '918451541',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '265',
    alias: 'MURCIA_ATALAYAS',
    name: 'Murcia - Atalayas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ATALAYAS, LOCAL 9A-9B-10AC/ MOLINA DE SEGURA, S/N',
    city: 'MURCIA',
    province: 'MURCIA',
    cp: '30006',
    phone: '968248304',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '266',
    alias: 'HUESCA_COSOBAJO',
    name: 'Huesca - Coso Bajo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ COSO BAJO, Nº 63',
    city: 'HUESCA',
    province: 'HUESCA',
    cp: '22001',
    phone: '974221267',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '268',
    alias: 'MURCIA_AGUILAS',
    name: 'Murcia - Aguilas Plaza',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ ANDRÉS SEGOVIA, Nº 10, LOCAL B-31',
    city: 'AGUILAS',
    province: 'MURCIA',
    cp: '30880',
    phone: '968497897',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '269',
    alias: 'MALLORCA_MARRAT',
    name: 'Mallorca - Marratxi',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. ALCAMPO MALLORCA (MARRATXI), AUTOPISTA PM-27, PALMA-INCA, KM 4,5, PLG MARRATXI, LOCAL 29',
    city: 'MARRATXI',
    province: 'BALEARES',
    cp: '07141',
    phone: '971604668',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '270',
    alias: 'SEVILLA_MACAREN',
    name: 'Sevilla - Macarena',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MACARENA, RONDA URBANA NORTE, S/N, LOCAL 8',
    city: 'SEVILLA',
    province: 'SEVILLA',
    cp: '41008',
    phone: '954962490',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '271',
    alias: 'TARRAGONA_PARC',
    name: 'Tarragona - Parc Central',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PARC CENTRAL, C. DE VIDAL I BARRAQUER 15-17, LOCAL B-25',
    city: 'TARRAGONA',
    province: 'TARRAGONA',
    cp: '43005',
    phone: '977237223',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '272',
    alias: 'VILLALBA_VALLES',
    name: 'Villaba - Los Valles',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LOS VALLES, C/ RINCÓN DE LAS ERAS, Nº 1, LOCAL 51',
    city: 'COLLADO VILLALBA',
    province: 'MADRID',
    cp: '28400',
    phone: '918493752',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '274',
    alias: 'ELCHE_L´ALJUB',
    name: 'Elche - L´Aljub',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. L´ALJUB, C/ JACARILLA, S/N, LOCAL B-1',
    city: 'ELCHE',
    province: 'ALICANTE',
    cp: '03205',
    phone: '965465468',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '275',
    alias: 'FUENGIROLA_MIRA',
    name: 'Fuengirola - Miramar',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MIRAMAR, AVDA DE LA ENCARNACIÓN, S/N, LOCAL B-26',
    city: 'FUENGIROLA',
    province: 'MALAGA',
    cp: '29640',
    phone: '952472716',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '276',
    alias: 'ALCOY_ALZAMORA',
    name: 'Alcoy - Alzamora',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALZAMORA, C/ ALZAMORA, Nº 44, LOCAL 08',
    city: 'ALCOY',
    province: 'ALICANTE',
    cp: '03802',
    phone: '966520601',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '277',
    alias: 'BARBERA_BARIC_2',
    name: 'Barbera Valles - Baricentro 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BARICENTRO, CTRA. NACIONAL 150, KM 6, LOCAL B13',
    city: 'BARBERÀ DEL VALLES',
    province: 'BARCELONA',
    cp: '08210',
    phone: '937295317',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '278',
    alias: 'REUS_FOLGUERA',
    name: 'Reus - Marti Folguera',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'RAVAL DE MARTÍ FLOGUERA1',
    city: 'REUS',
    province: 'TARRAGONA',
    cp: '43201',
    phone: '977311088',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '279',
    alias: 'CAN_TELDE',
    name: 'Las Palmas - Telde',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. TELDE, AUTOPISTA GRAN CANARIA 1, KM, 8',
    city: 'TELDE',
    province: 'LAS PALMAS G.C.',
    cp: '35212',
    phone: '928706313',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '280',
    alias: 'VIGO_G_VIA',
    name: 'Vigo - Gran Via',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ BAIXADA S/N LOCAL 1',
    city: 'VIGO',
    province: 'PONTEVEDRA',
    cp: '36210',
    phone: '986422679',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '281',
    alias: 'VIGO_CC_TRAVESI',
    name: 'Vigo - Travesia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'TRVS.DE VIGO S/N C.C. TRAVESIA LOCAL B-8',
    city: 'VIGO',
    province: 'PONTEVEDRA',
    cp: '36207',
    phone: '986284291',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '283',
    alias: 'PONTEVEDRA_VIGO',
    name: 'Pontevedra - Av. Vigo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AV. DE VIGO Nº 1',
    city: 'PONTEVEDRA',
    province: 'PONTEVEDRA',
    cp: '36003',
    phone: '986862666',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '286',
    alias: 'CAN_LAS_ARENAS',
    name: 'Las Palmas - Arenas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LAS ARENAS, CARRETERA DEL RINCÓN S/N, LOCAL T 19-20',
    city: 'LAS PALMAS DE GRAN CANARIA',
    province: 'LAS PALMAS G.C.',
    cp: '35010',
    phone: '928273259',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '287',
    alias: 'ABADIÑO_EROSKI',
    name: 'Abadiño - Eroski',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EROSKI ABADIÑO, BARRIO MURETA, S/N, LOCAL B-08',
    city: 'ABADIÑO',
    province: 'VIZCAYA',
    cp: '48220',
    phone: '946201017',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '289',
    alias: 'MADRID_GV_HORTA',
    name: 'Madrid - Gran Via Hortaleza',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. GRAN VÍA DE HORTALEZA, C/ GRAN VÍA DE HORTALEZA, S/N, LOCAL 224',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28043',
    phone: '917643554',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '291',
    alias: 'MADRID_PIO_XII',
    name: 'Madrid - Pio XII',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PIO XII, AVD PÍO XII, Nº 2, LOCAL 26',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28016',
    phone: '913502644',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '293',
    alias: 'UTEBO_ALCAMPO',
    name: 'Utebo - Alcampo Utebo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. UTEBO, CARRETERA ZARAGOZA-LOGROÑO, KM 12, LOCAL 12',
    city: 'UTEBO',
    province: 'ZARAGOZA',
    cp: '50180',
    phone: '976786847',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '294',
    alias: 'VIGO_A_LAXE',
    name: 'Vigo - A Laxe',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. A LAXE, AVDA. CÁNOVAS DEL CASTILLOS, S/N, LOCAL 10',
    city: 'VIGO',
    province: 'PONTEVEDRA',
    cp: '36202',
    phone: '986221715',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '296',
    alias: 'ROCES_ALCAMPO',
    name: 'Roces - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO, CARRETERA NACIONAL 630, KM 467, LOCAL 18',
    city: 'GIJON',
    province: 'ASTURIAS',
    cp: '33211',
    phone: '985167088',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '302',
    alias: 'TOLEDO_FUSION',
    name: 'Toledo - Fusión',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. FUSIÓN TOLEDO, AVDA RIO GUADIANA, S/N, LOCAL 04-A',
    city: 'TOLEDO',
    province: 'TOLEDO',
    cp: '45007',
    phone: '925337168',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '303',
    alias: 'FUENLABRADA_LOR',
    name: 'Fuenlabrada - Loranca',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LORANCA, AVDA PABLO IGLESIAS, Nº 17, LOCAL 103',
    city: 'FUENLABRADA',
    province: 'MADRID',
    cp: '28942',
    phone: '916045473',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '306',
    alias: 'FINESTRAT_MARIN',
    name: 'Finestrat - La Marina',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA MARINA, AVENIDA DEL PAIS VALENCIA, Nº 2, LOCAL 59-60',
    city: 'FINESTRAT',
    province: 'ALICANTE',
    cp: '03509',
    phone: '965864145',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '308',
    alias: 'AYAMONTE_PLAZA',
    name: 'Ayamonte - Plaza',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA PLAZA, AVENIDA DE LA CONSTITUCIÓN, Nº 78, LOCAL 11',
    city: 'AYAMONTE',
    province: 'HUELVA',
    cp: '21400',
    phone: '959180196',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '309',
    alias: 'SANTANDER_ALISA',
    name: 'Santander - Alisal',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. EL ALISAL, C/ JOAQUÍN RODRÍGO, S/N, ZONA CIERRO DEL ALISAL, LOCAL 28',
    city: 'SANTANDER',
    province: 'CANTABRIA',
    cp: '39011',
    phone: '942342584',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '310',
    alias: 'KIOSKO_1',
    name: 'KIOSKO_1',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ VIRGILIO Nº9',
    city: 'POZUELO DE ALARCON',
    province: 'MADRID',
    cp: '28223',
    phone: '902171819',
    regionalManager: 'Victor Gil',
    manager: 'Victor Gil',
  },
  {
    id: '311',
    alias: 'POIO_A_BARCA',
    name: 'Pontevedra - A Barca',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. A BARCA, AVD. DOMINGO FONTÁN, Nº 2, LOCAL B12',
    city: 'POIO',
    province: 'PONTEVEDRA',
    cp: '36005',
    phone: '986872118',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '317',
    alias: 'CAN_LAGUNA',
    name: 'La Laguna - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO, AUTOPISTA SANTA CRUZ-LA LAGUNA, LOCAL 36',
    city: 'SAN CRISTOBAL DE LA LAGUNA',
    province: 'TENERIFE',
    cp: '38205',
    phone: '922310149',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '319',
    alias: 'CORUÑA_ESPACIO',
    name: 'Coruña - Espacio Coruña',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. ESPACIO CORUÑA, C/ JOSE PASCUAL LOPEZ-CORTON Nº 10 LOCAL 1,51',
    city: 'CORUÑA',
    province: 'A CORUÑA',
    cp: '15008',
    phone: '981172277',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '325',
    alias: 'JEREZ_NORTE',
    name: 'Jerez - Jerez Norte',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. JEREZ NORTE, AVDA DE EUROPA S/N, LOCAL  13',
    city: 'JEREZ DE LA FRONTERA',
    province: 'CADIZ',
    cp: '11405',
    phone: '956185824',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '326',
    alias: 'CAN_BELLAVISTA',
    name: 'Las Palmas - Bellavista',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. BELLAVISTA, C/ PARTERA LEONERITA S/N, LOCAL B-119',
    city: 'MASPALOMAS',
    province: 'LAS PALMAS G.C.',
    cp: '35100',
    phone: '928769360',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '327',
    alias: 'BCN_FABRA',
    name: 'Barcelona - Fabra Puig',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'PG. FABRA I PUIG 166-182',
    city: 'BARCELONA',
    province: 'BARCELONA',
    cp: '08016',
    phone: '933518205',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '329',
    alias: 'TORREVIEJA_HABA',
    name: 'Torrevieja - Habaneras',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. HABANERAS, POLG. SAN JOSE, AVDA. ROSA MAZON V',
    city: 'TORREVIEJA',
    province: 'ALICANTE',
    cp: '03180',
    phone: '965712736',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '330',
    alias: 'ZARAGOZA_VENECI',
    name: 'Zaragoza Puerto Venecia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PUERTO VENECIA, PINARES DE VENECIA, S/N, LOCAL 159',
    city: 'ZARAGOZA',
    province: 'ZARAGOZA',
    cp: '50021',
    phone: '976930469',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '332',
    alias: 'CORDOVILLA_MORE',
    name: 'Cordovilla - Morea',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA MOREA, BARRIO LA MOREA, S/N, LOCAL B-30-BIS',
    city: 'CORDOVILLA',
    province: 'NAVARRA',
    cp: '31191',
    phone: '948150513',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '334',
    alias: 'PARLA_FERIAL',
    name: 'Parla - Ferial',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL FERIAL, C/ PINTO, S/N, LOCAL 2',
    city: 'PARLA',
    province: 'MADRID',
    cp: '28982',
    phone: '916992722',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '335',
    alias: 'CAN_3_MAYO',
    name: 'Tenerife - 3 de Mayo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. TRES DE MAYO, AVENIDA TRES DE MAYO, Nº 63, LOCAL B-5',
    city: 'SANTA CRUZ DE TENERIFE',
    province: 'TENERIFE',
    cp: '38003',
    phone: '922536328',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '337',
    alias: 'VALENCIA_ARENA',
    name: 'Valencia - Arena Multiespacio',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. ARENA MULTIESPACIO,RONDA NORTE ESQUINA CALLE HORCHATA, LOCAL S-15',
    city: 'VALENCIA',
    province: 'VALENCIA',
    cp: '46019',
    phone: '961249585',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '338',
    alias: 'SALAMANCA_TORO',
    name: 'Salamanca - Toro',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ TORO, Nº 84',
    city: 'SALAMANCA',
    province: 'SALAMANCA',
    cp: '37002',
    phone: '923211769',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '340',
    alias: 'ZAMORA_VALDERAD',
    name: 'Zamora - Valderaduey',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. VALDERADUEY, AVDA CARDENAL CISNEROS, S/N, LOCAL 28',
    city: 'ZAMORA',
    province: 'ZAMORA',
    cp: '49023',
    phone: '980523985',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '341',
    alias: 'POZUELO_ZIELO',
    name: 'Pozuelo - Zielo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ZIELO DE POZUELO, AVDA. DE EUROPA, Nº 26-B',
    city: 'POZUELO DE ALARCON',
    province: 'MADRID',
    cp: '28224',
    phone: '917093305',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '342',
    alias: 'ALFAFAR_CARREFO',
    name: 'Alfafar - Carrefour',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALFAFAR CARREFOUR, AVDA. DE LA ALBUFERA, S/N, LOCAL 50',
    city: 'ALFAFAR',
    province: 'VALENCIA',
    cp: '46910',
    phone: '963753371',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '343',
    alias: 'SALCEDO_CARREFO',
    name: 'Salcedo - Carrefour',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PONTEVEDRA CARREFOUR, RUA SAN BLAS, S/N, LOCAL 8',
    city: 'SALCEDO',
    province: 'PONTEVEDRA',
    cp: '36143',
    phone: '986847058',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '345',
    alias: 'REUS_M_FORTUNY',
    name: 'Reus - Carrefour',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. CARREFOUR, AV. MARIÀ FORTUNY S/N, LOCAL 11',
    city: 'REUS',
    province: 'TARRAGONA',
    cp: '43204',
    phone: '977340450',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '347',
    alias: 'MARRATXI_FESTIV',
    name: 'Mallorca - Festival Park',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. FESTIVAL PARK, AUTOPISTA PALMA-INCA, KM 7,1, LOCAL 145 A',
    city: 'MARRATXI',
    province: 'BALEARES',
    cp: '07141',
    phone: '971226763',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '348',
    alias: 'CORNELLA_SPLAU',
    name: 'Cornella - Splau',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. SPLAU, AV. DEL BAIX LLOBREGAT S/N, LOCAL 065-066',
    city: 'CORNELLÀ DE LLOBREGAT',
    province: 'BARCELONA',
    cp: '08940',
    phone: '934719031',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '349',
    alias: 'GRANADA_ALCAMPO',
    name: 'Granada - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. ALCAMPO, CARRETERA DE JAEN, S/N, NACIONAL 323, KM, 127, LOCAL, 16 A',
    city: 'GRANADA',
    province: 'GRANADA',
    cp: '18013',
    phone: '958162960',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '350',
    alias: 'TOLEDO_LUZTAJO',
    name: 'Toledo - Luz del Tajo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'RIO BOLADIEZ S/N C.C. LUZ DEL TAJO LOCAL 001A',
    city: 'TOLEDO',
    province: 'TOLEDO',
    cp: '45007',
    phone: '925232539',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '351',
    alias: 'BCN_GRAN_DE_GRA',
    name: 'Barcelona - Gran de Gracia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. GRAN DE GRÀCIA 116',
    city: 'BARCELONA',
    province: 'BARCELONA',
    cp: '08012',
    phone: '933687671',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '352',
    alias: 'ALGECIRAS_P_EUR',
    name: 'Algeciras - Puerta Europa',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVD. PROLONGACIÓN VIRGEN DE LA PALMA S/N, LOCAL B-5',
    city: 'ALGECIRAS',
    province: 'CADIZ',
    cp: '11202',
    phone: '956626162',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '353',
    alias: 'ALBORAYA_ALCAMP',
    name: 'Alboraya - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO, AUTOPISTA A-7. KM 4,5, SALIDA 2, LOCAL 40-41',
    city: 'ALBORAYA',
    province: 'VALENCIA',
    cp: '46120',
    phone: '963563909',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '354',
    alias: 'MADRID_GAVIA_II',
    name: 'Madrid - Gavia (2º)',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA GAVIA, C/ ADOLFO BIOY CASARES, Nº 2, LOCAL 1.96',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28051',
    phone: '914255692',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '355',
    alias: 'CADIZ_PALILLERO',
    name: 'Cadiz - Palillero',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'PLAZA PALILLERO, Nº 4, LOCAL COMERCIAL IZQUIERDA',
    city: 'CADIZ',
    province: 'CADIZ',
    cp: '11001',
    phone: '956228623',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '357',
    alias: 'CADIZ_PASEO',
    name: 'Cadiz - Paseo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL PASEO, CRTA. NACIONAL IV, KM 653, LOCAL 13-14',
    city: 'PUERTO SANTA MARIA',
    province: 'CADIZ',
    cp: '11500',
    phone: '956855581',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '359',
    alias: 'GRANOLLERS_ANA',
    name: 'Granollers - Santa Ana',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. SANTA ANNA 11',
    city: 'GRANOLLERS',
    province: 'BARCELONA',
    cp: '08401',
    phone: '938706714',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '362',
    alias: 'HUELVA_3_AGOSTO',
    name: 'Huelva - Tres Agosto',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CL. TRES DE AGOSTO Nº4 BAJO',
    city: 'HUELVA',
    province: 'HUELVA',
    cp: '21003',
    phone: '959244865',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '363',
    alias: 'SAN_FERNAN_RAFA',
    name: 'San Fernando - San Rafael',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ SAN RAFAEL, Nº 11, LOCAL COMERCIAL',
    city: 'SAN FERNANDO',
    province: 'CADIZ',
    cp: '11100',
    phone: '956595134',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '365',
    alias: 'SEVILLA_TAMARGU',
    name: 'Sevilla - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO, RONDA DE TAMARGUILLO, S/N, LOCAL 26-B',
    city: 'SEVILLA',
    province: 'SEVILLA',
    cp: '41005',
    phone: '954660683',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '366',
    alias: 'ROQUETAS_GPLA_2',
    name: 'Roquetas - Gran Plaza (2º)',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. GRAN PLAZA, LOCAL, 79, AVENIDA ALICÚN, S/N,',
    city: 'ROQUETAS DEL MAR',
    province: 'ALMERIA',
    cp: '04740',
    phone: '950324317',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '368',
    alias: 'SANTIAGO_CANCEL',
    name: 'Santiago As Cancelas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. AS CANCELAS, AVENIDA DEL CAMINO FRANCÉS S/N, PR-1, LOCAL 0,10',
    city: 'SANTIAGO DE COMPOSTELA',
    province: 'A CORUÑA',
    cp: '15703',
    phone: '981556398',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '376',
    alias: 'SANT_QUIRZE_ALC',
    name: 'Sant Quirze - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO, AUTOPISTA C-58,LOCAL 3',
    city: 'SANT QUIRZE DEL VALLÉS',
    province: 'BARCELONA',
    cp: '08192',
    phone: '937210754',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '377',
    alias: 'MOTRIL_ALCAMPO',
    name: 'Motril - Alcampo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. ALCAMPO, AVDA. DE SALOBREÑA, S/N. POLIG. LA PUCHILLA, LOC. 11-12',
    city: 'MOTRIL',
    province: 'GRANADA',
    cp: '18600',
    phone: '958609374',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '378',
    alias: 'VIGO_ALCAMPO_V2',
    name: 'Vigo - Alcampo Vigo II',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO, AVDA. DE MADRID, S/N, LOCAL 13',
    city: 'VIGO',
    province: 'PONTEVEDRA',
    cp: '36214',
    phone: '986414681',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '379',
    alias: 'VALENCIA_MANAR',
    name: 'Valencia - Manar',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. EL MANAR, POLÍGONO INDUSTRIAL EL MANAR, AUTOVÍA V-21, SALIDA 9',
    city: 'MASSALFASSAR',
    province: 'VALENCIA',
    cp: '46560',
    phone: '961400258',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '380',
    alias: 'MADRID_P_HIELO',
    name: 'Madrid - Palacio Hielo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PALACIO DEL HIELO, C/ SILVANO, Nº 77, LOCAL B-9',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28043',
    phone: '913884631',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '381',
    alias: 'ALICANTE_PUERTA',
    name: 'Alicante - Puerta Alicante',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. PUERTA DE ALICANTE, C/ ALCALDE LORENZO CARBONELL S/N, LOC. B00048',
    city: 'ALICANTE',
    province: 'ALICANTE',
    cp: '03007',
    phone: '965115671',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '382',
    alias: 'ALICANTE_GRAN_V',
    name: 'Alicante - Gran Via',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. GRAN VIA, C/ JOSE GARCÍA SELLES Nº2, LOC. B-12',
    city: 'ALICANTE',
    province: 'ALICANTE',
    cp: '03015',
    phone: '965775120',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '387',
    alias: 'GIRONA_SANT_ANT',
    name: 'Gerona - Sant Antoni',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'RONDA SANT ANTONI MARIA CLARET 10',
    city: 'GIRONA',
    province: 'GIRONA',
    cp: '17002',
    phone: '872080894',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '388',
    alias: 'ECIJA_N4',
    name: 'Ecija - N4',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. N4, PROLONGACIÓN AVENIDA DEL GENIL, S/N, LOCAL B38',
    city: 'ÉCIJA',
    province: 'SEVILLA',
    cp: '41400',
    phone: '955876195',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '389',
    alias: 'CORUÑA_MARINEDA',
    name: 'Coruña - Marineda Plaza',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MARINEDA PLAZA, CRTA BAÑOS DE ARTEIXO, Nº 43, LOCAL 0,57',
    city: 'CORUÑA',
    province: 'A CORUÑA',
    cp: '15008',
    phone: '981632491',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '390',
    alias: 'BILBAO_RODRIGUE',
    name: 'Bilbao - Rodriguez Arias',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ RODRIGUEZ ARIAS, N º9',
    city: 'BILBAO',
    province: 'VIZCAYA',
    cp: '48008',
    phone: '944108499',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '391',
    alias: 'IRUN_MENDIBIL',
    name: 'Irun - Mendibil',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MENDIBIL, C/ ALMIRÁNTE ARIZMENDI, Nº 9',
    city: 'IRUN',
    province: 'GUIPUZCOA',
    cp: '20302',
    phone: '943630330',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '393',
    alias: 'MAJADAHONDA_C_O',
    name: 'Majadahonda - Centro Oeste',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. CENTRO OESTE, C/ LAS MORERAS, Nº 2, LOCAL, Nº 43',
    city: 'MAJADAHONDA',
    province: 'MADRID',
    cp: '28222',
    phone: '916344401',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '396',
    alias: 'MATARO_PARC_2',
    name: 'Mataró - Parc',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MATARÓ PARK, C. D´ ESTRASBURGO 5, LOCAL 117',
    city: 'MATARÓ',
    province: 'BARCELONA',
    cp: '08304',
    phone: '936386436',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '397',
    alias: 'MAJADAHONDA_GP2',
    name: 'Majadahonda-Gran Plaza 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C/ DE LOS QUIMICOS Nº1. URB. EL TEJAR, C.C. GRAN PLAZA 2 LOCAL B096',
    city: 'MAJADAHONDA',
    province: 'MADRID',
    cp: '28222',
    phone: '915653738',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '398',
    alias: 'SANSEBASTIAN_FU',
    name: 'San Sebastián - Fuenterrabía',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ FUENTERRABIA,34',
    city: 'DONOSTI(SAN SEBASTIAN)',
    province: 'GUIPUZCOA',
    cp: '20005',
    phone: '943467741',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '401',
    alias: 'ORENSE_CARREFOU',
    name: 'Orense - Carrefour',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ AIRA DE FONSILLON, S/N, LOCAL12',
    city: 'BARBADAS',
    province: 'ORENSE',
    cp: '32890',
    phone: '988236110',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '402',
    alias: 'BADAJOZ_EL_FARO',
    name: 'Badajoz-El Faro',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. FARO GUADIANA, Avda. de Elvas, s/n esq. C/ Santiago Soutullo L13',
    city: 'BADAJOZ',
    province: 'BADAJOZ',
    cp: '06003',
    phone: '924391073',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '403',
    alias: 'VALLADOLID_RIO',
    name: 'Valladolid Rio Shopping',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. RIO SHOPPING, C/ ME FALTA UN TORNILLO, S/N, LOCAL B-67',
    city: 'ARROYO DE LA ENCOMIENDA',
    province: 'VALLADOLID',
    cp: '47195',
    phone: '983326412',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '404',
    alias: 'ORIHUELA_ZENIA',
    name: 'Orihuela-La Zenia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. LA ZENIA, C/ JADE, LOCAL 98',
    city: 'ORIHUELA',
    province: 'ALICANTE',
    cp: '03189',
    phone: '965355859',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '405',
    alias: 'MIERES',
    name: 'Mieres',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. C. CAUDALIA, AVD. DEL CAMINO, Nº 1 LOCAL B 51',
    city: 'MIERES',
    province: 'ASTURIAS',
    cp: '33600',
    phone: '985426960',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '406',
    alias: 'CACERES_PORTUGA',
    name: 'Caceres - Portugal',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. PORTUGAL 8',
    city: 'CACERES',
    province: 'CACERES',
    cp: '10001',
    phone: '927242932',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '407',
    alias: 'GRANADA_ARMILLA',
    name: 'Granada - Armilla',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CRTA. ARMILLA, S/N LOCAL 11',
    city: 'GRANADA',
    province: 'GRANADA',
    cp: '18006',
    phone: '958128207',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '408',
    alias: 'CASTELLDEFELS',
    name: 'Castelldefels',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ÀNEC BLAU, AV. DEL CANAL OLÍMPIC 24',
    city: 'CASTELLDEFELS',
    province: 'BARCELONA',
    cp: '08860',
    phone: '936321548',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '409',
    alias: 'VITORIA_JESUS',
    name: 'Vitoria - Jesus',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'JESÚS GURIDI 4',
    city: 'VITORIA',
    province: 'ALAVA',
    cp: '01004',
    phone: '945330842',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '410',
    alias: 'MADRID_ANGELES',
    name: 'Madrid - Los Angeles',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. C. LOS ANGELES, AVD. DE ANDALUCÍA KM.7,1N LOCAL 16C',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28041',
    phone: '917238211',
    regionalManager: 'Carlos Lopez',
    manager: 'Carlos Bravo',
  },
  {
    id: '411',
    alias: 'LUGO_CORUÑA',
    name: 'Lugo - Coruña',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. DE LA CORUÑA 53',
    city: 'LUGO',
    province: 'LUGO',
    cp: '27003',
    phone: '982202609',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '412',
    alias: 'GRANADA_SERRALL',
    name: 'Granada - Serrallo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. C. SERRALLO PLAZA, C/ LAGUNA DE CAMEROS Nº 1 LOCAL 24',
    city: 'GRANADA',
    province: 'GRANADA',
    cp: '18008',
    phone: '958614078',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '413',
    alias: 'ALGECIRAS_SEVIL',
    name: 'Algeciras - Sevilla',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'SEVILLA (PLZA. NEDA) 15',
    city: 'ALGECIRAS',
    province: 'CADIZ',
    cp: '11201',
    phone: '956655823',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '414',
    alias: 'ONTINIENTE_TELE',
    name: 'Ontiniente - El Teler',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CC EL TELER, PINTOR SEGRELLES, 1 LOCAL 212',
    city: 'ONTENIENTE',
    province: 'VALENCIA',
    cp: '46870',
    phone: '962385231',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '415',
    alias: 'SANTIAGO_S_PEDR',
    name: 'Santiago - San Pedro',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'SAN PEDRO DE MEZONZO 19',
    city: 'SANTIAGO COMPOSTELA',
    province: 'A CORUÑA',
    cp: '15701',
    phone: '981593303',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '416',
    alias: 'VALENCIA_CAMPAN',
    name: 'Valencia - Campanar',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. CAMPANAR, AVDA. MANUEL DE FALLA, Nº 13 LOCAL 30',
    city: 'VALENCIA',
    province: 'VALENCIA',
    cp: '46015',
    phone: '963173735',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '418',
    alias: 'TERUEL',
    name: 'Teruel',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'JOAQUÍN COSTA (TOZAL) 21',
    city: 'TERUEL',
    province: 'TERUEL',
    cp: '44001',
    phone: '978600928',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '419',
    alias: 'CALA_MORAL',
    name: 'La Cala del Moral',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CC RINCON DE LA VICTORIA, C/ ARROYO DE TOTALÁN, 36 LOCAL 19',
    city: 'LA CALA DEL MORAL',
    province: 'MALAGA',
    cp: '29720',
    phone: '952978622',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '420',
    alias: 'HUELVA_ALCALDE',
    name: 'Huelva - Alcalde',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'ALCALDE FEDERICO MOLINA 48',
    city: 'HUELVA',
    province: 'HUELVA',
    cp: '21006',
    phone: '959234428',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '421',
    alias: 'JAEN_MILLAN',
    name: 'Jaen - Millan',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'MILLÁN DE PRIEGO 19',
    city: 'JAEN',
    province: 'JAEN',
    cp: '23007',
    phone: '953303092',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '424',
    alias: 'ELCHE_CRIS_SANZ',
    name: 'Elche - CristobalSanz',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CRISTÓBAL SANZ 73',
    city: 'ELCHE',
    province: 'ALICANTE',
    cp: '03201',
    phone: '966600778',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '425',
    alias: 'BADALONA_MAGIC',
    name: 'Badalona - Magic',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. MÀGIC, C. Concordia, 1',
    city: 'BADALONA',
    province: 'Barcelona',
    cp: '08917',
    phone: '932245508',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '428',
    alias: 'VALENCIA_SANTOS',
    name: 'Valencia - Santos',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'SANTOS JUSTO Y PASTOR (EDIFICIO ARCO) 124',
    city: 'VALENCIA',
    province: 'VALENCIA',
    cp: '46022',
    phone: '963715698',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '429',
    alias: 'PUERTOLLANO',
    name: 'Puertollano',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'VÉLEZ 3',
    city: 'PUERTOLLANO',
    province: 'CIUDAD REAL',
    cp: '13500',
    phone: '926438705',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '430',
    alias: 'PALMA_31_DIC',
    name: 'Palma - 31 Diciembre',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: '31 DE DICIEMBRE 24',
    city: 'PALMA DE MALLORCA',
    province: 'BALEARES',
    cp: '07004',
    phone: '971758040',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '431',
    alias: 'VILLENA',
    name: 'Villena',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVENIDA CONSTITUCIÓN 77',
    city: 'VILLENA',
    province: 'ALICANTE',
    cp: '03400',
    phone: '965779411',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '432',
    alias: 'ORENSE_PROGRESO',
    name: 'Orense - Progreso',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'PROGRESO 47',
    city: 'OURENSE',
    province: 'OURENSE',
    cp: '32003',
    phone: '988240990',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '433',
    alias: 'PLASENCIA',
    name: 'Plasencia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. ALFONSO VIII 14',
    city: 'PLASENCIA',
    province: 'CACERES',
    cp: '10600',
    phone: '927423915',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '434',
    alias: 'PALMA_PORTO_P2',
    name: 'Mallorca - Porto Pi - 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. C. PORTO PI, AVDA. GABRIEL ROCA, Nº 54 LOCAL 1301A + 1301B',
    city: 'PALMA DE MALLORCA',
    province: 'BALEARES',
    cp: '07015',
    phone: '971401513',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '435',
    alias: 'CHICLANA',
    name: 'Chiclana',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. DE LOS DESCUBRIMIENTOS (C.E. LAS REDES) 124',
    city: 'CHICLANA',
    province: 'CADIZ',
    cp: '11130',
    phone: '956530835',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '436',
    alias: 'ALMENDRALEJO',
    name: 'Almendralejo',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'ANTONIO MARTÍNEZ PINILLO (EL ESPOLON) 7',
    city: 'ALMENDRALEJO',
    province: 'BADAJOZ',
    cp: '06200',
    phone: '924671254',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '437',
    alias: 'XATIVA',
    name: 'Xativa',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'REINA 21',
    city: 'XATIVA',
    province: 'VALENCIA',
    cp: '46800',
    phone: '962280220',
    regionalManager: 'Eduardo Timón',
    manager: 'Enrique Alberola',
  },
  {
    id: '438',
    alias: 'LUCENA',
    name: 'Lucena',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'JAIME 16',
    city: 'LUCENA',
    province: 'CORDOBA',
    cp: '14900',
    phone: '957513347',
    regionalManager: 'Carlos Lopez',
    manager: 'Rafael Bustos',
  },
  {
    id: '439',
    alias: 'VALDEPEÑAS',
    name: 'Valdepeñas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'GUARDIA 2',
    city: 'VALDEPEÑAS',
    province: 'CIUDAD REAL',
    cp: '13300',
    phone: '926312418',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '440',
    alias: 'MERIDA',
    name: 'Merida',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'RAMBLA DE STA. EULALIA 9',
    city: 'MÉRIDA',
    province: 'BADAJOZ',
    cp: '06800',
    phone: '924304126',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '441',
    alias: 'TARRASA_EGARA',
    name: 'Tarrasa - Egara',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'RAMBLA D´EGARA 105',
    city: 'TERRASSA',
    province: 'BARCELONA',
    cp: '08221',
    phone: '937685158',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '442',
    alias: 'ALCAZAR_S_JUAN',
    name: 'Alcazar de San Juan',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'EMILIO CASTELAR 51',
    city: 'ALCAZAR DE SAN JUAN',
    province: 'CIUDAD REAL',
    cp: '13600',
    phone: '926547397',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '443',
    alias: 'VIC',
    name: 'Vic',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'PORTAL DE LA RAMBLA 1',
    city: 'VIC',
    province: 'BARCELONA',
    cp: '08500',
    phone: '938881571',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '445',
    alias: 'CADIZ_ANDALUCIA',
    name: 'Cadiz - Andalucia',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. ANDALUCÍA 92',
    city: 'CADIZ',
    province: 'CADIZ',
    cp: '11008',
    phone: '956261960',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '446',
    alias: 'VILAGARCIA_AROU',
    name: 'Villagarcia de Arosa',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ ALCALDE REY DAVIÑA Nº 49',
    city: 'VILLAGARCÍA DE AROSA',
    province: 'PONTEVEDRA',
    cp: '36600',
    phone: '986510601',
    regionalManager: 'Eduardo Timón',
    manager: 'Joel Ramirez Garcia',
  },
  {
    id: '447',
    alias: 'DOS_HERMANAS',
    name: 'Dos Hermanas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'CANÓNIGO 82',
    city: 'DOS HERMANAS',
    province: 'SEVILLA',
    cp: '41700',
    phone: '954722992',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '448',
    alias: 'DON_BENITO',
    name: 'Don Benito',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'AVDA. CONSTITUCIÓN 19 ACC.2',
    city: 'DON BENITO',
    province: 'BADAJOZ',
    cp: '06400',
    phone: '924805592',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '449',
    alias: 'SANTANDER_BURGO',
    name: 'Santander - Burgos',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ BURGOS 16',
    city: 'SANTANDER',
    province: 'CANTABRIA',
    cp: '39008',
    phone: '942374808',
    regionalManager: 'Eduardo Timón',
    manager: 'Jokin Barceló',
  },
  {
    id: '450',
    alias: 'SEVILLA_NERVI_2',
    name: 'Sevilla - Nervion 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C. C. NERVIÓN, AVDA. LUIS MORALES, 3 LOCAL A009',
    city: 'SEVILLA',
    province: 'SEVILLA',
    cp: '41005',
    phone: '954425984',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '451',
    alias: 'MALLORCA_FAN',
    name: 'Mallorca - Fan',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C/ CARDENAL ROSELL Nº 168, (CC FAN MALLORCA SHOPPING) LOCAL 9 (L0-10)',
    city: 'PALMA DE MALLORCA',
    province: 'BALEARES',
    cp: '07007',
    phone: '871031969',
    regionalManager: 'Eduardo Timón',
    manager: 'Alex Vila',
  },
  {
    id: '452',
    alias: 'SANT_ADRIA_BES',
    name: 'Sant Adria del Besos',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. ALCAMPO, AV. DE LA PLAYA S/N, LOCAL L-27',
    city: 'SANT ADRIÀ DEL BESOS',
    province: 'BARCELONA',
    cp: '08930',
    phone: '933816409',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '453',
    alias: 'ARANJUEZ_DEL',
    name: 'Aranjuez Deleite',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. EL DELEITE, PASEO DEL DELEITE, Nº 13, LOCAL 33',
    city: 'ARANJUEZ',
    province: 'MADRID',
    cp: '28300',
    phone: '918391525',
    regionalManager: 'Carlos Lopez',
    manager: 'Miguel Angel Fernandez Suarez',
  },
  {
    id: '454',
    alias: 'MADRID_PZA_RIO2',
    name: 'Madrid Plaza Rio 2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. PLAZA RIO 2, C/ ANTONIO LÓPEZ, Nº 109, LOCAL PB-07',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28026',
    phone: '910586173',
    regionalManager: 'Carlos Lopez',
    manager: 'Monica Quiñones',
  },
  {
    id: '455',
    alias: 'HUELVA_HOLEA',
    name: 'Huelva Holea',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. HOLEA. RONDA EXTERIOR ZONA SUR, S/N, LOCAL PA-028',
    city: 'HUELVA',
    province: 'HUELVA',
    cp: '21007',
    phone: '959499494',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '456',
    alias: 'CAN_ALISIOS',
    name: 'Las Palmas - Alisios',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C ALISIOS, LOCAL B-81, CALLE HERMANOS DOMINGUEZ SANTANA S/N',
    city: 'LAS PALMAS DE GRAN CANARIA',
    province: 'LAS PALMAS G.C.',
    cp: '35018',
    phone: '928392035',
    regionalManager: 'Eduardo Timón',
    manager: 'Imanol Núñez',
  },
  {
    id: '457',
    alias: 'Barcelona-Fines',
    name: 'Barcelona-Finestrelles',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. FINESTRELLES, C/ LAUREA MIRÓ, Nº 4, LOCAL PS-13',
    city: 'ESPLUGUES DE LLOBREGAT',
    province: 'BARCELONA',
    cp: '08950',
    phone: '936897356',
    regionalManager: 'Eduardo Timón',
    manager: 'Sergio Rodenas',
  },
  {
    id: '459',
    alias: 'ALMERIA_TORRECA',
    name: 'Almeria - Torrecardenas',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address:
      'C.C. TORRECARDENAS, AVENIDA MÉDICO FRANCISCO PÉREZ COMPANY, Nº 18,LOCAL FB-2',
    city: 'ALMERIA',
    province: 'ALMERIA',
    cp: '04009',
    phone: '950288918',
    regionalManager: 'Carlos Lopez',
    manager: 'Almudena Martin',
  },
  {
    id: '460',
    alias: 'SEVILLA_TORRE',
    name: 'Sevilla - Torresevilla',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C.C. TORRESEVILLA, GONZALO JIMÉNEZ DE QUESADA, Nº 2, LOCAL L-16',
    city: 'SEVILLA',
    province: 'SEVILLA',
    cp: '41092',
    phone: '954872040',
    regionalManager: 'Carlos Lopez',
    manager: 'Alexandre Vieira',
  },
  {
    id: '510',
    alias: 'KIOSKO_2',
    name: 'KIOSKO_2',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'VIRGILIO 9',
    city: 'POZUELO DE ALARCON',
    province: 'MADRID',
    cp: '28223',
    phone: '902171819',
  },
  {
    id: '900',
    alias: 'CENTRAL_SAT',
    name: 'CENTRAL_SAT',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'HORMIGUERAS 124 PORTAL 5 BAJO D',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28031',
    phone: '917958839',
    regionalManager: 'S/D',
    manager: 'S/D',
  },
  {
    id: '950',
    alias: 'CENTRAL_ESPORTS',
    name: 'CENTRAL_ESPORTS',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'VIRGILIO 9',
    city: 'MADRID',
    province: 'MADRID',
    cp: '28031',
    phone: '913300100',
    regionalManager: 'S/D',
    manager: 'S/D',
  },
  {
    id: '990',
    alias: 'WEB',
    name: 'WEB',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ VIRGILIO 9 (WEB)',
    city: 'POZUELO DE ALARCON',
    province: 'MADRID',
    cp: '28223',
    phone: '902171819',
    regionalManager: 'Jeronimo Fornieles',
    manager: 'Frederico Lopes',
  },
  {
    id: '999',
    alias: 'CENTRAL',
    name: 'CENTRAL',
    'Razón social': 'GAME STORES IBERIA S.L.',
    cif: 'B81209751',
    address: 'C/ VIRGILIO Nº9',
    city: 'POZUELO DE ALARCON',
    province: 'MADRID',
    cp: '28223',
    phone: '913300105',
  },
];
