import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import InputBase from '@material-ui/core/InputBase';

export default withStyles(theme => ({
  input: {
    position: 'relative',
    padding: '9px 10px',
    backgroundColor: '#fafbfc',
    border: '1px solid #d1d5da',
    borderRadius: 3,
    color: 'inherit',
    fontSize: 16,
    transition: theme.transitions.create(['border-color', 'box-shadow', 'padding']),
    boxShadow: 'inset 0 1px 2px rgba(27, 31, 35, 0.075)',
    '&:invalid': {
      boxShadow: 'inset 0 1px 2px rgba(27, 31, 35, 0.075)',
    },
    '&:focus': {
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    },
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  error: {
    '& input': {
      borderColor: '#e02828',
    },
    '& input:focus': {
      borderColor: '#e02828',
      boxShadow: 'inset 0 1px 2px rgba(224, 40, 40, 0.25)',
    },
  },
  adornedStart: {
    position: 'relative',
  },
  adornedEnd: {
    position: 'relative',
  },
  inputAdornedStart: {
    paddingLeft: '38px',
  },
  inputAdornedEnd: {
    paddingRight: '38px',
  },
}))(InputBase);
