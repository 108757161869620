import React from 'react';
import request from 'utils/request';
import { query } from 'utils/data/update/products';

import { Button } from '@material-ui/core';

export default function AdminPage() {
  const handleUpdate = () => {
    console.log(query);
    request('https://api.bigbenespana.es/api/totem/products/price', {
      method: 'POST',
      body: JSON.stringify(query),
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="container text-center my-4">
      <Button onClick={handleUpdate} color="primary" variant="contained">
        Actualizar precio
      </Button>
    </div>
  );
}
