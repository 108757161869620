import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-right: 24px;
  flex: 1 0 430px;

  & img {
    max-width: 100%;
    max-height: 530px;
  }
`;

export const ProductTitle = styled.div`
  margin-bottom: 24px;

  & h2 {
    letter-spacing: 0.004em;
  }

  & p {
    font-size: 18px;
    line-height: 1.125;
    letter-spacing: 0.004em;
    font-weight: 300;
  }
`;

export const ProductCard = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
  padding: 34px 30px;
  background-color: #f3f3f3;
  border-radius: 3px;
`;

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 100%;
  /* background-color: #45e19f; */
  background-color: #0070c9;
  background: linear-gradient(#42a1ec, #0070c9);
  border: 0;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;

  /*

& > span {
  max-width: 0;
  transition: all 400ms;
  overflow: hidden;
}

&:hover > span {
  max-width: 130px;
  transition: all 500ms;
}

*/
`;

export const UnitsCaption = styled.div`
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 275px;
  max-width: 350px;
  margin: 5px;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  cursor: pointer;

  & h3 {
    margin-top: 0.5rem;
  }

  & h5 {
    padding: 0 6px;
    font-size: 13px;
    text-align: center;
  }
`;

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  height: 350px;
  max-height: 350px;
  padding: 16px;

  & img {
    max-width: 100%;
    max-height: 318px;
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 10px;
`;

export const DialogNav = styled.div`
  display: none;
  position: relative;
  margin: 0;

  @media (min-width: 992px) {
    display: block;
  }
`;

export const DialogNavItem = styled.div`
  position: absolute;
  top: 0;
  left: ${({ inverted }) => (inverted ? 'unset' : '-55px')};
  right: ${({ inverted }) => (inverted ? '-55px' : 'unset')};
  z-index: 1;
`;

export const DialogNavArrow = styled.button`
  padding: 278px 10px;
  background: none;
  border: 0;
  color: #fff;
  opacity: 0.7;
  transition: opacity 150ms ease;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const useStyles = makeStyles({
  paperScrollBody: {
    overflow: 'visible',
  },
});
