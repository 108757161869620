import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2885e0',
      dark: '#1976d2',
    },
    secondary: {
      light: '#ff6e6e',
      main: '#fa3e3e',
      dark: '#e03838',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
  },
  shadows: [
    'none',
    '0px 1px 3px 0px rgba(0, 0, 0, 0.04),0px 1px 1px 0px rgba(0, 0, 0, 0.04),0px 2px 1px -1px rgba(0, 0, 0, 0.04)',
    '0px 1px 5px 0px rgba(0, 0, 0, 0.04),0px 2px 2px 0px rgba(0, 0, 0, 0.04),0px 3px 1px -2px rgba(0, 0, 0, 0.04)',
    '0px 1px 8px 0px rgba(0, 0, 0, 0.04),0px 3px 4px 0px rgba(0, 0, 0, 0.04),0px 3px 3px -2px rgba(0, 0, 0, 0.04)',
    '0px 2px 4px -1px rgba(0, 0, 0, 0.04),0px 4px 5px 0px rgba(0, 0, 0, 0.04),0px 1px 10px 0px rgba(0, 0, 0, 0.04)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.04),0px 5px 8px 0px rgba(0, 0, 0, 0.04),0px 1px 14px 0px rgba(0, 0, 0, 0.04)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.04),0px 6px 10px 0px rgba(0, 0, 0, 0.04),0px 1px 18px 0px rgba(0, 0, 0, 0.04)',
    '0px 4px 5px -2px rgba(0, 0, 0, 0.04),0px 7px 10px 1px rgba(0, 0, 0, 0.04),0px 2px 16px 1px rgba(0, 0, 0, 0.04)',
    '0px 5px 5px -3px rgba(0, 0, 0, 0.04),0px 8px 10px 1px rgba(0, 0, 0, 0.04),0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.04),0px 9px 12px 1px rgba(0, 0, 0, 0.04),0px 3px 16px 2px rgba(0, 0, 0, 0.04)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.04),0px 10px 14px 1px rgba(0, 0, 0, 0.04),0px 4px 18px 3px rgba(0, 0, 0, 0.04)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.04),0px 11px 15px 1px rgba(0, 0, 0, 0.04),0px 4px 20px 3px rgba(0, 0, 0, 0.04)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.04),0px 12px 17px 2px rgba(0, 0, 0, 0.04),0px 5px 22px 4px rgba(0, 0, 0, 0.04)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.04),0px 13px 19px 2px rgba(0, 0, 0, 0.04),0px 5px 24px 4px rgba(0, 0, 0, 0.04)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.04),0px 14px 21px 2px rgba(0, 0, 0, 0.04),0px 5px 26px 4px rgba(0, 0, 0, 0.04)',
    '0px 8px 9px -5px rgba(0, 0, 0, 0.04),0px 15px 22px 2px rgba(0, 0, 0, 0.04),0px 6px 28px 5px rgba(0, 0, 0, 0.04)',
    '0px 8px 10px -5px rgba(0, 0, 0, 0.04),0px 16px 24px 2px rgba(0, 0, 0, 0.04),0px 6px 30px 5px rgba(0, 0, 0, 0.04)',
    '0px 8px 11px -5px rgba(0, 0, 0, 0.04),0px 17px 26px 2px rgba(0, 0, 0, 0.04),0px 6px 32px 5px rgba(0, 0, 0, 0.04)',
    '0px 9px 11px -5px rgba(0, 0, 0, 0.04),0px 18px 28px 2px rgba(0, 0, 0, 0.04),0px 7px 34px 6px rgba(0, 0, 0, 0.04)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.04),0px 19px 29px 2px rgba(0, 0, 0, 0.04),0px 7px 36px 6px rgba(0, 0, 0, 0.04)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.04),0px 20px 31px 3px rgba(0, 0, 0, 0.04),0px 8px 38px 7px rgba(0, 0, 0, 0.04)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.04),0px 21px 33px 3px rgba(0, 0, 0, 0.04),0px 8px 40px 7px rgba(0, 0, 0, 0.04)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.04),0px 22px 35px 3px rgba(0, 0, 0, 0.04),0px 8px 42px 7px rgba(0, 0, 0, 0.04)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.04),0px 23px 36px 3px rgba(0, 0, 0, 0.04),0px 9px 44px 8px rgba(0, 0, 0, 0.04)',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.04),0px 24px 38px 3px rgba(0, 0, 0, 0.04),0px 9px 46px 8px rgba(0, 0, 0, 0.04)',
  ],
  overrides: {
    MuiInput: {
      root: {
        borderRadius: '3px',
        color: 'inherit',
        '&$focused': {
          backgroundColor: '#fff',
          borderColor: '#2188ff',
          borderRadius: '3px',
          boxShadow:
            'inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em rgba(3, 102, 214, 0.3)',
        },
        '&$error': {
          borderColor: '#ff2121',
          boxShadow:
            'inset 0 1px 2px rgba(27, 31, 35, 0.075), 0 0 0 0.2em rgba(214, 3, 3, 0.3)',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: 'inherit',
        fontSize: 16,
        fontFamily: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        '@media (min-width: 768px)': {
          fontSize: 14,
        },
      },
      input: {
        padding: '6px 0',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      adornedEnd: {
        position: 'relative',
      },
      inputAdornedEnd: {
        paddingRight: '32px',
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        position: 'absolute',
        right: 3,
      },
    },
    MuiSelect: {
      root: {
        fontSize: '14px',
        borderRadius: '3px',
        '&:focus': {
          backgroundColor: '#fff',
          borderRadius: '3px',
        },
      },
      select: {
        paddingLeft: 16,
        paddingRight: '32px !important',
        borderRadius: '3px',
        '&:focus': {
          backgroundColor: '#fff',
          borderRadius: '3px',
        },
      },
    },
    MuiMenu: {
      paper: {
        '&::-webkit-scrollbar': {
          width: 10,
        },
        '&::-webkit-scrollbar-track': {
          border: '3px solid transparent',
          borderRadius: '16px',
          boxShadow: 'inset 0 0 10px 10px #eff1f7',
        },
        '&::-webkit-scrollbar-thumb': {
          border: '3px solid transparent',
          borderRadius: '16px',
          boxShadow: 'inset 0 0 0 1px #b8c1d0, inset 0 0 10px 10px #cbd2dd',
        },
      },
    },
    MuiMenuItem: {
      root: {
        color: 'inherit',
        fontSize: '14px',
        '&$selected': {
          color: '#1665d8',
          backgroundColor: 'rgba(84, 147, 245, 0.05)',
        },
      },
    },
    MuiListItem: {
      root: {
        paddingTop: '8px',
        paddingBottom: '8px',
        color: 'inherit',
        '&:hover': {
          color: '#1665d8',
          backgroundColor: 'rgba(84, 147, 245, 0.05)',
        },
        '&$selected': {
          color: '#1665d8',
          backgroundColor: 'rgba(84, 147, 245, 0.05)',
          '&:hover': {
            color: '#1665d8',
            backgroundColor: 'rgba(84, 147, 245, 0.05)',
          },
        },
      },
      button: {
        '&.active': {
          backgroundColor: 'rgba(84, 147, 245, 0.05)',
        },
        '&:hover': {
          backgroundColor: 'rgba(84, 147, 245, 0.05)',
        },
        '&:focus': {
          backgroundColor: 'rgba(84, 147, 245, 0.05)',
        },
      },
    },
    MuiChip: {
      root: {
        margin: '3px 0',
        height: '30px',
        fontSize: 12,
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        '&+&': {
          marginLeft: '6px',
        },
      },
      deleteIcon: {
        fontSize: '18px',
        margin: '0 8px 0 -6px',
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: 8,
      },
      tooltip: {
        fontSize: '12px',
        fontFamily: 'inherit',
        fontWeight: '700',
        lineHeight: '16px',
      },
      tooltipPlacementLeft: {
        margin: '0 8px',
        '@media (min-width: 600px)': {
          margin: '0 8px',
        },
      },
      tooltipPlacementRight: {
        margin: '0 8px',
        '@media (min-width: 600px)': {
          margin: '0 8px',
        },
      },
      tooltipPlacementTop: {
        margin: '8px 0',
        '@media (min-width: 600px)': {
          margin: '8px 0',
        },
      },
      tooltipPlacementBottom: {
        margin: '8px 0',
        '@media (min-width: 600px)': {
          margin: '8px 0',
        },
      },
    },
    MuiDialog: {
      paper: {
        overflowY: 'initial',
      },
      scrollBody: {
        '&::-webkit-scrollbar': {
          width: 0,
        },
        '&::-webkit-scrollbar-track': {
          border: '3px solid transparent',
          borderRadius: '16px',
          boxShadow: 'inset 0 0 10px 10px #eff1f7',
        },
        '&::-webkit-scrollbar-thumb': {
          border: '3px solid transparent',
          borderRadius: '16px',
          boxShadow: 'inset 0 0 0 1px #b8c1d0, inset 0 0 10px 10px #cbd2dd',
        },
        WebkitOverflowScrolling: 'touch',
      },
      paperFullScreen: {
        height: 'unset',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '16px 20px',
        borderBottom: 'none',
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: '8px',
        borderTop: '1px solid #eaedf3',
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        minWidth: '36px',
      },
    },
    MuiTypography: {
      body1: {
        color: 'inherit',
        fontSize: 'inherit',
      },
      h6: {
        color: '#586069',
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
    MuiButton: {
      root: {
        padding: '6px 12px',
        minHeight: 'unset',
        color: '586069',
        fontSize: '14px',
        fontWeight: 'inherit',
        lineHeight: '20px',
        textTransform: 'none',
        '&$disabled': {
          cursor: 'not-allowed',
        },
        '@media (min-width: 1200px)': {
          fontSize: '13px',
        },
        '@media (min-width: 1580px)': {
          fontSize: '14px',
        },
      },
      contained: {
        padding: '6px 12px',
        backgroundColor: '#eff3f6',
        border: '1px solid #eaedf3',
        color: '#586069',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.04)',
        '&:hover': {
          backgroundColor: '#eaedf3',
        },
        '&$disabled': {
          backgroundColor: '#eff3f6',
          borderColor: '#d1d5da',
          color: 'rgba(36, 41, 46, 0.4)',
        },
      },
      containedPrimary: {
        borderColor: '#2885e0',
        '&:active': {
          borderColor: '#1976d2',
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#eaedf3',
      },
    },
    MuiTab: {
      root: {
        '@media (min-width: 1200px)': {
          fontSize: '12px',
        },
        '@media (min-width: 1580px)': {
          fontSize: '0.8125rem',
        },
      },
    },
    MuiBottomNavigation: {
      root: {
        backgroundColor: '#24292e',
        color: '#fff',
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: 'rgba(255, 255, 255, 0.75)',
        '&$selected': {
          color: '#fff',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '8px',
      },
    },
    MuiDrawer: {
      paper: {
        '&::-webkit-scrollbar': {
          width: 10,
        },
        '&::-webkit-scrollbar-track': {
          border: '3px solid transparent',
          borderRadius: '16px',
          boxShadow: 'inset 0 0 10px 10px #eff1f7',
        },
        '&::-webkit-scrollbar-thumb': {
          border: '3px solid transparent',
          borderRadius: '16px',
          boxShadow: 'inset 0 0 0 1px #b8c1d0, inset 0 0 10px 10px #cbd2dd',
        },
      },
    },
  },
});

export default theme;
