import PhoneCaseIcon from './images/phone-case.png';
import ScreenProtectorIcon from './images/screen-protector.png';
import UsbChargerIcon from './images/usb-charger.png';
import HeadPhonesIcon from './images/headphones.png';
import MemoryCardIcon from './images/memory-card.png';
import MoreIcon from './images/more.png';
import SmartwatchIcon from './images/smartwatch.png';
import DroneIcon from './images/drone.png';
import GoProIcon from './images/gopro.png';
import PlayStationIcon from './images/playstation.png';
import XboxIcon from './images/xbox.png';
import NintendoSwitchIcon from './images/nintendo-switch.png';
// import Nintendo3DSIcon from './images/ds.png';
import ComputerIcon from './images/pc.png';
import PSPIcon from './images/psp.png';
import BolsoIcon from './images/bolso.png';
import ViajeIcon from './images/viaje.png';
import NovedadesIcon from './images/novedades.png';
import CarteraIcon from './images/wallet.png';
import AccesoriosIcon from './images/accesories.png';
import HombreIcon from './images/man.png';


export const accesorios = [
  {
    id: 2,
    name: 'Fundas y carcasas',
    icon: PhoneCaseIcon,
  },
  {
    id: 19,
    name: 'Energía y cables',
    icon: UsbChargerIcon,
  },
  {
    id: 21,
    name: 'Protector de pantalla',
    icon: ScreenProtectorIcon,
  },
  {
    id: 25,
    name: 'Auriculares',
    icon: HeadPhonesIcon,
  },
  {
    id: 24,
    name: 'Memoria',
    icon: MemoryCardIcon,
  },
  {
    id: 1,
    name: 'Otros',
    icon: MoreIcon,
  },
];

export const deporte = [
  {
    id: 8,
    name: 'Relojes',
    icon: SmartwatchIcon,
  },
  {
    id: 9,
    name: 'Drones',
    icon: DroneIcon,
  },
  {
    id: 10,
    name: 'Cámaras',
    icon: GoProIcon,
  },
  {
    id: 7,
    name: 'Otros',
    icon: MoreIcon,
  },
];

export const videogames = [
  {
    id: 1,
    name: 'PlayStation',
    icon: PlayStationIcon,
  },
  {
    id: 2,
    name: 'Xbox',
    icon: XboxIcon,
  },
  {
    id: 3,
    name: 'Nintendo Switch',
    icon: NintendoSwitchIcon,
  },
  /*{
    id: 4,
    name: 'Nintendo 3DS',
    icon: Nintendo3DSIcon,
  },*/
  {
    id: 5,
    name: 'PC',
    icon: ComputerIcon,
  },
  {
    id: 6,
    name: 'PSP',
    icon: PSPIcon,
  },
  {
    id: 7,
    name: 'Otros',
    icon: MoreIcon,
  },
];

export const bolsos = [
  {
    id: 1,
    name: 'Novedades',
    icon: NovedadesIcon,
  },
  {
    id: 2,
    name: 'Viaje',
    icon: ViajeIcon,
  },
  {
    id: 3,
    name: 'Bolsos',
    icon: BolsoIcon,
  },
  {
    id: 4,
    name: 'Carteras',
    icon: CarteraIcon,
  },
  {
    id: 5,
    name: 'Accesorios',
    icon: AccesoriosIcon,
  },
  {
    id: 6,
    name: 'Hombre',
    icon: HombreIcon,
  },
];
