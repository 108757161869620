import React from 'react';
import PropTypes from 'prop-types';

import { Submenu, SubmenuItem, SubmenuIcon, SubmenuText } from './styles';

export default function CategoriesMenu({
  items,
  fixed,
  onChange,
  selectedCategory,
}) {
  return (
    <Submenu fixed={fixed}>
      {items.map(item => (
        <SubmenuItem
          key={item.id}
          fixed={fixed}
          onClick={() => onChange(selectedCategory === item.id ? null : item.id)}
          selected={selectedCategory === item.id}
        >
          <SubmenuIcon
            src={item.icon}
            draggable={false}
            alt={item.name}
            fixed={fixed}
          />
          <SubmenuText fixed={fixed}>{item.name}</SubmenuText>
        </SubmenuItem>
      ))}
    </Submenu>
  );
}

CategoriesMenu.propTypes = {
  items: PropTypes.array,
  fixed: PropTypes.bool,
  selectedCategory: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func,
};
