import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { emptyProducts } from 'modules/cesta';

import {
  Popper,
  Paper,
  DialogContent,
  Grow,
  ClickAwayListener,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableFooter,
  Button,
} from '@material-ui/core';
import ProductTable from './ProductTable';
import { useStyles } from './styles';

function Cesta({
  open,
  onClose,
  anchorEl,
  products,
  emptyProducts,
  onBuyPress,
}) {
  const [arrowRef, setArrowRef] = React.useState(null);
  const classes = useStyles();

  return (
    <Popper
      open={open}
      onClose={onClose}
      placement="bottom"
      className={classes.popper}
      anchorEl={anchorEl.current}
      modifiers={{
        flip: {
          enable: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent',
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
      transition
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: 'top center' }}
          timeout={150}
        >
          <div>
            <ClickAwayListener onClickAway={onClose}>
              <div>
                <span className={classes.arrow} ref={setArrowRef} />
                <Paper className={classes.paper} elevation={3}>
                  <DialogContent className={classes.dialogContent}>
                    {products.length > 0 ? (
                      <React.Fragment>
                        <div className={classes.tableWrapper}>
                          <Table className={classes.productList} stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className={classes.tableCell}
                                  colSpan={3}
                                >
                                  Artículos en la cesta
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  colSpan={2}
                                  align="right"
                                >
                                  <Button
                                    className={classes.button}
                                    size="small"
                                    color="secondary"
                                    onClick={emptyProducts}
                                  >
                                    Vaciar cesta
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <ProductTable products={products} />
                          </Table>
                        </div>
                        <Table style={{ borderTop: '1px solid #e0e0e0' }}>
                          <TableFooter>
                            <TableRow>
                              <TableCell
                                className={classes.tableCell}
                                style={{ minWidth: 1, whiteSpace: 'nowrap' }}
                              >
                                <Button
                                  className={classes.button}
                                  onClick={() => {
                                    onBuyPress();
                                    onClose();
                                  }}
                                  color="inherit"
                                  variant="contained"
                                  style={{
                                    background: '#0070c9',
                                    color: '#fff',
                                    boxShadow: 'none',
                                  }}
                                >
                                  Comprar artículos
                                </Button>
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                align="right"
                                style={{ width: '100%' }}
                              >
                                Total
                              </TableCell>
                              <TableCell
                                className={classes.tableCell}
                                style={{
                                  color: 'inherit',
                                  fontSize: 18,
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {new Intl.NumberFormat('es-Es', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(
                                  products.reduce(
                                    (a, b) =>
                                      a +
                                      (isNaN(b.amount)
                                        ? 0
                                        : b.amount * b.price.amount),
                                    0,
                                  ),
                                )}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </React.Fragment>
                    ) : (
                      <div
                        className="d-flex align-items-center justify-content-center align-self-stretch text-black-50"
                        style={{ padding: '80px', fontSize: 14 }}
                      >
                        Tu cesta está vacía.
                      </div>
                    )}
                  </DialogContent>
                </Paper>
              </div>
            </ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  );
}

Cesta.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  anchorEl: PropTypes.object,
  emptyProducts: PropTypes.func.isRequired,
  onBuyPress: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      emptyProducts,
      onBuyPress: () => push('/buy-process'),
    },
    dispatch,
  );

export default connect(
  null,
  mapDispatchToProps,
)(Cesta);
