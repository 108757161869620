import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Collapse } from '@material-ui/core';
import CatalogoAccesorios from './catalogo';
import Terminales from './terminales';
import PhoneCaseImage from './images/iphone-case.png';
import {
  CarouselItem,
  CarouselCaptionLeft,
  CarouselImageRight,
} from './styles';

function AccesoriosTelefonia() {
  const [showCarousel, setShowCarousel] = useState(true);
  const [terminal, setTerminal] = useState(null);

  return (
    <div>
      <Collapse in={showCarousel} timeout={300}>
        <Carousel
          indicators={false}
          controls={false}
          style={{ borderBottom: '1px solid #ececec' }}
        >
          <Carousel.Item as={CarouselItem}>
            <div
              className="container position-relative"
              style={{ height: 440 }}
            >
              <CarouselImageRight
                src={PhoneCaseImage}
                draggable={false}
                style={{ height: 640 }}
                alt="Funda semirígida azul para iPhone"
              />
              <Carousel.Caption as={CarouselCaptionLeft}>
                <h2>Viste a tu terminal.</h2>
                <Link to="/accesorios-telefonia">
                  Accesorios para tu móvil
                  <span style={{ marginLeft: '0.3em' }}></span>
                </Link>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </Collapse>
      <Terminales terminal={terminal} setTerminal={setTerminal} />
      {!terminal && <CatalogoAccesorios setShowCarousel={setShowCarousel} hideSearchbar />}
    </div>
  );
}

export default AccesoriosTelefonia;
