import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import SearchIcon from '@material-ui/icons/Search';
import { Collapse, InputBase } from '@material-ui/core';
import { pacoProducts } from '../../utils/data/pacoMartinez';
import Catalogo from 'components/catalogo';
import CategoriesMenu from 'components/categoriesMenu';
import { bolsos as categories } from 'utils/data/categories';
import { useScrollPosition } from 'utils/hooks';
import {
  useStyles,
  SearchTitle,
  SearchInput,
  CarouselItem,
  CategoriesContainer,
  CarouselCaptionLeft,
  CarouselImageRight,
} from '../videojuegos/styles';

function PacoMartinez() {
  const categoriesRef = useRef(null);
  const [fixedCategories, setFixedCategories] = useState(false);
  const [showCarousel, setShowCarousel] = useState(true);
  const [categoryId, setCategoryId] = useState(null);
  const [products, setProducts] = useState(pacoProducts);
  const [searchValue, setSearchValue] = useState('');
  const classes = useStyles();
  const searchInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [loadedItemsCount, setLoadedItemsCount] = useState(0);

  const loadMoreItems = () => {
    setLoading(true);
    setTimeout(() => {
      const newLoadedItemsCount = loadedItemsCount + 10;
      setLoadedItemsCount(newLoadedItemsCount);
      setLoading(false);
    }, 2000);
  };

  const handleSearchInputChange = event => {
    const searchText = event.target.value;
    const filteredProducts = pacoProducts.filter(product =>
      product.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    setSearchValue(searchText);
    setProducts(filteredProducts);
  };

  useScrollPosition(
    ({ _, currPos }) => {
      if (currPos.y <= 46) {
        setFixedCategories(true);
      }
      if (currPos.y > 46) {
        setFixedCategories(false);
      }
    },
    [],
    categoriesRef,
  );

  useEffect(() => {
    if (categoryId) {
      const name = categories.find(category => category.id === categoryId);
      const filtered = pacoProducts.filter(
        product => product.categoria.toLowerCase() === name.name.toLowerCase(),
      );
      setProducts(filtered);
    }
  }, [categoryId]);

  return (
    <>
      <Collapse in={showCarousel} timeout={300}>
        <Carousel
          indicators={false}
          controls={false}
          style={{ borderBottom: '1px solid #ececec' }}
        >
          <Carousel.Item as={CarouselItem}>
            <div
              className="container position-relative"
              style={{ height: 440 }}
            >
              <CarouselImageRight
                src="/logo/logo-pacomartinez.jpg"
                draggable={false}
                style={{ height: 530 }}
                alt="logo paco martinez"
              />
              <Carousel.Caption as={CarouselCaptionLeft}>
                <h2>Preparate para despegar</h2>
                <Link to="/">Los mejores bolsos de viaje</Link>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </Collapse>
      <div className="container">
        <div className="text-center">
          <SearchTitle>Encuentra los bolsos o maletas que buscas.</SearchTitle>
          <SearchInput>
            <SearchIcon
              fontSize="large"
              style={{ marginRight: 8, color: '#bbb' }}
            />
            <InputBase
              inputRef={searchInputRef}
              type="search"
              placeholder="Buscar bolsos"
              className={classes.input}
              value={searchValue}
              onChange={handleSearchInputChange}
              onFocus={() => {
                setShowCarousel(false);
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              }}
              fullWidth
            />
          </SearchInput>
        </div>
      </div>
      <div ref={categoriesRef} className="position-relative">
        <div style={{ paddingBottom: 135 }} />
        <CategoriesContainer
          fixed={fixedCategories}
          className={fixedCategories ? 'container-fluid' : 'container'}
        >
          <div className="text-center">
            <CategoriesMenu
              items={categories}
              fixed={fixedCategories}
              selectedCategory={categoryId}
              onChange={id => {
                if (categoryId !== id) {
                  setProducts(pacoProducts);
                  setCategoryId(id);
                }
              }}
            />
          </div>
        </CategoriesContainer>
      </div>

      <div>
        <div className="container mb-5">
          <Catalogo
            loading={loading}
            products={products}
            loadMoreItems={loadMoreItems}
            shouldLoadMoreItems={false}
            isPacoMartinez={true}
          />
        </div>
      </div>
    </>
  );
}

export default PacoMartinez;
